import { ref } from 'vue'
const templates = ref([])
export function useTemplates() {
  const loaded = ref(true)
  templates.value = Object.entries(window.platform.apps.find((a) => a.name === 'digital-edition').pages)
    .filter(([k,v]) => v.additions?.theme)
    .map(([k, v]) => k.split('/').slice(-1))
    .flat(Infinity)
  return {
    templates,
    loaded,
  }
}
