import { onMounted, onUnmounted, ref, watch } from 'vue'
import { dbRef } from '../../../features/firebase'

export function useCommands() {
  let commandRef = dbRef.child(`/data/commands`)
  const commands = ref([])

  const onUpdate = snap => {
    commands.value = snap.val()
  }

  onMounted(() => commandRef.on('value', onUpdate))
  onUnmounted(() => commandRef.off('value', onUpdate))

  return {
    commands,
  }
}
