<template lang="pug">
transition(@leave='leave')
  splashscreen(v-if="isLoading")
section.page(v-if="!isLoading")
  router-view
</template>
<style scoped>
.app-switch .page { width: 100%; height: 100%; left: 0; top: 0; justify-content: center; align-items: center}
</style>
<script>
export default {
  inject: ['progress'],
  methods: {
    leave(el, done) {
      el.style.opacity = 0
      setTimeout(() => done(), 500)
    },
  },
  computed: {
    messages() {
      return this.$root.messages
    },
    isLoading() {
      return false
    },
  },
}
</script>
