<style scoped>
.info { display: flex;flex-direction: column;flex-wrap: wrap; }
.info .row { padding: 8px;font: var(--p2); }
.info .row > * { display: flex;align-items: center; }
.info .key { font-weight: 600;margin-right: 4px;min-width: 110px; }
.info .value .left { color: var(--inactive);margin-left: 4px; }
.wip .board .line .cell.name { align-items: flex-start; }
.wip .board .line .cell.status { flex: 0!important; }
.wip .board .line .cell.due { flex-direction: row; }
.box-overlay { cursor: pointer;position: absolute;top: 0;bottom: var(--form-height);left: 0;right: 0;display: flex;align-items: center;justify-content: center;background: rgb(0,0,0,.75);min-width: 100%!important;margin: 0!important; }
.box-overlay::after { content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" fill="none" shape-rendering="geometricPrecision"><path d="M18 6L6 18"/><path d="M6 6l12 12"/></svg>');position: absolute;top: 0;right: 0;width: 30px;height: 30px;padding: 5px;background: #666; }
.box-overlay .inside { height: calc(100% - 80px);min-width: 100%;margin: 40px!important;background: white; }
.box-overlay .inside iframe { height: 100%;width: 100%; }
h2 .prev { cursor: pointer;width: 30px;height: 30px;transform: rotate(180deg); }
h2 .tag { margin: 0 8px 0 auto; }
h2 .name { margin-left: auto;max-width: calc(100% - 160px);overflow: hidden;text-overflow: ellipsis;white-space: nowrap;line-height: 1.2; }
h2 .count { margin: 4px;font: var(--h3); }
h2 .task { margin-right: auto; }
h2 .next { cursor: pointer;width: 30px;height: 30px; }
h2 :is(.prev, .next).inactive { pointer-events: none;fill: var(--inactive); }
.description { width: 100px;margin: 4px; }
.row.error { color: var(--negative); }
.link { cursor: pointer;color: var(--primary); }
.link.inactive { color: var(--inactive);pointer-events: none; }
.link:hover, .link.active { font-weight: bold; }
.save-workflow { flex: 0; }
.save-workflow div { width: 150px; }
/* HACK */.box-form { overflow: unset; }
.wfblock { min-height: 0;overflow: auto; }
h1 input { font: var(--h2); }
</style>

<template lang="pug">
transition(@leave='leave')
  loader(v-if="isLoading")
h1(v-if="run")
  div(v-if="$root.query.rid") Run {{ run.id }} - {{ run.context.isin + '-' + run.context.language + '-' + run.context.domain.slice(0, 7) }}
  input(style="margin: 0;" v-model="workflow.name" v-else)
  button.save-workflow(@click="set(['data', 'workflows', workflow.id].join('.'), workflow);$router.push({ path: 'workflows' })" v-if="!$root.query.rid")
    div {{ t.save_workflow }}
.row(v-if="run && rid")
  .block.info(style="max-height: 200px;overflow: auto;")
    .row(v-for="k in detail_info.map(v => localStorage.PROJECT === 'greenrock' && v === 'name' ? 'workflow' : v)")
      .key {{ t[k] || k.titleize() }}:
      .value.owner(style="margin: -3px 0;min-width: 0;" :style="{ background: 'var(--cat' + ((run.context[k] || '').charCodeAt(0) % 10 + 1) + ')' }" :tt="(run.context[k].split('@')[0] || '').titleize()" v-if="k === 'owner' && run.context[k]") {{ run.context[k].split('@')[0].split('.').map('0').join('').upper() }}
      .value.document(v-else-if="k === 'document'" :tt="[run.document].filter().join(' / ')") {{ run.short_code }}
      .value.jurisdiction(v-else-if="k === 'jurisdiction'") {{ (run.countries || []).join(',') }}
      svg-icon.lang.icon.value(style="margin: -4px 0;min-width: 0;" :name="'flag-' + (run.context.language.toLowerCase().slice(0, 2))" v-else-if="k === 'language'")
      img.file_icon(src="https://platform.100m.io/dist/impress/icon_pdf.png" v-else-if="k === 'format'")
      .value(v-else-if="k === 'domain'")
        div {{ run.context.domain.includes('Q') ? run.context.domain : new Date(run.context.domain).format('DD/MM/YY') }}
      .value(v-else-if="k === 'id'") {{ run.id }}
      .value(v-else-if="k === 'stamp'") {{ run.stamp }}
      .value(v-else-if="k === 'name'") {{ run.report.name.titleize() }}
      .value(v-else-if="k === 'workflow'") {{ run.workflow.name.titleize() }}
      .value(v-else-if="k === 'start_date'") {{ new Date(run.context['run_time']).format('DD/MM/YYYY') }}
      .value(v-else-if="['template', 'fund_name'].includes(k)") {{ (run.context[k] || '').titleize() }}
      .value(v-else) {{ run.context[k] || '-' }}
  .block.wip(style="max-width: 33%;max-height: 200px;overflow: auto;")
    board(:data="boardData" :metadata="{ columns: ['name'] }")
      template(v-slot:header-name="s")
        div Work in progress & Report assets
      template(v-slot:header-status="s")
        div
      template(v-slot:cell-name="s")
        .row
          .link(v-if="s.line.url !== 'preview'"
              :class="{ inactive: (!['final'].includes(s.line.url) && !get(s.line.url.replace('[fund]', 'userflows.' + run.context.fund_id).replace('[date]', run.context.domain.slice(0, 7)))) || (s.line.url === 'final' && !(impressions || []).v().find(i => i.run_id === +$root.query.rid)) }"
              @click="update_query({ tab: s.line.url.replace('[fund]', 'userflows.' + run.context.fund_id).replace('[date]', run.context.domain.slice(0, 7)) })") {{ s.line.name }}
          // Preview move into new browser tab
          .link(v-if="s.line.url === 'preview'")
            a(target="_blank" :href="download_url") {{ s.line.name }}
      template(v-slot:cell-status="s")
        .task(:class="get(s.line.url.replace('[fund]', 'userflows.' + run.context.fund_id).replace('[date]', run.context.domain.slice(0, 7))) ? 'success' : 'error'" v-if="s.line.url")

      template(v-slot:cell-due="s")
        .row(v-if="s.line.due")
          div {{ s.line.due }}
          span.delay(:class="s.line.received ? (new Date(s.line.due) - new Date(s.line.received || new Date()) <= 0 ? 'plus' : 'minus') : 'inactive'") {{ (new Date(s.line.due) - new Date(s.line.received || new Date())) <= 0 ? '+' : '-' }}{{ Math.round(Math.abs(new Date(s.line.due) - new Date(s.line.received || new Date())) / 86400000) }}
.block.expand.wfblock(v-if="run")
  workflow-calendar(v-if="enrichedWorkflow" :data="enrichedWorkflow" @update:data="workflow = $event" :workflow="workflow" :run="run")
.box-overlay.row(@click="update_query({ tab: null })" v-if="run && $root.query.tab === 'document'")
  .column.bottom
    a(download="" target="_blank" :href="commandr + '/' + document.alias" v-for="document in documents.filter(d => d.run_id == run.id)") {{ document.alias.split('/').last() }}
  .inside(v-if="documents.filter(d => d.run_id == run.id && d.alias.includes('.pdf')).v().last()")
    iframe(:src="localStorage.COMMANDR + '/' + documents.filter(d => d.run_id == run.id && d.alias.includes('.pdf')).v().last().alias")
//.box-overlay.data-audit.row(@click="update_query({ tab: null })" v-show="/data-audit/.test($root.query.tab)" v-if="$root.query.rid")
  .inside
    iframe(src="/advanced/data-quality")
    //iframe(:src="'/advanced/data-audit?userflow=' + run.context.fund_id")
//.box-overlay.data-quality.row(@click="update_query({ tab: null })" v-show="/data-quality/.test($root.query.tab)" v-if="$root.query.rid")
  .inside
    iframe(src="/advanced/data-quality")
.box-overlay.final.row(@click="update_query({ tab: null })" v-show="$root.query.tab === 'final'" v-if="rid && (impressions || []).v().find(i => i.run_id === +rid)")
  .inside
    iframe(:src="commandr + '/' + (impressions || []).v().find(i => i.run_id === +rid).alias")
.box-overlay.comment.row(@click="update_query({ tab: null })" v-if="$root.query.tab === 'comment'")
  .inside(@click.stop="" style="display:flex;flex-direction: column;max-width: 600px;padding: 20px;")
    .timeline(v-if="run")
      .event(v-for="event in logs.v().concat(run.context.comments || []).sort('start')")
        template(v-if="event.id")
          .task(:class="event.status")
          .text {{ workflow.actions[event.action_id].name }}
          .start {{ new Date(event.start).format('YYYY-DD-MM hh:mm') }}
        template(v-else)
          .owner(:style="{ background: 'var(--cat' + ((event.user || '').charCodeAt(0) % 10 + 1) + ')' }" :tt="(event.user.split('@')[0] || '').titleize()") {{ event.user.split('@')[0].split('.').map('0').join('').upper() }}
          .text {{ event.text }}
          .start {{ new Date(event.start).format('YYYY-DD-MM hh:mm') }}
    form.row(style="margin-top: auto;height: 100px;" @submit.prevent="set(['data', 'runs', run.id, 'context', 'comments'].join('.'), (run.context.comments || []).concat({ text: $event.target.children[0].value, user: $root.profile.email, start: new Date().toISOString() }));$event.target.children[0].value = ''")
      textarea.expand
      button(style="align-self: flex-end;") Submit
//.box-overlay.assets.row(@click="update_query({ tab: null })" v-if="$root.query.tab && !/(data-quality|data-audit)/.test($root.query.tab) && !['preview', 'comment', 'document', 'final'].includes($root.query.tab)")
  .inside
    iframe(:src="get($root.query.tab)" v-if="/^(\\/|http)/.test(get($root.query.tab))")
    div(style="padding: 40px;" v-html="get($root.query.tab)" v-else)
.box-form.block(v-if="run && workflow && action.id")
  .column.expand.between(v-if="!$root.query.rid")
    h2
      svg-icon.prev(:class="{ inactive: +$root.query.action_id === +workflow.actions.v().first().id }" @click.native="update_query({ action_id: +$root.query.action_id - 1 })" name="ic_navigate_next")
      input.name(style="margin: 0;" v-model="action.name")
      .count ({{ action.id }}/{{ workflow.actions.v().length }})
      svg-icon.next(:class="{ inactive: +$root.query.action_id === +workflow.actions.v().last().id }" @click.native="update_query({ action_id: +$root.query.action_id + 1 })" name="ic_navigate_next")
    .form.row
      .column
        .label {{ t.step_type }}
        .row
          select(v-model="action.type")
            option
            option(:value="type" v-for="type in ['run_command', 'wait_for', 'user_input', 'user_validation']") {{ t[type] || type.titleize() }}
          select(v-model="action.command" v-if="action.type === 'run_command'")
            option
            option(v-for="command in (commands || [])") {{ command }}
      .column(v-if="['wait_for', 'user_input', 'run_command'].includes(action.type)")
        .label {{ 'wait_for' ? 'Watched Location' : 'Content Location' }}
        input(v-model="action.url")
      .column.expand
        .label {{ t.instruction }}
        input(v-model="action.instruction")
      .column(v-if="['user_input', 'user_validation'].includes(action.type)")
        .label {{ t.assigned_user }}
        select(v-model="action.user")
          option
          option(v-for="user in users") {{ user.email }}
      .column(v-if="action.type")
        .label Duration
        .row
          input(style="width: 70px;" type="number" :value="action.duration * (action.duration < 1 ? 24 : 1)" @input="action.duration = $event.target.value / (action.duration < 1 ? 24 : 1)")
          select(:value="action.duration < 1 ? 'hours' : 'days'" @change="action.duration = action.duration / ($event.target.value === 'hours' ? 24 : 1 / 24)")
            option hours
            option days
  .column.expand.between(v-else)
    h2
      svg-icon.prev(:class="{ inactive: +$root.query.action_id === +workflow.actions.v().first().id }" @click.native="update_query({ action_id: +$root.query.action_id - 1 })" name="ic_navigate_next")
      .tag(:style="'background: var(--cat' + { run_command: 4, wait_for: 1, user_input: 9, user_validation: 10 }[action.type] + ');'") {{ t[action.type] }}
      .name {{ action.name }}
      .count ({{ action.id }}/{{ workflow.actions.v().length }})
      .task(:class="[log.status, { skipped: log.skipped && log.skipped !== 'rerun' }]")
      svg-icon.next(:class="{ inactive: +$root.query.action_id === +workflow.actions.v().last().id }" @click.native="update_query({ action_id: +$root.query.action_id + 1 })" name="ic_navigate_next")
      div(:tt="(run.comments || []).length + ' ' + t.comments" style="display: flex;font: var(--h3);" @click="update_query({ tab: 'comment' })") {{ (run.comments || []).length || '' }}
        svg-icon.comment(style="width: 16px;margin: 0 4px 0 4px;" name="pt-icon-comment")
    .column.expand
      .row(style="align-items: center;")
        .description Instruction:
        div(style="margin: 4px;font-style: italic;font-weight: 600;" v-html="action.instruction && action.instruction.replace('[fund]', 'userflows.' + run.context.fund_id).replace('[date]', run.context.domain.slice(0, 7))")
      .row.error(v-if="log.status === 'error' && log.error")
        .description Errors:
        div {{ log.error }}
      .row.expand
        .description Actions:
        div(style="height: 100px;width: 100%;padding: 4px;border: var(--border);overflow: auto;" v-edit="action.url.replace('[fund]', 'userflows.' + run.context.fund_id).replace('[date]', run.context.domain.split('|').slice(-1)[0].slice(0, 7)).replace('[language]', run.language).replace('comment', periodComments)" v-if="['user_input'].includes(action.type) && log.status === 'running'")
        button(@click="validate_step(run.id, log.id)" v-if="['user_input', 'user_validation'].includes(action.type) && log.status === 'running'") {{ action.type === 'user_input' ? t.submit : t.accept }}
        button.ghost(@click="set(['data', 'runs', run.id, 'logs', log.id, 'status'].join('.'), 'error')" v-if="['user_input', 'user_validation'].includes(action.type) && log.status === 'running'") {{ t.reject }}
        button(@click="rerun_step(run, action.id)" v-if="log.id && log.status !== 'running'") {{ t.rerun }}
        button(@click="skip_step(run.id, log.id)" v-if="log.id && log.status === 'running' && !['user_input', 'user_validation'].includes(action.type)") {{ t.skip }}
      .row.expand(v-if="log.trace")
        pre(style="height: 150px;width: 100%;padding: 8px;margin-top: 8px;border: var(--border);overflow: auto;") {{ log.trace }}

</template>

<script>
import Board from '../../asset-management/components/board.vue'
import config from '../../../config'
import {useRun} from "../composables/useRun";
import {useWorkflow} from "../composables/useWorkflow";
import {useCommands} from "../composables/useCommands";
import {computed} from "vue";
import {useImpressions} from "../composables/useImpressions";
import {useUsers} from "../composables/useUsers";

export default {
  setup() {
    const {run, rid, logs, periodComments, loaded: rLoaded} = useRun()
    const {workflow, action, actionId, id, loaded: wLoaded} = useWorkflow()
    const {commands} = useCommands()
    const {impressions} = useImpressions()
    const {users} = useUsers()
    const isLoading = computed(() => !rLoaded || !wLoaded)
    const enrichedWorkflow = computed(() => {
      if (run.value && workflow.value && run.value.keys().length) {
        const actions = workflow.value.actions.v()
            .map(a => {
              const log = Object.values(logs.value)
                  .filter(l => l.action_id === a.id)
                  .reverse()[0] || {}
              const {id: lid, ...logData} = log
              return {lid, ...logData, ...a}
            })

        return {...workflow.value, ...{actions, owner: run.value.context.owner}}
      }
      return workflow.value
    })

    const log = computed(() => {
      return Object.values(logs.value).filter(l => l.action_id === actionId.value).reverse()[0] || {}
    })

    return {
      run,
      rid,
      id,
      actionId,
      action,
      enrichedWorkflow,
      commands,
      periodComments,
      workflow,
      logs,
      log,
      commandr: config.commandr,
      impressions,
      users,
      isLoading,
    }
  },
  components: {
    Board,
  },
  created() {
    const fn = () => setTimeout(() => $('.action.selected') && $('.action.selected').scrollIntoView({ behavior: 'smooth' }), 100)
    this.$watch('$root.query.action_id', fn)
    fn()
  },
  unmounted() {
    update_query(this.$route.query.map((d,k) => { ['id', 'rid', 'action_id', 'tab'].includes(k) && null}))
  },
  methods: {
    get(...args) {
      return get(...args)
    }
  },
  computed: {
    boardData() {
      return [{ name: 'Preview', url: 'preview' }]
          .concat(this.workflow && this.workflow.actions.v().filter(a => ['wait_for', 'user_input', 'run_command'].includes(a.type) && a.url) || [])
          .concat({ name: 'Final Document', url: 'final' }).reverse()
    },
    business_url() {
      return (`/${this.run.context.fund_id}-${this.run.context.share_id}/${this.run.context.template}?app=default&domain=${this.run.context.domain}&lang=${this.run.context.language}`)
    },
    download_url() {
      const params = '&cache=false'
      // Warning for client with no asof add a condition:
      const asof = ('&asof=' + this.run.context?.asof)
      return `${location.origin}/digital-edition${this.business_url}${params}${asof}`
    }
  }
}
</script>
