<!-- eslint-disable vue/no-mutating-props -->
<template>
  <!-- nx-scroll-area -->
  <!-- tabindex is necessary to be able to focus key events on the div -->
  <div
    qa-scroll-area
    @wheel.stop.prevent="imitateScroll"
    @touchmove.stop.prevent="touchMove"
    @touchstart.stop.prevent="touchStart"
    @touchend.stop.prevent="touchEnd"
    tabindex="0"
  >
    <!-- scrolling overlay: handles horizontal & vertical scrolling -->
    <nx-scroll-content
      class="pointer-events-none absolute bottom-0 left-0 right-0 [contain:strict]"
      :class="gridZIndex.content"
      :style="{
        left: props.state.horizontalOffset + 'px',
        top: props.state.verticalOffset + 'px',
      }"
      v-bind="props"
      @scroll-start="scrollStart"
      @scroll-end="scrollEnd"
    />
    <!-- @slot Content that needs to be scrolled -->
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
/**
 * @component Creates a scrolling overlay for a container element that handles horizontal and vertical scrolling
 */
import { INxScrollContentProps, gridZIndex, IScrollState } from '@hauru/common'
import { reactive } from 'vue'
/* eslint-disable vue/no-mutating-props */

// Code in this page is adapted from https://github.com/mdbootstrap/perfect-scrollbar/blob/main/dist/perfect-scrollbar.js

interface IProps {
  /**
   * The current scroll state of the container element
   */
  state: IScrollState
}

const props = withDefaults(defineProps<IProps>(), {})

/**
 * Imitates scrolling behavior based on the provided WheelEvent.
 * @param e - The event containing scroll data.
 */
function imitateScroll(e: WheelEvent) {
  let n = 0
  switch (e.deltaMode) {
    case WheelEvent.DOM_DELTA_PIXEL:
      n = 1
      break
    case WheelEvent.DOM_DELTA_LINE:
      n = props.state.rowHeight
      break
    case WheelEvent.DOM_DELTA_PAGE:
      n = props.state.containerHeight
      break
  }

  // If shift key is pressed and deltaX is zero, set horizontal to deltaY and vertical to zero.
  // Otherwise, set horizontal to deltaX and vertical to deltaY.
  const [horizontal, vertical] = e.shiftKey && e.deltaX === 0 ? [e.deltaY, 0] : [e.deltaX, e.deltaY]

  props.state.offsetLeft(horizontal * n * props.state.scrollSpeedHorizontal)
  props.state.offsetTop(vertical * n * props.state.scrollSpeedVertical)
}

const startOffset = reactive({
  pageX: 0,
  pageY: 0,
})
const speed = reactive({
  x: 0,
  y: 0,
})

let startTime = 0
let easingLoop: ReturnType<typeof setInterval>

/**
 * Returns the first touch point from a TouchEvent object if appropriate, undefined otherwise
 * @param e - The TouchEvent object to extract the touch point from.
 */
function getTouch(e: TouchEvent) {
  return e?.targetTouches[0]
}

/**
 * Handles the start of a touch event
 * Saves current touch position and time and clears the easingLoop if it exists
 * @param e - The touch event data.
 */
function touchStart(e: TouchEvent) {
  const touch = getTouch(e)

  startOffset.pageX = touch.pageX
  startOffset.pageY = touch.pageY

  startTime = new Date().getTime()

  if (easingLoop !== null) {
    clearInterval(easingLoop)
  }
}

/**
 * Event handler for a touch move event.
 * Calculates the difference in position between current and previous touch events, updates the touch speed,
 * and calls the applyTouchMove function to update the scroll state of the container element.
 * @param e - The touch event data.
 */
function touchMove(e: TouchEvent) {
  const touch = getTouch(e)

  // Set the current touch position in the currentOffset object.
  const currentOffset = { pageX: touch.pageX, pageY: touch.pageY }

  // Calculate the difference in position between the current and previous touch events.
  const differenceX = currentOffset.pageX - startOffset.pageX
  const differenceY = currentOffset.pageY - startOffset.pageY

  // Update the scroll state of the container element based on the touch event.
  applyTouchMove(differenceX, differenceY)

  // Update the startOffset object with the current touch position.
  startOffset.pageX = currentOffset.pageX
  startOffset.pageY = currentOffset.pageY

  // Calculate touch speed and update the speed object.
  const currentTime = new Date().getTime()
  const timeGap = currentTime - startTime
  if (timeGap > 0) {
    speed.x = differenceX / timeGap
    speed.y = differenceY / timeGap
    startTime = currentTime
  }
}

/**
 * Event handler for the end of a touch event.
 * Clears the easingLoop interval and sets a new easingLoop interval function
 * to ease the scroll after the user stops touching the screen.
 */
function touchEnd() {
  // Clear the easingLoop interval to ensure that any previous easing effects are stopped.
  clearInterval(easingLoop)

  // Set a new easingLoop interval function to ease the scroll after the user stops touching the screen.
  easingLoop = setInterval(function () {
    if (!speed.x && !speed.y) {
      clearInterval(easingLoop)
      return
    }

    if (Math.abs(speed.x) < 0.01 && Math.abs(speed.y) < 0.01) {
      clearInterval(easingLoop)
      return
    }

    applyTouchMove(speed.x * 30, speed.y * 30)

    speed.x *= 0.8
    speed.y *= 0.8
  }, 10)
}

/**
 * Updates the scroll state of the container element based on the touch event.
 * @param differenceX - The amount by which the container should be scrolled horizontally.
 * @param differenceY - The amount by which the container should be scrolled vertically.
 */
function applyTouchMove(differenceX: number, differenceY: number) {
  props.state.offsetTop(-differenceY)
  props.state.offsetLeft(-differenceX)
}

function scrollStart() {
  document.body.classList.add('select-none')
}

function scrollEnd() {
  document.body.classList.remove('select-none')
}
</script>
