<style scoped>
:deep() .kpi:is(.active, :hover) { cursor: pointer;border-color: var(--highlight); }
.top-button .button { width: 300px;cursor: pointer; }
.code-block { padding: 8px; }
.code-block .column:first-child { min-width: 75%;max-width: 400px; }
.code-block .column:first-child code {
  min-width: 100%;
  white-space: pre;
  font-size: 0.9em;
  border-left: 4px solid;
  overflow-y:auto;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.code-block .column:first-child input { border-top: none;border-top-left-radius: 0;border-top-right-radius: 0; }
.code-block .column:nth-child(2) { min-width: 25%; }
.code-block .column:nth-child(2) div { padding: 4px; }
.code-block .column:nth-child(2) button { width: 100px; }
span.ok { color: var(--positive)!important;margin-right: 4px; }
span.warning { color: var(--cat1)!important;margin-right: 4px; }
span.error, :deep() .kpi .value { color: var(--negative)!important;margin-right: 4px; }
span.ignored { color: var(--inactive)!important;margin-right: 4px; }
span.ignored .button { margin-left: 4px;padding: 2px 4px;font-size: 12px; }
.code-block.error .column:first-child code { border-left-color: var(--negative); }
.code-block.warning .column:first-child code { border-left-color: var(--cat1);  }
.code-block.ignored .column:first-child code { border-left-color: var(--inactive); }
.board .line  .cell:first-child { align-items: start; }
.tick_img::after { content: url("data:image/svg+xml;utf8,<svg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 52 52' width='16pt' height='16pt' style='enable-background:new 0 0 52 52;' xml:space='preserve'><g><path fill='%2328ae71' d='M26,0C11.664,0,0,11.663,0,26s11.664,26,26,26s26-11.663,26-26S40.336,0,26,0z M40.495,17.329l-16,18 C24.101,35.772,23.552,36,22.999,36c-0.439,0-0.88-0.144-1.249-0.438l-10-8c-0.862-0.689-1.002-1.948-0.312-2.811 c0.689-0.863,1.949-1.003,2.811-0.313l8.517,6.813l14.739-16.581c0.732-0.826,1.998-0.9,2.823-0.166 C41.154,15.239,41.229,16.503,40.495,17.329z'/></g></svg>"); }
.warning_img::after { content: url("data:image/svg+xml;utf8,<svg height='16pt' viewBox='0 0 512 512' width='16pt' xmlns='http://www.w3.org/2000/svg'><path d='m433.347656 512.921875h-352.898437c-27.71875-.003906-53.460938-14.355469-68.039063-37.9375-14.574218-23.578125-15.902344-53.023437-3.511718-77.820313l176.433593-352.914062c13.542969-27.117188 41.253907-44.25 71.566407-44.25s58.023437 17.132812 71.566406 44.25l176.433594 352.914062c12.390624 24.796876 11.0625 54.242188-3.511719 77.820313-14.574219 23.582031-40.320313 37.933594-68.039063 37.9375zm0 0' fill='%23ffb931'/><g fill='%23fff'><path d='m256.898438 128.203125c8.835937 0 16 7.164063 16 16v192c0 8.835937-7.164063 16-16 16-8.835938 0-16-7.164063-16-16v-192c0-8.835937 7.164062-16 16-16zm0 0'/><path d='m240.898438 384.203125h32v32h-32zm0 0'/></g></svg>"); }
.error_img::after { content: url("data:image/svg+xml;utf8,<svg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 52 52' width='16pt' height='16pt' style='enable-background:new 0 0 52 52;' xml:space='preserve'><path fill='%23cc1c3a' d='M26,0C11.664,0,0,11.663,0,26s11.664,26,26,26s26-11.663,26-26S40.336,0,26,0z M38.5,28h-25c-1.104,0-2-0.896-2-2 s0.896-2,2-2h25c1.104,0,2,0.896,2,2S39.604,28,38.5,28z'/></svg>"); }


.kpi.active, .kpi:hover { cursor: pointer;border-color: var(--highlight); }
.top-button .button { width: 300px;cursor: pointer; }
.code-block { padding: 8px; }
.code-block .column:first-child { min-width: 75%;max-width: 400px; }
.code-block .column:first-child code {
  min-width: 100%;
  max-width: 100%;
  max-height: 200px;
  white-space: pre;
  font-size: 0.9em;
  border-left: 4px solid;
  overflow-y:auto;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.code-block .column:first-child input { border-top: none;border-top-left-radius: 0;border-top-right-radius: 0; }
.code-block .column:nth-child(2) { min-width: 25%;padding-left: 8px; padding-right: 8px; }
.code-block .column:nth-child(2) div { padding: 4px; }
.code-block .column:nth-child(2) button { width: 100px; }
span.ok { color: var(--positive)!important;margin-right: 4px; }
span.warning { color: var(--cat1)!important;margin-right: 4px; }
span.error, .kpi .value { color: var(--negative)!important;margin-right: 4px; }
span.ignored { color: var(--inactive)!important;margin-right: 4px; }
span.ignored .button { margin-left: 4px;padding: 2px 4px;font-size: 12px; }
.code-block.error .column:first-child code { border-left-color: var(--negative); }
.code-block.warning .column:first-child code { border-left-color: var(--cat1);  }
.code-block.ignored .column:first-child code { border-left-color: var(--inactive); }
.board .line  .cell:first-child { align-items: start; }
.tick_img::after { content: url("data:image/svg+xml;utf8,<svg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 52 52' width='16pt' height='16pt' style='enable-background:new 0 0 52 52;' xml:space='preserve'><g><path fill='%2328ae71' d='M26,0C11.664,0,0,11.663,0,26s11.664,26,26,26s26-11.663,26-26S40.336,0,26,0z M40.495,17.329l-16,18 C24.101,35.772,23.552,36,22.999,36c-0.439,0-0.88-0.144-1.249-0.438l-10-8c-0.862-0.689-1.002-1.948-0.312-2.811 c0.689-0.863,1.949-1.003,2.811-0.313l8.517,6.813l14.739-16.581c0.732-0.826,1.998-0.9,2.823-0.166 C41.154,15.239,41.229,16.503,40.495,17.329z'/></g></svg>"); }
.warning_img::after { content: url("data:image/svg+xml;utf8,<svg height='16pt' viewBox='0 0 512 512' width='16pt' xmlns='http://www.w3.org/2000/svg'><path d='m433.347656 512.921875h-352.898437c-27.71875-.003906-53.460938-14.355469-68.039063-37.9375-14.574218-23.578125-15.902344-53.023437-3.511718-77.820313l176.433593-352.914062c13.542969-27.117188 41.253907-44.25 71.566407-44.25s58.023437 17.132812 71.566406 44.25l176.433594 352.914062c12.390624 24.796876 11.0625 54.242188-3.511719 77.820313-14.574219 23.582031-40.320313 37.933594-68.039063 37.9375zm0 0' fill='%23ffb931'/><g fill='%23fff'><path d='m256.898438 128.203125c8.835937 0 16 7.164063 16 16v192c0 8.835937-7.164063 16-16 16-8.835938 0-16-7.164063-16-16v-192c0-8.835937 7.164062-16 16-16zm0 0'/><path d='m240.898438 384.203125h32v32h-32zm0 0'/></g></svg>"); }
.error_img::after { content: url("data:image/svg+xml;utf8,<svg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 52 52' width='16pt' height='16pt' style='enable-background:new 0 0 52 52;' xml:space='preserve'><path fill='%23cc1c3a' d='M26,0C11.664,0,0,11.663,0,26s11.664,26,26,26s26-11.663,26-26S40.336,0,26,0z M38.5,28h-25c-1.104,0-2-0.896-2-2 s0.896-2,2-2h25c1.104,0,2,0.896,2,2S39.604,28,38.5,28z'/></svg>"); }


.messages { display: flex; flex-direction: column;}
.messages code { flex: auto}
.messages input { width: 100%; max-width: 100%}
.actions {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 8px;
}
</style>
<template lang='pug'>
transition(@leave='leave')
  loader(v-if="isLoading")
h1 {{ t[$root.screen.path] }}
  autocomplete.right(:data="search" v-model="autocomplete_search" :options="{ placeholder: t.search }")
.row.stretch
  kpi(v-for="category in categories" :class="{ active: ($root.query.category || '').includes(category.name) }" @click.native="update_filter('category', category.name)" :data="category.kpiData")
block()
  board(:data="checks.filter(d => !$root.query.category || $root.query.category.split('|').includes(d.category)).sort('category')" :metadata="{ expand: 'name', sort: 'result', desc: true, columns: ['label', 'category', 'result'] }")
    template(v-slot:cell-category="s")
      div {{ (t[s.line.category] || 'missing test').titleize() }}
    template(v-slot:cell-label="s")
      div {{ s.line.label }}
      span.ok(v-if="s.line.error_number + s.line.warning_number === 0") {{ t.no_issues }}
      span.warning(v-if="s.line.warning_number") {{ s.line.warning_number }} {{ t.warnings }}
      span.error(v-if="s.line.error_number") {{ s.line.error_number }} {{ t.errors }}
      span.ignored(v-if="s.line.messages.length - (s.line.error_number + s.line.warning_number)") {{ (s.line.messages.length - (s.line.error_number + s.line.warning_number)) }} {{ t.past_errors }}
        span.button(@click.prevent.stop="update_query({ hidden: $root.query.hidden ? null : true })") {{ !$root.query.hidden ? t.hide : t.show }}
    template(v-slot:cell-result="s")
      div
        .tick_img(style="height: 18px;margin-top: 4px;" v-if="s.line.result === 0")
        .warning_img(style="height: 18px;margin-top: 4px;" v-if="s.line.result === -1")
        .error_img(style="height: 18px;margin-top: 4px;" v-if="s.line.result === -2")
    template(v-slot:expand="s")
      block(v-if="(s.line.error_number + s.line.warning_number !==  0) || !$root.query.hidden && s.line.messages.length >0")
        h2 Errors & warnings
        template(v-for="e in s.line.messages")
          .row.code-block( v-if="!e.ignore || !$root.query.hidden" :class="[e.type, { ignored: e.ignore }]")
            .column.messages
              code {{ e.message }}
              input(:value="dataQualityIgnored[e.hash].comment" @change="update_comment(e.hash, $event.target.value)" @click.stop="" v-if="e.ignore" placeholder="Comment")
            .column.actions
              div(v-if="e.ignore")
                h4 {{ t.updated +':' }}
                div {{ dataQualityIgnored[e.hash].name }}
                div {{ (dataQualityIgnored[e.hash].date) }}
              .buttons
                button(@click.prevent.stop="window.navigator.clipboard.writeText(e.message)") {{ t.copy_to_clipboard }}
                button(@click.prevent.stop="ignore_toggle(e.hash)" :style="{ background: 'var(--' + (e.ignore ? 'inactive' : 'primary') +')'}") {{ e.ignore ? t.reset : t.ignore }}

</template>
<script>
import {useDataQuality} from "../composables/useDataQuality";
import {useProgress} from "../composables/useProgress";
import {computed} from "vue";
import { useShares } from '../composables/useShares'
import { useTranslation } from '../../../features/translation'

export default {
  icon: 'playlist_add_check',
  setup() {
    const {dataQuality, dataQualityIgnored, loaded: dqLoaded} = useDataQuality()
    const {shares, loaded: sLoaded} = useShares()
    const {t} = useTranslation()
    const {progress} = useProgress([dqLoaded, sLoaded])
    const isLoading = computed(() => progress.value !== 1)
    const search = computed(() => shares.value.reduce((acc, s) => {
      acc[`${s.slug}-${s.share_id}`] = [t[s.fund_name] || s.fund_name, s.share_letter].filter(s => !!s).join(' - ')
      return acc
    }, {}))
    return {dataQuality, dataQualityIgnored, isLoading, search, shares}
  },
  data() {
    return {
      autocomplete_search: null,
    }
  },
  watch: {
    autocomplete_search() {
      update_query({ autocomplete_search: this.autocomplete_search })
    }
  },
  mounted() {
    if($root.query.autocomplete_search){
      this.autocomplete_search = $root.query.autocomplete_search
    }
  },
  methods: {
    update_comment(hash, comment){
      set('data-quality-ignored.' + hash + '.comment', comment || null)
    },
    ignore_toggle(hash){
      const ignored_list = this.dataQualityIgnored || {}
      const value = ignored_list[hash] ? null : { hash, name: $root.profile.name, date: new Date().format('YYYY-MM-DD hh:mm') }
      set('data-quality-ignored.' + hash, value)
    },
  },
  computed: {
    categories() {
      return this.dataQuality.keys().filter(d => d !== 'sla')
      .map(c => {
        const sum = this.checks.filter(d => d.category === c).map(d => d.error_number + d.warning_number).sum()
        return {
          name: c,
          kpiData: [
            {
              title: c,
              value: sum
            }
          ]
        }
      })
    },
    checks() {
      const ignore_list = this.dataQualityIgnored || {}

      // Add test Key and category in test:
      // NB: SLA used only for AMI
      const enriched_test = this.dataQuality.filter((d,k) => k !== 'sla')
          .map((list_test, category) => list_test.map( (test, test_key) => {
            test.test_key = test_key
            test.category = category
            test.context = test_key.split('-').length >= 2 ? test_key.split('-')[1] : ''
            return test
          }).v()).v().flatten()


      let filtered_checks = enriched_test
      if(this.autocomplete_search){
        const [slug, share_id] = this.autocomplete_search.split('-')
        const share = this.shares.find(s => s.slug === slug && s.share_id === share_id)
        const accounting_fund_id = share?.fund_id
        filtered_checks = enriched_test.filter(d => d.context === slug || d.context === share_id || d.context === accounting_fund_id)
      }

      return filtered_checks.group('name').v().map(type_test => {
        const messages = type_test
            .filter(d => d.error_output || d.warning_output)
            .map(d => {
              const message = (d.error_output || d.warning_output).join('\n')
              const hash = d.ignore_hash || message.md5()
              return {
                type: d.error_output ? 'error': 'warning',
                message,
                hash,
                ignore: !!ignore_list[hash],
              }
            })
        const error_number = messages.filter(d => d.type === 'error' && !d.ignore).v().length
        const warning_number = messages.filter(d => d.type === 'warning' && !d.ignore).v().length
        return {
          name: type_test[0].name,
          label: type_test[0].description,
          category: type_test[0].category,
          messages,
          error_number,
          warning_number,
          result: error_number + warning_number === 0 ? 0 : error_number === 0 ? -1 : -2,
        }
      })
    },
  },
}

</script>
