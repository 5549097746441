import { onMounted, onUnmounted, ref } from 'vue'
import { dbRef } from '../../../features/firebase'
const workflows = ref([])
const initialized = ref(false)
export function useWorkflows() {
  const workflowsRef = dbRef.child('/data/workflows')
  const loaded = ref(false)
  const onUpdate = snap => {
    workflows.value = (snap.val() || {})
    loaded.value = true
    initialized.value = true
  }
  onMounted(() => {
    loaded.value = false
    workflowsRef.on('value', onUpdate)
  })
  onUnmounted(() => workflowsRef.off('value', onUpdate))
  return {
    workflows,
    loaded,
    initialized,
  }
}
