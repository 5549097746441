<template>
  <!-- nx-arrow-head-triangle -->
  <polygon
    v-if="props.isFilled"
    :style="{
      transform: `rotate(${props.rotation}deg)`,
      transformOrigin: `${props.pointX}px ${props.pointY}px`,
    }"
    :points="points"
    fill="black"
    stroke-width="0"
  />
  <polyline
    v-else
    :style="{
      transform: `rotate(${props.rotation}deg)`,
      transformOrigin: `${props.pointX}px ${props.pointY}px`,
    }"
    :points="points"
    fill="none"
    stroke="black"
    stroke-linecap="round"
    :stroke-width="props.strokeWidth"
  />
</template>

<script setup lang="ts">
import { computed } from '@vue/reactivity'

interface IProps {
  /**
   * Tipping point of the arrow head on the X axis
   */
  pointX: number
  /**
   * Tipping point of the arrow head on the Y axis
   */
  pointY: number
  /**
   * Line width of the arrow.
   * Only applies if the triangle is not filled with colour.
   */
  strokeWidth?: number
  /**
   * Rotation of the arrow head in degrees
   * @example 90
   */
  rotation?: number
  /**
   * The base of the triangle formed by the arrow head in px
   */
  base: number
  /**
   * The altitude of the triangle formed by the arrow head in px
   */
  altitude: number
  /**
   * Boolean that indicates whether the content of the triangle should fill with color
   */
  isFilled?: boolean
  /**
   * The small altitude of the double triangle formed by the arrow head in px
   */
  smallAltitude?: number
}

const props = withDefaults(defineProps<IProps>(), {
  strokeWidth: 2,
  rotation: 0,
  isFilled: false,
  smallAltitude: 0,
})

const points = computed(() => {
  const smallAltitude = props.isFilled ? `${props.pointX},${props.pointY + props.altitude - props.smallAltitude}` : ''

  return `
    ${props.pointX - props.base / 2},${props.pointY + props.altitude}
    ${props.pointX},${props.pointY} 
    ${props.pointX + props.base / 2},${props.pointY + props.altitude}
    ${smallAltitude}
  `
})
</script>
