<script setup>
//This is a feature test (if needed) :
//It allows to zoom in a graph and see details on a specific bar and its surrondings

import Chart from "chart.js/auto"
import { onMounted, ref, computed, watch, nextTick } from "vue"
import performance_data from "../../data/performance.json"

const combined_data = ref(
  performance_data
    .filter((d) => d.portfolioCode === $root.params.userflow)
    .__.map((d) => ({
      Date: new Date(d.date).format(),
      Performance: d.actifNet,
      Capital: d.capital,
      "Preferred Return": d.preferredReturn,
      "Catch-Up": d.catchUpHurdle,
      "Performance percent": d.performance / 100,
      "Capital percent": d.capitalPercentage / 100,
      "Preferred Return percent": d.preferredReturnPercentage / 100,
      "Catch-Up percent": d.catchUpPercentage / 100,
      "Capital filled": d.actifNet > d.capital ? "100%" : d.actifNet / d.capital,
      "Preferred Return filled":
        d.actifNet > d.preferredReturn ? "100%" : d.actifNet > d.capital ? d.actifNet / d.preferredReturn : "0%",
      "Catch-up filled":
        d.actifNet > d.catchUpHurdle ? "100%" : d.actifNet > d.preferredReturn ? d.actifNet / d.catchUpHurdle : "0%",
    }))
)

const fullData = {
  labels: combined_data.value.map((object) => object.Date),
  datasets: {
    dataPerformance: combined_data.value.map((object) => object.Performance),
    dataCapital: combined_data.value.map((object) => object.Capital),
    dataReturn: combined_data.value.map((object) => object["Preferred Return"]),
    dataCatchUp: combined_data.value.map((object) => object["Catch-Up"]),
  },
}

const canvas = ref(null)
const canvas_detailed = ref(null)
var isVisible = ref(false)
var index = ref(0)
var date = ref(combined_data.value[index.value].Date)
var performance_detail = ref(combined_data.value[index.value]["Performance percent"] * 100)
var chart = null

const filter_data = computed(() => {
  const begin = Math.max(0, index.value - 1)
  const end = index.value + 2
  const new_Object = {
    labels: fullData.labels.slice(begin, end),
    datasets: {
      dataPerformance: fullData.datasets.dataPerformance.slice(begin, end),
      dataCapital: fullData.datasets.dataCapital.slice(begin, end),
      dataReturn: fullData.datasets.dataReturn.slice(begin, end),
      dataCatchUp: fullData.datasets.dataCatchUp.slice(begin, end),
    },
  }
  return new_Object
})

const configuration = {
  type: "bar",
  data: {
    labels: fullData.labels,
    datasets: [
      {
        type: "line",
        label: "Performance",
        backgroundColor: "transparent",
        borderWidth: 2,
        borderColor: "#e65100",
        data: fullData.datasets.dataPerformance,
        pointHitRadius: 20,
      },
      {
        type: "bar",
        label: "Capital",
        backgroundColor: "#0dd8b8 ",
        borderWidth: 2,
        borderColor: "#0dd8b8 ",
        data: fullData.datasets.dataCapital,
      },
      {
        type: "bar",
        label: "Preferred Return",
        backgroundColor: "rgba(150,245,220, 1)",
        borderWidth: 2,
        borderColor: "rgba(150,245,220, 1)",
        data: fullData.datasets.dataReturn,
      },
      {
        type: "bar",
        label: "Catch-Up",
        backgroundColor: "rgb(53,240,150)",
        borderWidth: 2,
        borderColor: "rgb(53,240,150)",
        data: fullData.datasets.dataCatchUp,
      },
      {
        type: "bar",
        label: "Profit Sharing",
        backgroundColor: "rgba(0,200,0, 0.8)",
        borderWidth: 2,
        borderColor: "rgba(0,200,0, 0.8)",
      },
    ],
  },

  options: {
    responsive: true,
    // maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: "Performance Analysis",
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    onClick: (a, detail) => ZoomIn(detail),
  },
}

const ZoomIn = (detail) => {
  index.value = detail[0].index
  date.value = combined_data.value[index.value].Date
  performance_detail.value = format(".2%")(combined_data.value[index.value]["Performance percent"])
  isVisible.value = true
}

const config_detailed = computed(() => {
  return {
    type: "bar",
    data: {
      labels: filter_data.value.labels,
      datasets: [
        {
          type: "line",
          label: "Performance",
          backgroundColor: "transparent",
          borderWidth: 2,
          borderColor: "#e65100",
          data: filter_data.value.datasets.dataPerformance,
          pointHitRadius: 20,
        },
        {
          type: "bar",
          label: "Capital",
          backgroundColor: "#0dd8b8 ",
          borderWidth: 2,
          borderColor: "#0dd8b8 ",
          data: filter_data.value.datasets.dataCapital,
        },
        {
          type: "bar",
          label: "Preferred Return",
          backgroundColor: "rgba(150,245,220, 1)",
          borderWidth: 2,
          borderColor: "rgba(150,245,220, 1)",
          data: filter_data.value.datasets.dataReturn,
        },
        {
          type: "bar",
          label: "Catch-Up",
          backgroundColor: "rgb(53,240,150)",
          borderWidth: 2,
          borderColor: "rgb(53,240,150)",
          data: filter_data.value.datasets.dataCatchUp,
        },
        {
          type: "bar",
          label: "Profit Sharing",
          backgroundColor: "rgba(0,200,0, 0.8)",
          borderWidth: 2,
          borderColor: "rgba(0,200,0, 0.8)",
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
          min: 0,
          max: 20000000,
        },
      },
    },
  }
})

onMounted(() => {
  new Chart(canvas.value, configuration)
})

watch(index, async () => {
  await nextTick()
  if (chart) chart.destroy()
  chart = new Chart(canvas_detailed.value, config_detailed.value)
  console.log("watcher", canvas_detailed.value, config_detailed.value)
})
</script>
<script>
export const additions = { icon: "ic_timeline" }
</script>
<template>
  <div>
    <div class="flex flex-col items-center">
      <h1 class="justify-center font-bold mb-6 text-3xl">PE2 : Test Zoom Feature {{ t.performanceAnalysis }}</h1>
      <div class="flex w-[80vw]">
        <canvas ref="canvas"></canvas>
      </div>
    </div>
    <div>
      <div v-if="isVisible" class="flex flex-row justify-around pt-20">
        <div class="flex flex-col items-center w-1/3">
          <div class="font-bold text-2xl">{{ t.performanceFocus }}</div>
          <div class="font-bold pb-8">{{ performance_detail }} on {{ date }}</div>
          <div class="w-auto h-[250px]">
            <canvas ref="canvas_detailed"></canvas>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
