export function useContribution() {
  const getContributionData = async (params) => {
    console.log('getContributionData')
    const resp = await axios({
      url: '/dr',
      method: 'post',
      data: {
        variables: params,
        query: `query ContributionQuery(
          $isinShare: String!
          $asof: Date
          $domain: Domain!
        ) {
          businessData {
            share(shareId: $isinShare, asof: $asof) {
              ${$root.config.characteristicsSchema || ''}
              asof
              axis_of_analysis
              slug
              benchmark {
                id
                label
              }
              contribution(domain: $domain) {
                fuid
                name
                contribution_fund
                contribution_benchmark
                pnlOrFeesPerShare_fund
                pnlOrFeesPerShare_benchmark
                axis
              }
            }
          }
        }`,
      },
    })
    return resp.data.data.businessData
  }
  return {
    getContributionData,
  }
}
