/* eslint-disable no-use-before-define */
/* eslint-disable no-prototype-builtins */
import {
  IClassStringArray,
  IGenericStyles,
  ITheme,
  IThemeConfig,
  IThemeElement,
  IThemeElementConfigValue,
  IThemeExtendValue,
  IThemeSimple,
  ITypeThemeBase,
  buttonTheme,
  gridTheme,
  listItemTheme,
  switchTheme,
} from '@hauru/common'
import { reactiveComputed } from '@vueuse/core'
import { CSSProperties, ComputedRef, computed, inject, provide, InjectionKey, ref, Ref, watch } from 'vue'

/**
 * Executes any functions contained in the style object passing the given props as argument
 * @param styles Style object that is being evaluated
 * @param props The props of the component whose styles we are evaluating
 */
export function evaluateClassStringArray(
  styles: IClassStringArray | number | CSSProperties,
  props: any,
): typeof styles {
  if (!styles && styles !== 0) return

  if (Array.isArray(styles)) return styles.map(i => evaluateClassStringArray(i, props)) as IClassStringArray
  else if (styles instanceof Function) return styles(props)
  else if (typeof styles === 'object') return evaluateStyles(styles, props) as CSSProperties

  return styles
}

/**
 * Executes any functions contained in the style object passing the given props as argument
 * @param styles Style object that is being evaluated
 * @param props The props of the component whose styles we are evaluating
 * @param args Any other arguments we wish to add that will cause the reevaluation of styles
 */
export function evaluateStyles(
  styles: IGenericStyles | CSSProperties | undefined,
  props: any,
  // ...args: any[]
): IGenericStyles {
  if (!styles) return {}

  const obj: IGenericStyles = {}

  for (const styleKey in styles) {
    // @ts-ignore
    obj[styleKey] = evaluateClassStringArray(styles[styleKey], props)
  }
  return obj
}

const $key: InjectionKey<{
  config: IThemeConfig
  value: IThemeConfig
  currentTheme: Ref<string>
  trackCurrentModifications: Ref<number>
}> = Symbol('theme-state')
const $underEvaluation = Symbol('under-evaluation')

/**
 * Creates a deep copy of an object. Only copies enumerable properties, not symbols.
 *
 * @param obj The object to be cloned.
 */
function deepClone<T>(obj: T): T {
  if (obj === null || typeof obj !== 'object') {
    return obj
  }

  if (Array.isArray(obj)) {
    const clonedArray = []
    for (let i = 0; i < obj.length; i++) {
      clonedArray[i] = deepClone(obj[i])
    }
    return clonedArray as T
  }

  const clonedObj: any = {}
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      clonedObj[key] = deepClone(obj[key])
    }
  }

  return clonedObj
}

/**
 * When merging objects, this wrapper function will allow to extend previously defined values
 *
 * @param str The string that will extend the previous value
 */
export function extend<T>(value: T): IThemeExtendValue<T> {
  return {
    $specialObject: 'extend',
    value,
  }
}

export function evaluateThemeFunctions<TTheme = any>(theme: TTheme, props: any): TTheme {
  // @ts-ignore
  return evaluateStyles(theme, props)
}

/**
 * Merges two objects, with values from `obj2` taking precedence over values from `obj1`.
 * Deeply merges nested objects, but not arrays.
 *
 * @template X The type of `obj1`.
 * @template Y The type of `obj2`.
 * @param obj1 The first object to merge.
 * @param obj2 he second object to merge.
 */
export function extendValues<X = any, Y = any>(obj1: X, obj2: Y, treatSpecialValues = false): X & Y {
  const target: any = { ...obj1 }
  for (const key in obj2) {
    if (typeof obj2[key] === 'object' && obj2[key] !== null && !Array.isArray(obj2[key])) {
      if ((obj2[key] as any)?.$specialObject === 'extend') {
        if (treatSpecialValues) target[key] = [(obj1 as any)?.[key], (obj2[key] as any).value].flat()
        else target[key] = obj2[key]
      } else target[key] = extendValues((obj1 as any)?.[key] ?? {}, obj2[key], treatSpecialValues)
    } else {
      target[key] = obj2[key]
    }
  }
  return target
}

// Internal flag object to help with handling circular references in theme configuration
const flag = {
  start: (obj: any) => {
    if (obj?.[$underEvaluation] === true) {
      throw new Error('Circular reference detected in theme configuration')
    }
    obj[$underEvaluation] = true
  },
  end: (obj: any) => {
    obj[$underEvaluation] = false
  },
  finished: (obj: any) => obj?.[$underEvaluation] === false,
}

/**
 * Applies relative path to a base path and returns the resulting path
 *
 * @param path The path array to be used as a base.
 * @param relativePath The relative path string to be applied.
 */
function tranformPath(path: string[], relativePath: string) {
  if (relativePath.startsWith('.')) {
    let i = 1
    let track = 0
    while (relativePath.substring(track).length > 0) {
      if (relativePath.substring(track).startsWith('../')) {
        i++
        track += 3
      } else if (relativePath.substring(track).startsWith('./')) {
        track += 2
      } else {
        break
      }
    }
    return path.slice(0, -i).concat(relativePath.substring(track).split('/'))
  }
  return relativePath.split('/')
}

/**
 * Traverses the given object to retrieve the value at the specified path.
 *
 * @param obj The object to traverse.
 * @param path The path array to traverse.
 */
function traversePathGet(obj: any, path: string[]) {
  try {
    let current = obj
    for (const key of path) {
      current = current[key]
    }
    return current
  } catch (e) {
    throw new Error(`Invalid path "${path.join('/')}"`)
  }
}

/**
 * Calculates value of a theme type, resolves extended values, and prevents circular references.
 *
 * @param evaluatedConfig The theme configuration to be evaluated.
 * @param themeName The name of the theme to evaluate.
 * @param path The path array to the theme type to be evaluated.
 */
function evaluateThemeType(evaluatedConfig: IThemeConfig, themeName: string, path: string[]) {
  // console.log('evaluateThemeType', evaluatedConfig, themeName, path)
  const theme = evaluatedConfig.themes[themeName]
  let type = traversePathGet(theme, path) as ITypeThemeBase
  const typeParent = traversePathGet(theme, path.slice(0, -1)) as ITypeThemeBase
  const typeKey = path[path.length - 1] as keyof typeof type
  const keys = Object.keys(type) as (keyof typeof type)[]

  // Skip types that have already been evaluated
  if (flag.finished(type)) return
  flag.start(type)

  if (type.$extends) {
    evaluateThemeType(evaluatedConfig, themeName, tranformPath(path, type.$extends))
  }

  for (const key of keys) {
    if (type[key] !== null && typeof type[key] === 'object' && !Array.isArray(type[key])) {
      evaluateThemeType(evaluatedConfig, themeName, [...path, key])
    }
  }

  if (type.$extends) {
    const parentPath = tranformPath(path, type.$extends)
    const parentTypeValue = traversePathGet(theme, parentPath)

    typeParent[typeKey] = extendValues(parentTypeValue, type, true)
  }

  type = traversePathGet(theme, path) as ITypeThemeBase
  flag.end(type)
}

/**
 * Calculates value of a theme, resolves parent themes, and prevents circular references.
 *
 * @param evaluatedConfig The theme configuration to be evaluated.
 * @param themeName The name of the theme to evaluate.
 */
function evaluateExtendedTheme(evaluatedConfig: IThemeConfig, themeName: string) {
  const theme = evaluatedConfig.themes[themeName]

  // Skip themes that have already been evaluated
  if (flag.finished(theme)) return
  flag.start(theme)

  const $default = evaluatedConfig.themes?.$default && themeName !== '$default' ? '$default' : undefined
  const parentTheme = theme.$extends || $default
  if (parentTheme) {
    if (!evaluatedConfig.themes[parentTheme]) throw new Error(`Parent theme "${parentTheme}" does not exist`)
    if (!flag.finished(evaluatedConfig.themes[parentTheme])) evaluateExtendedTheme(evaluatedConfig, parentTheme)

    evaluatedConfig.themes[themeName] = extendValues(evaluatedConfig.themes?.[parentTheme!] ?? {}, theme)
  }

  flag.end(theme)
}

/**
 * Calculates theme types of theme
 *
 * @param evaluatedConfig The theme configuration to be evaluated.
 * @param themeName The name of the theme to evaluate.
 */
function evaluateTheme(evaluatedConfig: IThemeConfig, themeName: string) {
  const theme = evaluatedConfig.themes[themeName]
  const components = Object.keys(theme)

  for (const key of components) {
    evaluateThemeType(evaluatedConfig, themeName, [key])
  }
}

/**
 * Flattens a deeply nested object, using dot notation for keys.
 * Example output: { 'a.b.c': 'x', 'a.d': 'y', 'e.f': 'z' }
 *
 * @param obj The object to be flattened.
 * @param prefix The current key prefix (used internally for recursion).
 */
function flattenObject<T extends { [key: string]: any }>(obj: T, prefix = '') {
  const keys = Object.keys(obj) as string[]
  return keys.reduce((acc, key) => {
    const newKey = prefix ? `${prefix}.${key}` : key
    if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
      Object.assign(acc, flattenObject(obj[key], newKey))
    } else {
      if (prefix !== '') acc[prefix] = obj
    }
    return acc
  }, {} as { [key: string]: any })
}

/**
 * Flattens theme components for easier access.
 *
 * @param evaluatedConfig The evaluated theme configuration.
 * @param themeName The name of the theme to flatten.
 */
function flattenTheme(evaluatedConfig: IThemeConfig, themeName: string) {
  const theme = evaluatedConfig.themes[themeName]
  const components = Object.keys(theme) as (keyof typeof theme)[]

  for (const key of components) {
    const component = theme[key]
    if (typeof component === 'object' && component !== null && !Array.isArray(component)) {
      Object.assign(theme[key] as any, flattenObject(component))
    }
  }
}

/**
 * Evaluates themes by evaluating theme extensions, theme types & their extensions, and flattening the results.
 *
 * @param themeConfig The theme configuration to be evaluated.
 */
function evaluateThemes(themeConfig: IThemeConfig) {
  let evaluatedConfig: IThemeConfig = deepClone(themeConfig)
  const themes = Object.keys(evaluatedConfig.themes)

  for (const key of themes) {
    evaluateExtendedTheme(evaluatedConfig, key)
  }
  evaluatedConfig = deepClone(evaluatedConfig)
  for (const key of themes) {
    evaluateTheme(evaluatedConfig, key)
  }
  for (const key of themes) {
    flattenTheme(evaluatedConfig, key)
  }

  return evaluatedConfig
}

export type IThemeState = ReturnType<typeof useTheme>

/**
 * Provides a set of functions to manage theme configurations.
 */
export function useTheme() {
  const storedState = inject($key, {
    config: {} as IThemeConfig,
    value: {} as IThemeConfig,
    currentTheme: ref('nx'),
    trackCurrentModifications: ref(0),
  })

  const trackParentModifications = storedState.trackCurrentModifications
  const trackCurrentModifications = ref(0)

  const parentTheme = storedState.currentTheme
  const currentTheme = ref(parentTheme.value)
  const overrideParentTheme = ref(false)

  let addedThemes: IThemeConfig[] = []

  const state = {
    config: storedState.config,
    value: storedState.value,
    currentTheme,
    trackCurrentModifications,
    initTheme,
    addToTheme,
    patchActiveTheme,
    setTheme,
    replaceTheme,
    computedThemeType,
    reactiveComputedThemeType,
    computedTheme,
  }

  watch(parentTheme, () => {
    if (overrideParentTheme.value) return
    currentTheme.value = parentTheme.value
  })

  watch(trackParentModifications, () => {
    const parentState = inject($key)
    state.config = parentState?.config ?? ({} as IThemeConfig)
    state.value = parentState?.value ?? ({} as IThemeConfig)

    for (let i = 0; i < addedThemes.length; i++) {
      addToTheme(addedThemes[i], { build: i === addedThemes.length - 1, rerun: true })
    }
  })

  /**
   * Adds a new theme configuration to the current theme, with the new configuration taking precedence over the current one.
   *
   * @param config The theme configuration to add.
   */
  function addToTheme(config: IThemeConfig, { build = true, rerun = false } = {}) {
    // console.group('addToTheme Start', state, config)

    if (!rerun) addedThemes.push(config)
    trackCurrentModifications.value++

    state.config = extendValues(state.config, config)
    if (build) state.value = evaluateThemes(state.config)
    // console.log('addToTheme End', state)
    // console.groupEnd()

    provide($key, state)
    return state
  }

  function patchActiveTheme(patch: ITheme) {
    trackCurrentModifications.value++
    state.value = extendValues(state.value, { themes: { [currentTheme.value]: patch } })
    provide($key, state)
  }

  /**
   * Changes the current theme for all components that are children of the current component.
   *
   * @param theme The name of the theme to set as the current theme.
   */
  function setTheme(theme?: string) {
    if (!theme) return resetTheme()

    overrideParentTheme.value = true
    currentTheme.value = theme
  }

  /**
   * Resets theme configuration to the parent provided value.
   */
  function resetTheme() {
    overrideParentTheme.value = false
    currentTheme.value = parentTheme.value
  }

  /**
   * Replaces the current theme configuration with a new one.
   * !IMPORTANT: This function has never been tested, it's a theoritical implementation.
   *
   * @param config The theme configuration to replace the current one.
   */
  function replaceTheme(config: IThemeConfig, build = true) {
    state.config = config
    addedThemes = [config]
    if (build) state.value = evaluateThemes(state.config)

    provide($key, state)
    return state
  }

  /**
   * Computes the theme configuration for a specified theme element, given a theme and type.
   *
   * @template T The type of theme element.
   * @param element The theme element to compute the configuration for.
   * @param args Optional arguments to specify the theme and type of the element.
   */
  function computedThemeTypeBase<T extends IThemeElement>(
    element: T,
    args: { theme?: string; type?: string } = {},
  ): () => IThemeElementConfigValue<T> {
    return () => {
      const theme = args.type && args.theme ? args.theme : currentTheme.value
      const type = args.type ?? args.theme

      const defaultTheme = state.value.themes?.[theme]
      const elementTheme = (defaultTheme?.[element] as any)?.[
        type ?? (defaultTheme?.[element] as any)?.$default ?? 'basic'
      ] as IThemeElementConfigValue<T>
      // console.log('recalc', element, args, defaultTheme, elementTheme)
      return elementTheme
    }
  }
  function computedThemeType<T extends IThemeElement>(
    element: T,
    args: { theme?: string; type?: string } = {},
  ): ComputedRef<IThemeElementConfigValue<T>> {
    return computed(computedThemeTypeBase(element, args))
  }
  function reactiveComputedThemeType<T extends IThemeElement>(
    element: T,
    args: { theme?: string; type?: string } = {},
  ): IThemeElementConfigValue<T> {
    return reactiveComputed(computedThemeTypeBase(element, args))
  }

  /**
   * Computes the theme configuration for a specified theme element, given a theme and type.
   *
   * @template T The type of theme element.
   * @param element The theme element to compute the configuration for.
   * @param args Optional arguments to specify the theme and type of the element.
   */
  function computedTheme(args: { theme?: string; type?: string } = {}): ComputedRef<IThemeSimple> {
    const theme = args.type && args.theme ? args.theme : currentTheme.value

    return computed(() => {
      return state.value.themes[theme] as IThemeSimple
    })
  }

  /**
   * Initializes the theme with a default theme configuration.
   */
  function initTheme() {
    state.addToTheme(buttonTheme, { build: false })
    state.addToTheme(switchTheme, { build: false })
    state.addToTheme(listItemTheme, { build: false })
    state.addToTheme(gridTheme, { build: false })
  }

  return state
}
