<template>
  <grk-block :data="data" :options="options">
    <div class="flex w-full flex-col">
      <div class="flex w-1/2 flex-col px-2">
        <div class="flex w-full justify-between">
          <div class="flex flex-col">
            <div class="flex font-semibold">Lower risk</div>
            <div class="flex">Potentially lower reward</div>
          </div>
          <div class="flex flex-col">
            <div class="flex justify-end font-semibold">Higher risk</div>
            <div class="flex justify-end">Potentially higher reward</div>
          </div>
        </div>
        <div class="my-1 flex w-full items-center justify-between text-base">
          <div class="z-10 flex">&#9664;</div>
          <hr class="-mx-1 h-[2px] flex-1 bg-black print:bg-black" />
          <div class="z-10 flex">&#9654;</div>
        </div>
        <div class="-mt-[26px] flex h-full w-full px-3">
          <div v-for="num in possible_risks" class="flex h-5 flex-1 justify-center text-center align-middle font-semibold">
            <div class="w-5 rounded-xl p-0.5 align-middle" :class="num === risk ? 'bg-primary text-white' : 'bg-neutral-300'">{{ num }}</div>
          </div>
        </div>
      </div>
      <div class="mt-3 columns-2 space-y-2 text-justify" v-if="data.text" v-html="insane(htmlstring)"></div>
    </div>
  </grk-block>
</template>

<script setup>
import { marked } from "marked"
import insane from "insane"
const htmlstring = marked(props.data.text)
const props = defineProps({
  data: {
    type: [Object, String],
  },
  options: {
    type: Object,
  },
})
const sep = props.options.sep || "/"
const risk = props.data.risk_profile.split(sep)[0] * 1
const max = props.data.risk_profile.split(sep)[1] * 1
const possible_risks = [...Array(max + 1).keys()].splice(1)
</script>

<style scoped>
li {
  list-style-type: square;
  list-style-position: outside;
  margin-left: calc(var(--text_size) * (1.5));
}
</style>
