<template>
  <!-- grid-header-column -->
  <nx-dropdown
    :nx-header-container="props.header.index"
    type="grid.header"
    class="relative shrink-0 grow-0"
    :class="[gridTheme?.header?.column?.container]"
    :style="{
      gridRow: `span ${gridRowSpan}`,
      gridColumn: `span ${gridColumnSpan}`,
    }"
    @mouseover="isButtonRendered = true"
  >
    <template #button="{ shown, show }">
      <div
        class="group flex h-full border-r border-t border-solid"
        :class="[
          gridTheme?.header?.column?.idle,
          shown ? gridTheme?.header?.column?.selected : '',
          props.isFirstColumn ? 'border-l' : '',
          !header.visible.length ? 'border-b' : '',
        ]"
        @contextmenu.prevent="
          () => {
            hideAllPoppers()
            show()
          }
        "
      >
        <nx-button
          :qa-header-toggle="header.isExpanded"
          v-if="header.isExpandable.value"
          theme="grid.header.toggle"
          :icon="header.isExpanded ? gridTheme?.icons?.columnCollapse.i : gridTheme?.icons?.columnExpand.i"
          @click.stop="header.toggleExpanded()"
        ></nx-button>
        <input
          qa-header-select-all
          v-if="props.state.showCheckbox && props.level === 0 && props.isFirstColumn"
          :class="[
            gridTheme?.header?.checkbox?.idle,
            props.state.selection.items.isAll() ? gridTheme?.header?.checkbox?.checked : '',
            props.state.selection.items.list.length > 0 ? gridTheme?.header?.checkbox?.anyChecked : '',
          ]"
          type="checkbox"
          :checked="props.state.selection.items.isAll()"
          @click.stop="handleAllSelect"
        />
        <div
          nx-header
          :class="[
            gridTheme?.header?.column?.content,
            currentSortLabel && state.columns.sortBy.getOrder(currentSortLabel)
              ? gridTheme?.header?.column?.sorted
              : '',
          ]"
        >
          <slot name="header" :header="header">
            {{ header.label }}
          </slot>
        </div>
        <div
          v-if="
            currentSortLabel && state.columns.sortBy.getOrder(currentSortLabel) && state.columns.sortBy.list.length > 1
          "
          :class="gridTheme?.header?.column?.sortOrder"
        >
          {{ state.columns.sortBy.list.findIndex(item => item.label === currentSortLabel) + 1 }}
        </div>
        <nx-button
          :qa-header-sort="state.columns.sortBy.getOrder(currentSortLabel)"
          v-if="isButtonRendered && currentSortLabel"
          theme="grid.header.action"
          :icon="
            currentSortLabel && state.columns.sortBy.getOrder(currentSortLabel) === -1
              ? gridTheme?.icons?.columnSortDescending.i
              : gridTheme?.icons?.columnSortAscending.i
          "
          :state="currentSortLabel && state.columns.sortBy.is(currentSortLabel) ? 'selected' : 'idle'"
          @click.stop="cycle(currentSortLabel!)"
        ></nx-button>
        <div
          :class="gridTheme?.resizer?.container"
          @pointerdown.stop="onResizeStart"
          @click.stop
          @dblclick.stop="header.autoSize()"
        >
          <div :class="gridTheme?.resizer?.content"></div>
        </div>
      </div>
    </template>
    <div class="flex flex-col" :class="gridTheme?.header?.dropdown?.container">
      <div class="flex shrink-0 grow-0 basis-auto" :class="gridTheme?.header?.dropdown?.content">
        <div class="flex grow flex-col">
          <nx-list-item qa-sort-ascending v-if="currentSortLabel" type="grid" @click="toggle(currentSortLabel, 1)">
            <nx-dynamic-icon v-bind="(gridTheme?.icons?.columnSortAscending as any)" />
            {{ gridTheme?.labels.columnSortAscending }}
          </nx-list-item>
          <nx-list-item qa-sort-descending v-if="currentSortLabel" type="grid" @click="toggle(currentSortLabel, -1)">
            <nx-dynamic-icon v-bind="(gridTheme?.icons?.columnSortDescending as any)" />
            {{ gridTheme?.labels.columnSortDescending }}
          </nx-list-item>
          <!-- divider -->
          <nx-list-item qa-group-by-option type="grid" @click="state?.columns.groupBy.toggle(header.label)">
            <nx-dynamic-icon
              v-bind="(
                state?.columns.groupBy.is(header.label)
                  ? gridTheme?.icons?.columnUngroup
                  : gridTheme?.icons?.columnGroup as any)
              "
            />
            {{
              state?.columns.groupBy.is(header.label) ? gridTheme?.labels.columnUngroup : gridTheme?.labels.columnGroup
            }}
          </nx-list-item>
          <template v-if="!isLastColumnLevel">
            <template v-if="props.isFreezed">
              <!-- <nx-list-item type="grid" @click="removeFromFreezed()">
                <nx-dynamic-icon v-bind="gridTheme?.icons?.columnUnfreeze" />
                {{ gridTheme?.labels.columnUnfreeze }}
              </nx-list-item> -->
              <nx-list-item qa-freeze-up-to-option type="grid" @click="removeAllFromFreezed()">
                <nx-dynamic-icon v-bind="(gridTheme?.icons?.columnUnfreezeUpTo as any)" />
                {{ gridTheme?.labels.columnUnfreezeUpTo }}
              </nx-list-item>
            </template>
            <template v-else>
              <!-- <nx-list-item type="grid" @click="addToFreezed()">
                <nx-dynamic-icon v-bind="gridTheme?.icons?.columnFreeze" />
                {{ gridTheme?.labels.columnFreeze }}
              </nx-list-item> -->
              <nx-list-item qa-add-all-to-freezed type="grid" @click="addAllToFreezed()">
                <nx-dynamic-icon v-bind="(gridTheme?.icons?.columnFreezeUpTo as any)" />
                {{ gridTheme?.labels.columnFreezeUpTo }}
              </nx-list-item>
            </template>
          </template>
          <nx-list-item qa-toggle-show v-if="props.state.display !== 'pivot'" type="grid" @click="header.toggleShow()">
            <nx-dynamic-icon v-bind="(gridTheme?.icons?.columnHide as any)" />
            {{ gridTheme?.labels.columnHide }}
          </nx-list-item>
        </div>
      </div>
    </div>
  </nx-dropdown>
</template>

<script setup lang="ts">
import { IGridHeader, IGridState, IMoveEvent, useMove, useTheme } from '@hauru/common'
import { hideAllPoppers } from 'floating-vue'
import { computed, ref } from 'vue'

interface IProps {
  /**
   * Allows to manually bypass the theme set as default, among the themes provided by the theme config
   */
  theme?: string
  /**
   * The type of the dropdown among the types defined in the theme config
   */
  type?: string
  state: IGridState
  header: IGridHeader
  level: number
  isFirstColumn: boolean
  isFirstColumnLevel: boolean
  isLastColumnLevel: boolean
  isFreezed: boolean
}

const props = withDefaults(defineProps<IProps>(), {})

const currentSortLabel = computed(() => {
  return props.header.visibleOrDefaultCollapsed.length === 0
    ? props.header.label
    : props.header.visibleOrDefaultCollapsed.length === 1
    ? props.header.visibleOrDefaultCollapsed[0].label
    : undefined
})

const isButtonRendered = ref(
  currentSortLabel.value && props.state.columns.sortBy.getOrder(currentSortLabel.value) !== 0,
)

const themeConfig = useTheme()
const gridTheme = themeConfig.computedThemeType('grid', props)

const gridColumnSpan = computed(() => {
  return props.isFreezed && props.isLastColumnLevel
    ? props.state.columns.visibleFreezed.length - props.header.index
    : !props.isFreezed && props.isFirstColumnLevel && props.state.columns.visibleFreezed.length >= props.header.index
    ? props.header.columnCount - (props.state.columns.visibleFreezed.length - props.header.index)
    : props.header.columnCount
})

const gridRowSpan = computed(() => {
  return props.header.visible.length ? 1 : props.state.columns.depth - props.level
})

const resizer = useMove({
  onMove: onResize,
})
let initialWidth = 0
let resizeHeader: IGridHeader | undefined

function onResizeStart(e: PointerEvent) {
  resizeHeader = props.header.findRightmostVisible()
  initialWidth = resizeHeader?.width ?? 0
  resizer.moveStart(e)
}

function onResize({ deltaX }: IMoveEvent) {
  resizeHeader?.setWidth(initialWidth + deltaX)
}

function addAllToFreezed() {
  const righmostHeader = props.header.findRightmostVisible()
  if (!righmostHeader) return
  props.state.columns.setFreezedCount(righmostHeader?.index + 1)
}

function removeAllFromFreezed() {
  props.state.columns.setFreezedCount(props.header.index)
}

// function addToFreezed() {
//   props.state.columns.headers.move(props.header.indexPath.at(-1)!, props.state.columns.freezedCount)
//   props.state.columns.setFreezedCount(props.state.columns.freezedCount + 1)
// }

// function removeFromFreezed() {
//   props.state.columns.headers.move(props.header.indexPath.at(-1)!, props.state.columns.freezedCount - 1)
//   props.state.columns.setFreezedCount(props.state.columns.freezedCount - 1)
// }

function cycle(label: string) {
  props.state.selection.select()
  props.state?.columns.sortBy.cycle(label)
  moveSortedToFirst(label)
}

function toggle(label: string, order: 1 | -1 | undefined) {
  props.state?.columns.sortBy.toggle(label, order)
  moveSortedToFirst(label)
}

function moveSortedToFirst(label: string) {
  const from = props.state?.columns.sortBy.list.findIndex(item => item.label === label)
  if (from !== undefined && from !== -1) {
    props.state?.columns.sortBy.move(from, 0)
  }
}

function handleAllSelect() {
  if (props.state.selection.items.isAll()) {
    props.state.selection.items.deselectAll()
  } else {
    props.state.selection.items.selectAll()
  }
}
</script>
