<script setup>
import { onMounted } from "vue"
import { gaugeFiller } from "../composables/gaugeFiller.js"
const { loadLiquidFillGauge, liquidFillGaugeDefaultSettings } = gaugeFiller()

const props = defineProps({
  data: [Object, Array, Number],
  options: {
    type: [Object, Array],
    default: {},
  },
})

var config_info = liquidFillGaugeDefaultSettings()
config_info.textColor = "#000000"
config_info.waveTextColor = "#000000"

const config_combined = {
  ...config_info,
  ...props.options.config,
}

onMounted(() => {
  var gauge = loadLiquidFillGauge(props.options.id, props.data.percent, config_combined)
})
</script>

<template>
  <div class="flex flex-row">
    <svg :id="options.id" class="w-[80px]"></svg>
    <div class="flex flex-col justify-center pl-2 w-auto">
      <div class="font-bold">{{ data.name }}</div>
      <div class="font-bold">{{ data.value }} <slot></slot></div>
    </div>
  </div>
</template>

<style></style>
