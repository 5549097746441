<style scoped>
h1 .search-filters { display: flex;flex: 1;margin-left: 10px;}
h1 .search-filters .autocomplete {min-width: 90%!important;}
:deep() .cell-download { max-width: 30px; }
:deep() .cell-download .resizer { display: none; }
:deep() .header .cell > * { justify-content: start; font-weight: bold; font-size: 14px;}
:deep() .cell-download > * { padding: 0!important; }
:deep() .cell-download img { min-width: auto; width: 30px }
:deep() .spreadsheet .group [type=checkbox] { width: 30px;margin-left: -6px; }
[type=search] { font: var(--p1); }

:deep() .toolbar-break { display: none }
.app-client-edition .screen-documents .block { padding: 16px }

</style>

<template lang="pug">
transition(@leave='leave')
  loader(v-if="isLoading")
h1 
  span {{ t[$root.screen.path] }}
  .search-filters
    autocomplete.right(
      :data="autocomplete_data" 
      :options="{ placeholder: t.search }" 
      :modelValue="activated_filters"
      @update:modelValue="autocomplete_input"
      @keyup.native="search = $event.target.value"
      @focusout.native="search = ''"
  )
block.expand
  .row.center.left
    button(@click="dl(selected)" v-if="selected.length") Download selected ({{ selected.length }})
  spreadsheet(v-if="filtered_files.length" :data="filtered_files" :options="{ regroup: ['empty'], level: 10, filter: searchFn, sort: ['-p1'], group: Array(fs.map(p => p.split('/').length).max()).fill().map((_, i) => 'p' + i), columns: ['check', 'download', 'filename'].concat(document_info).flat().unique(), editable: false }")
    template(v-slot:header-check)
      input(type="checkbox" :checked="selected.length === fs.length" :indeterminate.prop="selected.length > 0 && selected.length !== fs.length" @click.stop="selected = selected.length === fs.length ? [] : files")
    template(v-slot:cell-check="s")
      input(type="checkbox" :checked="checkSelection(s.line, selected)" @click.stop="selected = selected.map().toggle(s.line)" @mousedown.prevent.stop="")
    template(v-slot:header-download)
      img(style="pointer-events: all;cursor: pointer;" tt="Download All" @click="dl(Object.keys(fs).map(Number))" src="https://platform.100m.io/dist/impress/icon_pdf.png")
    template(v-slot:cell-download="s")
      img(style="pointer-events: all;cursor: pointer;" @click="dl([s.line])" src="https://platform.100m.io/dist/impress/icon_pdf.png")
    template(v-slot:group="{ line }")
      input(type="checkbox" :checked="line._group.every(v => checkSelection(v, selected))" @mousedown.prevent.stop="" :indeterminate.prop="line._group.some(v => selected.includes(v)) > 0 && !line._group.every(v => selected.includes(v))" @click.stop="selected = line._group.every(v => selected.includes(v)) ? selected.filter(v => !line._group.includes(v)) : selected.concat(line._group.filter(v => !selected.includes(v)))")
      .key {{ t[line._key] || line._key }}
      .length {{ line._length }}
</template>

<script>
import JSZip from 'jszip'
export const additions = {"icon":"ic_folder"}
import config from '../../../config'
import {useImpressions} from "../composables/useImpressions";
import {useProgress} from "../composables/useProgress";
import {computed} from "vue";
import { useRuns } from '../composables/useRuns'
import { useShares } from '../composables/useShares'
export default {
  setup() {
    const {impressions, loaded, initialized} = useImpressions()
    const {runs, loaded: rLoaded} = useRuns()
    const {progress} = useProgress([loaded, rLoaded])
    const isLoading = computed(() => progress.value !== 1)

    return {impressions, isLoading, runs}
  },
  data() {
    return {
      selected: [],
      search: '',
      searchFn: x => !this.search || x.alias.includes(this.search),
    }
  },
  computed: {
    files() {
      return this.impressions
          .group('alias')
          .map(v => v.last())
          .v()
          .map(v => v.alias.split('/').reduce((acc, p, i, ps) => (acc[i === ps.length - 1 ? 'filename' : 'p' + i] = p, acc), v))
          .map(el => {
            const isin = el.filename.split('-')[1]
            const responsable = this.runs[el.run_id]?.context?.responsable || 'N/A'
            const language = this.runs[el.run_id]?.context?.language || 'N/A'
            const period = this.runs[el.run_id]?.context?.period || 'N/A'
            return ({ ...el, fund_name: el.filename.split('-')[0], isin, responsable, language, period })
          })
    },
    activated_filters() {
      let query_params = this.$route.query
      let active_filters = query_params.map(filter_value => filter_value.split('|')) 
      const filters = []
      Object.entries(active_filters).forEach(([filter_name,filter_values]) => {
        if (filter_name === 'year') return
        filter_values.forEach(value => {
          filters.push(this.format_filter_data(filter_name,value))
        }) 
      })
      return filters
    },
    fs() {
      return (this.impressions || {}).v().map('alias').unique()
    },
    autocomplete_data() {
      const filter_criterias= this.document_info
      let data = this.files.reduce((grouped_filters, file) => {
        filter_criterias
        .forEach(criteria => { 
          if(typeof file[criteria] === 'string') {
            let filter_value = file[criteria].split('.')[0]
            grouped_filters[criteria] = grouped_filters[criteria] || {}
            grouped_filters[criteria][filter_value] = filter_value
          }
        })
        return grouped_filters
      }, {})
      return data
    },
    filtered_files() {
      let query_params = this.$route.query
      if(query_params.keys().length) {
        const filters = Object.entries(query_params).filter(([k, v]) => !['search', 'selected', 'scheduled', 'year'].includes(k)).map(([k, v]) => [k, v.split('|')])
        return this.files.filter(d =>
            filters.every(([k, vs]) =>
              vs.some(v =>
                (d[k] && d[k].replace('.','') == v) ||
                (d[k] && d[k].includes(v)) ||
                (/^>/.test(v) && d[k] > v.slice(1)) ||
                (/^</.test(v) && d[k] < v.slice(1))
            )
          ))
      } else {
        return this.files
      }
    }
  },
  methods: {
    checkSelection(line, selection) {
      var i;
      for (i = 0; i < selection.length; i++) {
          if (JSON.stringify(selection[i]) === JSON.stringify(line)) {
              return true
          }
      }
      return false
    },
    async dl(selected) {
      if (!selected.length) return
      const urls = selected.map(el => `${config.commandr}/${el.alias}`)
      let blob
      let filename
      if (selected.length === 1) {
        blob = await (await fetch(urls[0])).blob()
        filename = urls[0].split('/').last()
      } else {
        Promise.map = async (arr, fn) => await arr.reduce(async (acc, v, i) => ((await acc).push(await fn(v, i)), acc), Promise.resolve([]))
        const zip = new JSZip()
        if (urls.unique().length !== urls.length) await Promise.map(urls, async url => zip.file(url.replace(/\//g, '_'), await (await fetch(url)).blob()))
        else await Promise.map(urls, async url => zip.file(url.split('/').last(), await (await fetch(url)).blob()))
        blob = await zip.generateAsync({ type:"blob" })
        filename = `docs-${new Date().format()}.zip`
      }
      const link = document.createElement('a')
      link.setAttribute('href', URL.createObjectURL(blob))
      link.setAttribute('style', 'display: none;')
      link.setAttribute('download', filename)
      document.body.appendChild(link) // Required for FF
      link.click()
    },
    autocomplete_input(event) {
      let event_array = event
        .map(v => v.split('.'))
      let grouped_events = event_array
        .group('0')
      let selected_filters =  grouped_events.map(g => g.map('1').join('|'))
      let new_filter_selected = this.$route.query
        .filter((v, k) => !this.$route.query.keys().includes(k))
      let query = {...selected_filters,...new_filter_selected}
      this.$router.push({query})
    },
    format_filter_data(name,value) {
      return name + '.' + value
    }
  },
}
</script>
