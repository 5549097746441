import { isEmpty } from 'lodash'

export const NX_SEPARATOR = '#/NX/#'

export const aggregationFunctions = {
  sum: (arr, k) =>
    arr.length
      ? +arr
          .map(v => +v?.[k] || 0)
          .sum()
          .toFixed(2)
      : '',
  avg: (arr, k) =>
    arr.length
      ? +arr
          .map(v => +v?.[k] || 0)
          .mean()
          .toFixed(2)
      : '',
  min: (arr, k) =>
    arr.length
      ? +arr
          .map(v => +v?.[k] || 0)
          .min()
          .toFixed(2)
      : '',
  max: (arr, k) =>
    arr.length
      ? +arr
          .map(v => +v?.[k] || 0)
          .max()
          .toFixed(2)
      : '',
  count: (arr, k) => arr.length,
  unique: (arr, k) => arr.map(k).unique().length,
  list: (arr, k) => arr.map(k).unique().sort().join(', '),
}

aggregationFunctions.values = aggregationFunctions.list
aggregationFunctions.maximum = aggregationFunctions.max
aggregationFunctions.minimum = aggregationFunctions.min

export function preparePivot(data, options = {}, agg = aggregationFunctions) {
  const { rows = [], columns = [], aggregates = [], withPercentage = false, withTotal = false } = options.pivot || {}
  const metadata = { tree: undefined }
  const [fname, cname] = aggregates[0] instanceof Array ? aggregates[0] : aggregates
  const reducer = data => agg[fname](data, cname)
  const sorter = data => {
    const sortFns = (options.columns?.sortBy?.list || []).map(
      sort => (a, b) =>
        default_sort(
          columns.concat(sort.label).reduce((v, p) => v?.[p], a),
          columns.concat(sort.label).reduce((v, p) => v?.[p], b),
        ) * sort.order,
    )
    return data.sort(sortFns.concat('id'))
  }
  if (!fname || !agg[fname]) return { data: [{ id: '-', '': '' }], metadata }
  if (!rows.length)
    return { data: [{ id: '-', [columns.join(' / ') || ' ']: group(data, columns, reducer) }], metadata }
  if (rows.length === 1) return { data: groupLevel(1), metadata }
  const groups = rows.map((_, i) => groupLevel(i + 1))
  return {
    data: groups.flat(),
    metadata: {
      tree: {
        type: 'node',
        id: '-1',
        is_virtual: true,
        children: groupFlat(0),
      },
    },
  }

  function default_sort(a, b) {
    if (typeof a !== typeof b) return typeof a > typeof b ? 1 : -1
    if (['object', 'function', 'undefined'].includes(typeof a)) return 0
    return a === b ? 0 : a > b ? 1 : -1
  }
  function flatten(obj) {
    if (obj.constructor === Array) return obj
    if (obj.constructor === Object) return Object.values(obj).flatMap(v => flatten(v))
  }
  function traverse(obj, reducer) {
    if (obj.constructor === Array) return reducer(obj)
    if (obj.constructor === Object)
      return { ...Object.map(obj, v => traverse(v, reducer)), default: reducer(flatten(obj)) }
  }
  function group(arr, fns, reducer) {
    if (!fns || !fns.length) return reducer(arr)
    fns = fns.map(fn => (fn.constructor === Function ? fn : v => v[fn]))
    const lastKeys = (reducer ? data : arr).map(fns.at(-1)).sort().unique()
    const otherKeys =
      fns.length > 1
        ? fns.slice(0, fns.length - 1).map(fn =>
            data
              .map(v => fn(v))
              .sort()
              .unique(),
          )
        : []

    let result = arr.reduce((acc, v) => {
      fns.reduce((acc, fn, i) => {
        const key = fn(v)
        const last = i === fns.length - 1
        if (last) {
          if (Object.keys(acc).length === 0) lastKeys.map(k => (acc[k] = acc[k] || []))
          return (acc[key] = acc[key].concat([v]))
        }
        const hasKey = Object.prototype.hasOwnProperty.call(acc, key)
        return (acc[key] = hasKey ? acc[key] : {})
      }, acc)
      return acc
    }, {})
    if (otherKeys.length) {
      otherKeys[0].forEach(key => {
        if (!result[key]) {
          result[key] = fillEmptyData(otherKeys.slice(1, otherKeys.length), lastKeys)
        } else {
          result[key] = fillEmptyData(otherKeys.slice(1, otherKeys.length), lastKeys, result[key])
        }
      })
    }
    if (!reducer) return result
    return traverse(result, reducer)
  }

  function fillEmptyData(keysList, lastKeys, data = {}) {
    if (!keysList.length) {
      return lastKeys.reduce((acc, lastKey) => {
        if (!acc[lastKey]) acc[lastKey] = ''
        return acc
      }, data)
    }
    return keysList[0].reduce((acc, key) => {
      if (!acc[key]) {
        acc[key] = fillEmptyData(keysList.slice(1, keysList.length), lastKeys)
      } else {
        acc[key] = fillEmptyData(keysList.slice(1, keysList.length), lastKeys, acc[key])
      }
      return acc
    }, data)
  }

  function groupLevel(depth) {
    const result = Object.entries(
      group(data, [
        v =>
          rows
            .slice(0, depth)
            .map(k => v[k])
            .join(NX_SEPARATOR),
      ]),
    ).map(([k, v]) => ({
      id: k === '' ? ' ' : k,
      [rows.join(' / ')]: k.split(NX_SEPARATOR)[depth - 1],
      [columns.join(' / ') || ' ']: group(v, columns, reducer),
    }))
    return sorter(result)
  }
  function groupFlat(depth, memo = {}) {
    if (depth === rows.length - 1)
      return groups[depth].map(v => ({
        id: v.id,
        type: 'row',
      }))
    return (memo[depth] =
      memo[depth] ||
      groups[depth].map(v => ({
        id: v.id,
        type: 'node',
        children: groupFlat(depth + 1, memo).filter(c => c.id.startsWith(v.id)),
      })))
  }
}

export function formatData(rows, columns) {
  return rows.map((row, rowIndex) => {
    return row.cells.reduce(
      (acc, cell, index) => {
        if ((cell.value || isEmpty(cell.value)) && columns[index]) {
          acc[columns[index].options.label] = cell.value
        }
        return acc
      },
      { id: row?.row_id || rowIndex },
    )
  })
}
