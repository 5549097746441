<script setup>
import { ref } from "vue"
import active_balance_data from "../../data/active-balance.json"
import passive_balance_data from "../../data/passive-balance.json"
import NewTable from "../../components/new-table.vue"

const Active_balance_data = ref(
  active_balance_data
    .filter((d) => d.portfolioCode === $root.params.userflow && d.parentLabel != 0)
    .__.map((d) => ({
      Label: d.label,
      Level: d.level,
      RowLabel: d.rowLabel,
      Investment: d.valorizationSum,
      ABS: Math.abs(d.valorizationSum),
      Performance: d.performance / 100,
      ParentLabel: d.parentLabel,
    }))
    .group((d) => d.ParentLabel)
)

const Passive_balance_data = ref(
  passive_balance_data
    .filter((d) => d.portfolioCode === $root.params.userflow && d.parentLabel != 0)
    .__.map((d) => ({
      Label: d.label,
      Level: d.level,
      RowLabel: d.rowLabel,
      Investment: d.priceCostSum,
      ABS: Math.abs(d.priceCostSum),
      ParentLabel: d.parentLabel,
    }))
    .group((d) => d.ParentLabel)
)

const data = Active_balance_data.value["1"].sort("-Investment")

const options = {
  labelKey: "RowLabel",
  datasets: [
    {
      // label:'Categories',
      key: "ABS",
      backgroundColor: "#0ed8b8",
      labels: {
        display: true,
        align: "center",
        position: "center",
        formatter(ctx) {
          if (ctx.type !== "data") {
            return
          }
          return data.find({ ABS: ctx.raw.v }).RowLabel
        },
        font: {
          size: 16,
          weight: "bold",
        },
      },
    },
  ],
}
const table_options = {
  primary_columns: ["RowLabel", "Investment", "Performance"],
  sort_column: "Performance",
  table_size: 10,
  format: {
    Investment: ".2fM€",
    Performance: ".2%",
  },
  colored_text: "Performance",
}

const real_values = (data, label, value) => {
  const cell = data.find((obj) => obj.RowLabel == label)
  return cell["Investment"] > 0 ? format(".2fM€")(value) : format(".2fM€")(-value)
}
const formatRows = (rows) => {
  return rows.map((r) => {
    return {
      ...r,
      label: r.label,
      value: real_values(data, r.label, r.value),
    }
  })
}

const max = data.map((v) => v.ABS).max()
const chart_size = (val) => [Math.round((val * 100) / max), 100].min()
</script>
<script>
export const additions = { icon: "ic_pie_chart" }
</script>

<template>
  <div>
    <div class="flex flex-col items-center">
      <h1 class="font-bold mb-3 text-3xl">{{ $root.params.userflow }} : {{ t.balance }}</h1>
      <h1 class="font-bold mb-2 text-2xl">Investment</h1>
      <cjs-treemap-chart class="w-[80vw]" :data="data" :options="options">
        <template v-slot:tooltip="{ tooltip }">
          <table-tooltip v-if="tooltip.shown" :rows="formatRows(tooltip.rows)"></table-tooltip>
        </template>
      </cjs-treemap-chart>
    </div>
    <div class="flex justify-center pt-12">
      <NewTable class="w-[80vw]" :data="data" :options="table_options">
        <template #table="{ row, column_group }">
          <div
            v-if="column_group == 'ABS'"
            class="bg-[#0ed8b8] h-6 rounded-md w-8"
            :style="{ width: chart_size(row[column_group]) + '%' }"
          ></div>
          <div v-else>{{ row[column_group] }}</div>
        </template>
      </NewTable>
    </div>
  </div>
</template>
