import firebase from 'firebase/app'
import 'firebase/database'
import { ref, getCurrentInstance, onUnmounted, onMounted} from 'vue'
import { fetchAsof, getAsof } from './asof'
import config from '../config'
let database, rootRef
export let dbRef = null

export function initFirebase({apiKey, name, project, root}) {
  if (!root && !name && !project) return
  console.log('initFirebase', root, name, project)
  if (!firebase.apps.length) {
    firebase.initializeApp({
      apiKey: apiKey,
      authDomain: `${name}.firebaseapp.com`,
      databaseURL: `https://${name}.firebaseio.com`,
    })
  }
  if (!firebase.database) return console.log('ERROR: firebase.database is not defined')
  database = firebase.database()
  rootRef = database.ref(root)
  dbRef = rootRef.child('public')
  return true
}

export const useRTDB = () => {
  if (!config.firebase || !config.firebase.apiKey || !config.firebase.name) return

  const asof = getAsof()
  let asofDb = {}

  const data = ref({a:1})
  let remote = false
  const onupdate = snapshot => {
    data.value = { ...snapshot.val(), ...asofDb }
    remote = true
  }

  onMounted(async () => {
    if (asof) {
      asofDb = await fetchAsof(asof)
    }
    dbRef.once('value', onupdate)
  })
  if (getCurrentInstance()) onUnmounted(() => dbRef.off('value', onupdate))
  return {
    db: data,
  }
}
export async function get2(path) {
  const ref = dbRef.child(path.split('.').join('/'))
  const snap = await ref.get()
  return snap.val()
}

export function get(path) {
  console.log('get', path)
  return path.split('.').__.reduce((acc, p) => acc?.[p], $root.db)
}
export function set(path, value) {
  console.log('set', path, value)
  return path
    .split('.')
    .__.reduce((acc, p) => (!p ? acc : acc.child(p)), dbRef)
    .set(value)
}
export function update(path, value) {
  console.log('update', path, value)
  return path
    .split('.')
    .__.reduce((acc, p) => (!p ? acc : acc.child(p)), dbRef)
    .update(value)
}
