import { useRoute } from 'vue-router'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { dbRef } from '../../../features/firebase'

export function useWorkflow() {
  const route = useRoute()
  const id = ref(+route.query.id)
  const actionId = ref(+route.query.action_id)

  let workflowRef = dbRef.child(`/data/workflows/${id.value}`)

  const loaded = ref(false)
  const workflow = ref({ name: 'Workflow', actions: { 1: { id: 1, name: 'Step', step: 'Group', type: '', duration: 3 } }, id: id.value })

  const onUpdate = snap => {
    workflow.value = snap.val()
    loaded.value = true
  }

  watch(
    () => route.query.id,
    () => {
      workflowRef.off('value', onUpdate)
      id.value = +route.query.id
      workflowRef = dbRef.child(`/data/workflows/${id.value}`)
      workflowRef.on('value', onUpdate)
    },
  )
  watch(
    () => route.query.action_id,
    () => (actionId.value = +route.query.action_id),
  )

  onMounted(() => workflowRef.on('value', onUpdate))
  onUnmounted(() => workflowRef.off('value', onUpdate))

  const action = computed(() => workflow.value?.actions[actionId.value] || {})
  return {
    workflow,
    id,
    action,
    actionId,
    loaded,
  }
}
