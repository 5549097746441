<script setup>
import { ref, onMounted, computed, watch, onUnmounted } from 'vue'
import { useContribution } from '../../composables/useContribution.js'
import { useAutocomplete } from '../../composables/useAutocomplete.js'
import { useXfilter } from '../../composables/useXfilter.js'
import { useDates } from '../../composables/useDates';

const { getContributionData } = useContribution()
const { initAutocomplete, autocompleteAvailableData, activeAutocompleteFilters, onFiltersUpdateAutoComplete } = useAutocomplete()
const { initXfilter, filteredDataset, datasetByDimensions, axis, axisFormat, axisMetric } = useXfilter()
const { getDatesQuery } = useDates();
const loaded = ref(false)
const availableDimensions = ref()
const asof = ref()
const fundAssetClass = ref()

const leave = (el, done) => {
  el.style.opacity = 0
  setTimeout(() => done(), 500)
}

const loadData = async () => {
  loaded.value = false
  const domain = $root.$route.query.domain || $root.domain.join('|')
  const params = {
    isinShare: $root.$route.fullPath.split('/')[2].split('-')[1],
    domain,
    asof: $root.domain[1],
    lang: $root.$route.query.lang || $root.lang,
  }
  if (!$root.dates || $root.dates.isin_share !== params.isinShare || $root.dates.query !== 'contribution' ) {
    $root.dates = await getDatesQuery(params, 'contribution')
    $root.dates.isin_share = params.isinShare
    $root.dates.query = 'contribution'
    $root.refresh = $root.refresh + 1
    params.domain = $root.domain.join('|')
    params.asof = $root.domain[1]
  }

  const data = await getContributionData(params)

  fundAssetClass.value = data.share.characteristics?.fund_asset_class || ''
  $root.tab_benchmark = data.share.benchmark
  $root.tab_userflow_name = data.share.slug
  $root.refresh = $root.refresh + 1
  asof.value = data.share.asof
  availableDimensions.value = data.share.axis_of_analysis
  initAutocomplete(data.share.contribution, availableDimensions.value)
  initXfilter(data.share.contribution, availableDimensions.value, 'contribution')


  loaded.value = true
}

onMounted(loadData)
watch(() => $root.query.domain, loadData)
watch(() => $root.query.evolution, loadData)
// onUnmounted(()=>$root.bench_weight_nul = false)

const axisList = computed(() => {
  if (fundAssetClass.value === 'fundAssetClass_actions') return ['exposure', 'contrib_deltaaction']
  if (fundAssetClass.value === 'fundAssetClass_obligations' || fundAssetClass.value === 'fundAssetClass_monetaire') return ['exposure', 'contrib_sensi']
  if (fundAssetClass.value === 'fundAssetClass_diversifies' || fundAssetClass.value === 'fundAssetClass_convertibles') return ['exposure', 'contrib_sensi', 'contrib_deltaaction']
  return ['exposure', 'contrib_sensi', 'contrib_deltaaction']
})

const contributionAxis = computed(() => {
  return $root.query && $root.query.evolution ? 'contribution_evolution' : 'contribution'
})

const contributorsKpi = computed(() => (category /* best | worst */) => {
  const filterFn = {
    best: (d) => d[axisMetric.value] > 0,
    worst: (d) => d[axisMetric.value] < 0
  }[category]
  const result = filteredDataset.value
    // .filter(d => d.fuid !== 'UNEXPLAINED')
    // .filter(d => d.asset_class !== 'fees')
    .filter(filterFn)
    .sort((a, b)=> b[axisMetric.value] - a[axisMetric.value])
    .map((d) => ({ title: d.name, value: format('+.2%')(d[axisMetric.value]) }))
  return category === 'best' ?  result : result.reverse()
})

const metadataContribution = dim => {
  const format = axisFormat.value
  const max = 6 // Warning Old code (display at leat 80% removed)
  const dimension = dim // REQUIRE for inactive bar in plot-bar.vue
  const sort = $root.config.sorting(dim)
  const suppressEmptyBars = true
  return {format, max, dimension, sort, suppressEmptyBars}
}

const updateFilter = (dimension, event) => {
  const value = event.x === 'other' ? event.label : event.x
  // Unselect case:
  if ($root.query?.[dimension] && $root.query?.[dimension].split('|').includes(value)) {
    const filters = $root.query?.[dimension].split('|').filter(d => d !== value)
    return update_query({ [dimension]: filters.join('|') })
  }
  // Add criteria on existing dimension
  if ($root.query?.[dimension]) {
    const filters = $root.query?.[dimension].split('|').concat(value)
    return update_query({ [dimension]: filters.join('|') })
  }
  // new filter
  update_query({ [dimension]: value })
}

const performance = (benchmark = false) => {
  if (!filteredDataset.value) return 0
  const result = filteredDataset.value
    .reduce(
      (acc, cur) => acc + (cur[`contribution${benchmark ? '_benchmark' : '_fund'}`] || 0),
      0
    )
  return result
}
</script>

<script>
export const additions = { calendar: 'period', icon: 'ic_search' }
</script>

<template lang="pug">
transition(@leave='leave')
  loader(v-if="!loaded")
template(v-if="loaded")
  .screen-title
    .row
      h1 {{ t.contribution }}
      .row.right
        autocomplete(:data="autocompleteAvailableData" :options="{ placeholder: t.search }" :modelValue="activeAutocompleteFilters" @update:modelValue="onFiltersUpdateAutoComplete")
    .button-bar
    //  .evolution
    //    router-link(:class="{ on: !$route.query.evolution }" :to="{ query: Object.assign({}, $route.query, { evolution: null }).filter(d => d) }") {{ t[$root.screen.path] || $root.screen.path }}
    //    router-link(:class="{ on: $route.query.evolution }" :to="{ query: Object.assign({}, $route.query, { evolution: 'on' }) }") {{ t.evolution }}
      .axis
        router-link(:class="{ on: !$root.query.axis }" :to="{ query: Object.assign({}, $root.query, { axis: null }).filter(d => d) }") {{ t.weight }}
        router-link(:class="[{ on: $root.query.axis === axis }, axis]" :to="{ query: Object.assign({}, $root.query, { axis: axis }) }" v-for="axis in axisList" ) {{ t[axis] || axis }}

  .row.stretch(v-if="$root.domain.length")
    kpi.performance(:data="[{ title: 'performance', value: performance(false) }, { title: 'performance_benchmark', value: performance(true) }]")
    kpi(:data="[{ title: t['top_contributors'] }].concat(contributorsKpi('best'))")
    kpi(:data="[{ title: t['worst_contributors'] }].concat(contributorsKpi('worst'))")

  .block.column.expand(v-if="availableDimensions")
    .row.expand(v-for="row in availableDimensions.chunk(2)")
      block.allow-fullscreen(v-for="dimension in row" type="plot-bar" :data="datasetByDimensions(dimension)" :title="contributionAxis + ',by,' + dimension" :metadata="metadataContribution(dimension)" @plotclick="updateFilter(dimension, $event)")

  .asof(v-if="asof")
    p Mapping asof: {{asof}}
</template>

<style>
.on {
  opacity: 1;
}
.page > .asof {
  margin-top: 0;
  margin-bottom: 0;
}
.asof p {
  font-size: 9px;
  text-align: right;
}
.row.right {
  flex: 1;
}
.screen-contribution .block-plot-bar {
  min-width: 370px;
}
.button-bar{
  min-width: 624px;
}
/* .screen-contribution.no_benchmark_data .tab, */
.screen-contribution.no_benchmark_data .kpi .weight_benchmark,
.screen-contribution .kpi.no_benchmark_data .title:nth-of-type(2),
.screen-contribution .kpi.no_benchmark_data .value:nth-of-type(2)
{
  display: none;
}
</style>
