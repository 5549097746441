import { useTheme, extend, gridZIndex, ITheme, IGridNode } from '@hauru/common'

export const visageTheme: Partial<ITheme> = {
  grid: {
    basic: {
      container: 'select-none',
      header: {
        container: 'bg-white',
        checkbox: {
          $extends: '../selected/checkbox',
          idle: extend('group-hover:opacity-100 my-auto mr-2'),
        },
        column: {
          container: 'cursor-pointer select-none !overflow-visible truncate',
          idle: 'font-semibold pl-2 pr-1 py-1 border-gray-200 bg-gray-50 hover:bg-gray-100 transition-colors',
          selected: '!bg-gray-100',
          sorted: 'pr-4',
          content: 'truncate',
          sortOrder:
            'text-xs text-gray-500 h-5 items-center absolute right-[25px] flex p-[2px] pr-1 rounded-full transition-colors',
        },
        dropdown: {
          container: 'max-h-80 overflow-hidden',
          content: 'gap-2 py-1',
        },
      },
      actions: {
        find: {
          placeholder: 'Find in view',
          idle: 'h-[28px] w-[28px] min-w-[100px] focus:w-40',
          active: '!w-40',
        },
      },
      freezed: {
        initial: 'w-px bg-gray-400/80',
        scrolled: 'shadow-[4px_0_theme(colors.gray.500/15%)]',
      },
      cell: {
        container: [
          'flex h-full flex-shrink-0 flex-grow-0 items-center [contain:strict] bg-inherit relative',
          gridZIndex.content,
          _props => {
            if (_props.state.display === 'transpose' && _props.header.label === 'k') return '!bg-gray-50 font-medium'
            if (_props.state.metadata?.matches?.[_props.view.node.id]?.[_props.cell]) return 'bg-yellow-corn'
            if (!_props.state.columns.groupBy.list.length) return
            if (_props.header.index === 0 && _props.view.node.type === 'node') return 'pl-6'
            if (_props.header.index < _props.state.freezedColumnsCount && _props.view.node.type === 'node')
              return '!flex-shrink !p-0'
          },
          _props => {
            if (_props.header.index === 0 && _props.view.node.type === 'node') return
            return '[contain:strict]'
          },
          _props => [
            _props.view.node.type === 'node'
              ? _props.state.display === 'pivot'
                ? ''
                : 'first:pl-2'
              : 'border-r border-solid border-r-gray-200 last-of-type:border-r-0',
          ],
        ],
        content: 'flex items-center truncate px-2',
      },
      node: {
        container: [
          _props => {
            const level = _props.view.node.level
            // return (level % 2 === 0) ? 'bg-[#f7fcfc]' : 'bg-[#f2f7f6]'
            return level % 2 === 0 ? 'bg-light-50' : 'bg-light-100'
          },
          _props => {
            return _props.view.node.level === 0 || (_props.view.node.level === 1 && _props.view.node.parent?.is_virtual)
              ? ''
              : 'rounded'
          },
          'font-medium group/node',
          // 'border-solid border border-[#e3e3e3]',
          // 'border-solid border border-light-200'
        ],
        buttons: {
          fader: '-mr-1 h-full w-1 shrink-0 grow-0 bg-gradient-to-r',
          checkbox: {
            $extends: '../../selected/checkbox',
            idle: extend('group-hover/node:opacity-100'),
          },
        },
        nodeHeaderHeight: 32,
        rowGap: 0,
        nodeOffset: [
          32,
          0,
          (node: IGridNode) => ((node.rows?.length ?? 0) > 0 ? 0 : 6),
          (node: IGridNode) => ((node.rows?.length ?? 0) > 0 ? 0 : 12),
        ],
        containerOffset: [0, 0, 0, 0],
      },
      row: {
        rowLeftOffset: ({ state }) => (state.display === 'pivot' ? 0 : 22),
        container: [
          'border-solid border border-gray-200 text-gray-900 border-b-0 hover:bg-light-100 group/row',
          _props => {
            return _props.view.rowIndex! % 2 === 0 ? 'bg-white' : 'bg-gray-50'
          },
          _props => [
            _props.view.rowIndex === (_props.view.node.parent?.rows?.length ?? 0) - 1 ? '!border-b' : '',
            _props.view.rowIndex === 0 && _props.view.node.parent?.level === 0 && _props.state.display !== 'transpose'
              ? '!border-t-0'
              : '',
          ],
        ],
        buttons: {
          index:
            'text-gray-500 text-xs font-medium flex-shrink-0 flex-grow-0 ml-2 group-hover/row:opacity-0 opacity-100 min-w-[18px]',
          checkbox: {
            $extends: '../../selected/checkbox',
            idle: extend('absolute left-1 group-hover/row:opacity-100'),
          },
        },
      },
      resizer: {
        container: [
          'group/resizer absolute flex content-center w-[9px] top-0 bottom-0 -right-[3px] translate-x-[1px] cursor-ew-resize',
          gridZIndex.resizer,
        ],
        content: 'group-hover/resizer:opacity-100 opacity-0 bg-blue-600 w-[3px] mt-1 mb-[3px] m-auto rounded-full',
      },
      selected: {
        container: {
          widthOffset: 1,
          heightOffset: 1,
          cell: 'border-2 border-solid border-indigo-700 -mt-px',
        },
        cell: '',
        range: '!bg-blue-100',
        row: '!bg-blue-50 !border-indigo-200',
        node: '!bg-blue-50 border border-solid !border-indigo-200',
        handle:
          'absolute -bottom-[6px] -right-[6px] cursor-crosshair border border-solid border-white w-[10px] h-[10px] bg-blue-700 rounded-full',
        checkbox: {
          idle: 'opacity-0 vsg-checkbox vsg-unset w-4 h-4 text-blue-600 bg-white border border-solid border-blue-300 rounded shrink-0',
          anyChecked: '!opacity-100',
        },
      },
      plot: {
        container: 'flex-1 bg-white p-4',
      },
      labels: {
        hideColumns: 'Hide columns',
        hiddenColumn: 'Hidden column',
        hiddenColumns: 'Hidden columns',
        group: 'Group',
        groups: 'Groups',
        sort: 'Sort',
        sorts: 'Sorts',
        columnSortAscending: 'Add to sorted columns A → Z',
        columnSortDescending: 'Add to sorted columns Z → A',
        columnGroup: 'Group by this column',
        columnUngroup: "Don't group by this column",
        columnFreeze: 'Freeze this column',
        columnUnfreeze: 'Unfreeze this column',
        columnFreezeUpTo: 'Freeze up to this column',
        columnUnfreezeUpTo: 'Unfreeze up to this column',
        columnHide: 'Hide column',
      },
      icons: {
        sortAscending: {
          i: 'mdi-sort-descending',
          class: 'h-4 w-5 text-gray-500',
        },
        sortDescending: {
          i: 'mdi-sort-ascending',
          class: 'h-4 w-5 text-gray-500',
        },
        lineDensitySmall: {
          i: 'ic-round-density-small',
          class: 'h-4 w-5',
        },
        lineDensityLarge: {
          i: 'ic-round-density-medium',
          class: 'h-4 w-5',
        },
        textSmall: {
          i: 'fe-text-size',
          class: 'h-3 w-5',
        },
        textMedium: {
          i: 'fe-text-size',
          class: 'h-4 w-5',
        },
        textLarge: {
          i: 'fe-text-size',
          class: 'h-5 w-5',
        },
        columnSortAscending: {
          i: 'mdi-sort-descending',
          class: 'h-5 w-5',
        },
        columnSortDescending: {
          i: 'mdi-sort-ascending',
          class: 'h-5 w-5',
        },
        columnExpand: {
          i: 'ic-baseline-add',
          class: 'h-5 w-5',
        },
        columnCollapse: {
          i: 'ic-baseline-remove',
          class: 'h-5 w-5',
        },
        columnGroup: {
          i: 'mdi-list-box-outline',
          class: 'h-4 w-5',
        },
        columnUngroup: {
          i: 'mdi-list-box-outline',
          class: 'h-4 w-5',
        },
        columnFreeze: {
          i: 'mdi-table-star',
          class: 'h-4 w-5',
        },
        columnUnfreeze: {
          i: 'mdi-table-star',
          class: 'h-4 w-5',
        },
        columnFreezeUpTo: {
          i: 'mdi-table-star',
          class: 'h-4 w-5',
        },
        columnUnfreezeUpTo: {
          i: 'mdi-table-star',
          class: 'h-4 w-5',
        },
        columnHide: {
          i: 'mdi-eye-off-outline',
          class: 'h-4 w-5',
        },
      },
    },
  },
  dropdown: {
    grid: {
      basic: {
        theme: 'dropdown',
        placement: 'bottom-start',
        triggers: ['click'],
        delay: 0,
        handleResize: true,
        autoHide: true,
        popperClass:
          'h-fit divide-y divide-gray-100 overflow-auto rounded-md bg-white shadow-lg ring-1 ring-black/5 overflow-hidden',
      },
      actions: {
        basic: {
          $extends: '../basic',
          distance: 3,
          skidding: -6,
          popperClass: extend('shrink-0 w-56'),
          contentClass: 'max-h-80',
          mainContentClass: 'shrink-1 flex grow-0 basis-auto flex-col overflow-auto py-1',
          subHeadingClass: 'text-xs text-gray-700',
        },
        density: {
          mainContentClass: extend('gap-2'),
          placement: 'bottom-end',
          skidding: 6,
        },
        pivot: {
          $extends: './basic',
        },
      },
      header: {
        $extends: './basic',
        distance: 0,
        skidding: 0,
        popperClass: extend('w-[264px]'),
        popperHideTriggers: triggers => [...triggers, 'click'],
        instantMove: true,
      },
    },
  },
  button: {
    grid: {
      actions: {
        basic: {
          size: 'md',
          idle: '!py-1 font-medium text-gray-700 border-2 border-solid border-white hover:bg-light-100 hover:border-light-100 truncate',
          active: 'bg-light-100 !border-light-100 hover:bg-light-100  hover:!border-light-200',
          selected: 'bg-light-200 hover:bg-light-200 !border-light-200 hover:!border-light-200',
        },
        hide: {
          $extends: './basic',
          icon: 'ic-round-visibility-off',
          iconClass: 'h-4 w-5 !transform-none',
          label: 'Hidden columns',
        },
        group: {
          $extends: './basic',
          icon: 'mdi-list-box-outline',
          iconClass: 'h-5 w-5 !transform-none',
          label: 'Group',
        },
        sort: {
          $extends: './basic',
          icon: 'mdi-sort',
          iconClass: 'h-4 w-5 !transform-none',
          label: 'Sort',
        },
        filter: {
          icon: 'ic-outline-filter-list',
          iconClass: 'h-[18px] w-5 !-scale-x-100',
        },
        pivot: {
          icon: 'ic-outline-pivot-table-chart',
          iconClass: 'h-4 w-5 !-scale-x-100',
        },
        exportXLS: {
          $extends: './basic',
          icon: 'mdi-microsoft-excel',
          iconClass: 'h-5 w-5',
        },
        addRow: {
          $extends: './basic',
          icon: 'ic-baseline-add',
          iconClass: 'h-5 w-5',
        },
        delete: {
          $extends: './basic',
          icon: 'carbon-trash-can',
          iconClass: 'h-4 w-5',
          height: 'h-2',
          width: 'w-2',
        },
        duplicate: {
          $extends: './basic',
          icon: 'mdi-content-duplicate',
          iconClass: 'h-4 w-5',
          height: 'h-2',
          width: 'w-2',
        },
        exportCSV: {
          $extends: './basic',
          icon: 'la-file-csv',
          iconClass: 'h-5 w-5',
        },
        transpose: {
          icon: 'mdi-table-pivot',
          iconClass: 'h-4 w-5 !-scale-x-100',
        },
        plot: {
          icon: 'ic-outline-bar-chart',
          iconClass: 'h-[18px] w-5 !-scale-x-100',
        },
        density: {
          icon: 'ic-baseline-format-line-spacing',
          iconClass: 'h-4 w-5 !-scale-x-100',
        },
        rows: {
          $extends: './basic',
          icon: 'mdi-table-row',
          iconClass: 'h-4 w-5',
        },
        columns: {
          $extends: './basic',
          icon: 'mdi-table-column',
          iconClass: 'h-4 w-5',
        },
        aggregates: {
          $extends: './basic',
          icon: 'mdi-function-variant',
          iconClass: 'h-4 w-5',
        },
        swap: {
          $extends: './basic',
          icon: 'ic-baseline-swap-horiz',
          iconClass: 'h-4 w-5',
        },
        hideDetails: {
          $extends: './basic',
          icon: 'feather-square',
          iconClass: 'h-4 w-5',
        },
        showDetails: {
          $extends: './basic',
          icon: 'feather-columns',
          iconClass: 'h-4 w-5',
        },
      },
      subactions: {
        basic: {
          size: 'sm',
          idle: 'grow !py-1 font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 active:bg-gray-300',
          disabled: 'cursor-default !bg-gray-50 !text-gray-400 hover:!bg-gray-50',
        },
        expandToLevel: {
          size: 'xs',
          idle: extend('!grow-0'),
        },
        emptyShowAll: {
          idle: extend('!grow-0'),
        },
        emptyClearSearch: {
          idle: extend('!grow-0'),
        },
      },
      header: {
        action: {
          size: 'xs',
          idle: 'ml-auto !py-1 z-10 transition-colors hidden font-medium text-gray-700 bg-gray-50 group-hover:bg-gray-100 group-hover:!flex group-hover:hover:bg-gray-200 group-hover:active:bg-gray-300 ring-white',
          selected: '!bg-gray-200 !flex group-hover:hover:ring-2',
          iconClass: 'h-4 w-5',
        },
        toggle: {
          size: 'xs',
          idle: '-ml-1 mr-1 !py-1 transition-colors font-medium text-gray-700 bg-gray-50 group-hover:bg-gray-100 group-hover:hover:bg-gray-200 group-hover:active:bg-gray-300',
          iconClass: 'h-4 w-5',
        },
      },
    },
  },
  switch: {
    basic: {
      slider: '!bg-gray-200',
    },
  },
  listItem: {
    basic: {
      idle: 'hover:bg-gray-50 active:bg-gray-100',
    },
    grid: {
      idle: 'h-8 text-gray-800 flex gap-2 items-center px-4 hover:bg-gray-50',
    },
  },
}

export function initTheme() {
  const themeConfig = useTheme()
  themeConfig.initTheme()
  themeConfig.setTheme('visage')
  themeConfig.addToTheme({
    themes: {
      visage: visageTheme,
    },
  })
  return themeConfig
}
