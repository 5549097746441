
import axios from 'axios'
import sha256 from 'crypto-js/sha256'
import config from '../config'

export const partialToQl = partial => Object.entries(partial).reduce((str, b) => `${str}\n ${b[0]}: ${JSON.stringify(b[1])}`, '')

/**
 * GraphQl base service
 */
export class GraphQlService {
  /**
   * Query to the hasura backend
   * @param query GraphQl string query
   * @param variables
   * @return {Promise<any>} Any response
   */
  async query(query, variables = {}) {
    // Make a unique key from the query to ref the cache
    const key = sha256(query).toString()

    // Query graphql
    const res = await axios.post(
      config.graphqlEndpoint,
      { query, variables },
      {
        headers: {
          Authorization: `Bearer ${$root.profile.idToken}`,
        },
      },
    )

    // Map eventual error on js Error
    if (res.data.errors) throw new Error(res.data.errors[0].message)

    return res.data
  }
}

