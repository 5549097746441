/**
 * Check if value from nxpack is true
 * @param value {*}
 * @returns {boolean}
 */
export function isTrue(value) {
  if(typeof value === 'boolean') return value
  if(typeof value === 'string') {
    const v = value.toLowerCase()
    return v === 'true' || v === 'vrai'
  }
  return false
}
