<template>
  <wrapper-charts :data="data" v-if="data" />
  <div v-else-if="!state.plot.viz">Select a visualization</div>
</template>

<script setup lang="ts">
import { IGridState } from '@hauru/common'
import { computed, onErrorCaptured } from 'vue'

interface IProps {
  state: IGridState
}

const props = defineProps<IProps>()

const data = computed(() => {
  const keys = Object.keys(props.state.data[0] || {})
  const type = [props.state.plot.viz, props.state.plot.variant].filter().join('-')
  const component_type =
    {
      bar: 'vertical_bar',
      'bar-stacked': 'vertical_stacked_bar',
      'bar-full-stacked': 'vertical_full_stacked_bar',
      'bar-horizontal': 'horizontal_bar',
      'bar-horizontal-stacked': 'horizontal_stacked_bar',
      'bar-horizontal-full-stacked': 'horizontal_full_stacked_bar',
      'area-stacked': 'stacked_area',
      'area-full-stacked': 'full_stacked_area',
      line: props.state.metadata.columns[props.state.plot.x]?.type === 'number' ? 'line_plot' : 'time_series',
      scatter: 'xy_plot',
    }[type] || type
  const x = keys.findIndex(v => v === props.state.plot.x)
  const y = keys.findIndex(v => v === props.state.plot.y)
  // const z = keys.findIndex(v => v === props.state.plot.z)
  // const color = keys.findIndex(v => v === props.state.plot.color)
  const columns = keys.map((k, i) => ({ col_id: i, component_type: 'TODO', options: { label: k } }))
  const rows = props.state.data.map((row, i) => ({ row_id: i, cells: Object.values(row).map(v => ({ value: v })) }))

  // console.log(props.state.data, props.state.plot)
  // debugger
  return {
    component_id: -1,
    component_type,
    options: {
      values: type === 'pie' ? y : undefined,
      cols: type === 'pie' ? undefined : [y],
      // category: z,
      partition: x,
      // operation: 'nb',
      // nb_max_categories: 5,
    },
    dataset: {
      columns,
      rows,
    },
  }
})
onErrorCaptured(err => {
  console.log('plot error', err)
})
</script>
