<template lang="pug">
.donut-tooltip(class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" :style='style')
  div.text-gray-500.border-b.border-b-gray-200.text-center(v-if="title")
    | {{ title }}
  div
    div.py-1(v-for="(row, k) in rows" :key="k")
      .text-center
        span.number.text-4xl.font-bold(:style="{color: row.color}") {{ row.value }}
      .text-center.truncate.w-24.pointer-events-none
        span {{ row.label }}
</template>
<style scoped>

</style>
<script>
export default {
  props: {
    /**
     * Tooltip title
     */
    title: {
      type: String,
      default: '',
    },
    /**
     * Tooltip table rows
     * eg of row: {color: 'blue', label: 'Fund', value: '102.2'}
     */
    rows: {
      type: Array,
      default: () => [],
    },
    /**
     * Bound of the chart
     * Could be used to calculate the tooltip postion
     */
    chartArea: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    style() {
      if (this.chartArea === null) return ''
      const centerY = this.chartArea.centerY ? this.chartArea.centerY : this.chartArea.height / 2 + this.chartArea.top
      const centerX = this.chartArea.centerX ? this.chartArea.centerX : this.chartArea.width / 2 + this.chartArea.left
      return `top: ${centerY}px;left: ${centerX}px;`
    },
  },
}
</script>
