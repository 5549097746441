import { pagesFromGlobRecord, componentsFromGlobRecord } from '@hauru/src/utils/platformBuilder'
import layout from './layout.vue'
const pages = pagesFromGlobRecord(import.meta.globEager('./pages/**/*.vue'), './pages')
const components = componentsFromGlobRecord(import.meta.globEager('./components/**/*.vue'))
export default {
  name: 'builder',
  routePrefix: '/builder',
  pages,
  components,
  layout,
}
