<template>
  <div class="playground border rounded-lg flex flex-col">
    <div class="playground-header h-[40px] bg-gray-200 border-b border-gray-50 flex" v-if="$slots.header">
      <slot name="header">
        <div class="h-full flex">
          <!-- <select class="text-xs uppercase bg-transparent border-none">
            <option>Dataset 1</option>
            <option>Dataset 2</option>
            <option>Dataset 3</option>
          </select> -->
        </div>
        <div class="h-full flex mx-auto text-xs uppercase">
          <div
            class="w-12 flex place-content-center place-items-center border-l border-gray-50 cursor-pointer hover:bg-white/40"
            :class="{ 'active !bg-white': size.width === s.width && size.height === s.height }"
            v-for="s in sizes"
            @click="size = s"
          >
            {{ s.title }}
          </div>
        </div>
        <div class="h-full flex text-xs uppercase">
          <div class="w-24 flex place-content-center place-items-center border-l border-gray-50">W{{ size.width }} x H{{ size.height }}</div>
          <div class="w-12 flex place-content-center place-items-center border-l border-gray-50">{{ size.zoom }}%</div>
          <div class="w-12 flex place-content-center place-items-center border-l border-gray-50">F</div>
          <!-- <select class="text-xs uppercase bg-transparent border-none">
            <option>Color Palette 1</option>
            <option>Color Palette 2</option>
            <option>Color Palette 3</option>
          </select> -->
        </div>
      </slot>
    </div>
    <div class="playground-center flex h-[400px] p-8 bg-gray-200 overflow-auto">
      <div class="playground-zoom flex m-auto" :style="{ zoom: size.zoom / 100 }">
        <div
          class="playground-container flex m-auto resize overflow-hidden"
          :style="{ width: size.width + 'px', height: size.height + 'px' }"
          @mouseup=";(size.width = Math.round($event.target.offsetWidth)), (size.height = Math.round($event.target.offsetHeight))"
        >
          <div class="playground-component flex w-full h-full overflow-auto" @mouseup.stop>
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="playground-footer h-[200px] flex">
      <div class="h-full p-4 flex-1 flex flex-col uppercase space-y-1">
        <h3 class="font-semibold">Variants</h3>
      </div>
      <div class="h-full p-4 flex-1 flex flex-col uppercase space-y-1">
        <h3 class="font-semibold">Constrains</h3>
      </div>
    </div> -->
  </div>
</template>

<script setup lang="ts">
import { ref, getCurrentInstance, onMounted, onUnmounted } from 'vue'

const sizes = [
  // { title: 'Tablet', width: 1024, height: 768, zoom: 80 },
  // { title: 'Laptop', width: 1440, height: 920, zoom: 60 },
  // { title: 'Desktop', width: 1920, height: 1080, zoom: 60 },
  // { title: '4K', width: 2560, height: 1440, zoom: 60 },
]
const size = ref(sizes[0] || { zoom: 100 })

// Change zoom value on CMD + mouse wheel within 20% and 400%
const instance = getCurrentInstance()
const changeZoom = (e: WheelEvent) => {
  if (!e.metaKey) return
  if (!e.path.includes(instance.vnode.el)) return
  e.preventDefault()
  size.value.zoom = Math.min(Math.max(size.value.zoom + e.deltaY, 20), 400)
}
onMounted(() => document.addEventListener('wheel', changeZoom, { passive: false }))
onUnmounted(() => document.removeEventListener('wheel', changeZoom))
</script>
