<script setup>
import { ref, watch, nextTick, onMounted} from "vue"
import performance_data from "../../data/performance.json"
import NewTable from "../../components/new-table.vue"
import Chart from "chart.js/auto"
import Waterfall from "../../components/waterfall.vue"

var representation = ref("graph")

//table view
const combined_data = ref(
  performance_data
    .filter((d) => d.portfolioCode === $root.params.userflow)
    .__.map((d) => ({
      Date: new Date(d.date).format(),
      "NAV / Performance": d.actifNet,
      Capital: d.capital,
      "Preferred Return": d.preferredReturn,
      "Catch-Up": d.catchUpHurdle,
      "Performance percent": d.performance / 100,
      "Capital percent": d.capitalPercentage / 100,
      "Preferred Return percent": d.preferredReturnPercentage / 100,
      "Catch-Up percent": d.catchUpPercentage / 100,
      "Capital filled": d.actifNet > d.capital ? "100%" : d.actifNet / d.capital,
      "Preferred Return filled":
        d.actifNet > d.preferredReturn ? "100%" : d.actifNet > d.capital ? d.actifNet / d.preferredReturn : "0%",
      "Catch-Up filled":
        d.actifNet > d.catchUpHurdle ? "100%" : d.actifNet > d.preferredReturn ? d.actifNet / d.catchUpHurdle : "0%",
    }))
)


const options_combined = {
  primary_columns: ["Date", "NAV / Performance", "Capital", "Preferred Return", "Catch-Up"],
  secondary_columns: [
    "Date",
    "Preferred Return percent",
    "Catch-Up percent",
    "Capital filled",
    "Preferred Return filled",
    "Catch-Up filled",
  ],
  detailed_cell: ["Date", ["NAV / Performance", "Performance percent"], "Capital", "Preferred Return", "Catch-Up"],
  sort_column: "-Date",
  table_size: 10,
  format: {
    "NAV / Performance": ".2fM€",
    Capital: ".2fM€",
    "Preferred Return": ".2fM€",
    "Catch-Up": ".2fM€",
    "Performance percent": ".2%",
    "Capital percent": ".2%",
    "Preferred Return percent": ".2%",
    "Catch-Up percent": ".2%",
    "Capital filled": ".2%",
    "Preferred Return filled": ".2%",
    "Catch-Up filled": ".2%",
  },
  // colored_text : "Capital filled"
}

//-----------------------//
//Graph view : raw data

const canvas_full = ref(null)
const canvas_detailed = ref(null)
const canvas_share = ref(null)

const configuration = {
  type: "bar",
  data: {
    labels: ["12/31/2014", "06/30/2015", "12/31/2015", "06/30/2016", "12/31/2016", "06/30/2017"],
    datasets: [
      {
        type: "line",
        label: "Performance",
        backgroundColor: "transparent",
        borderWidth: 2,
        borderColor: "#e65100",
        data: [5178575, 4391778.3, 8245000, 9575508.8, 9991090.02, 10045245.6],
        pointHitRadius: 20,
      },
      {
        type: "bar",
        label: "Capital",
        backgroundColor: "#0dd8b8 ",
        borderWidth: 2,
        borderColor: "#0dd8b8 ",
        data: [5178575, 5178575, 9383950, 9434075, 9528600, 9528600],
      },
      {
        type: "bar",
        label: "Preferred Return",
        backgroundColor: "rgba(150,245,220, 1)",
        borderWidth: 2,
        borderColor: "rgba(150,245,220, 1)",
        data: [414286, 414286, 750716, 754726, 762288, 762288],
      },
      {
        type: "bar",
        label: "Catch-Up",
        backgroundColor: "rgb(53,240,150)",
        borderWidth: 2,
        borderColor: "rgb(53,240,150)",
        data: [4764289, 4764289, 8633234, 8679349, 8766312, 8766312],
      },
      {
        type: "bar",
        label: "Profit Sharing",
        backgroundColor: "rgba(0,200,0, 0.8)",
        borderWidth: 2,
        borderColor: "rgba(0,200,0, 0.8)",
      },
    ],
  },
  options: {
    responsive: true,
    // maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: "Performance Analysis",
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    onClick: (a,b,c) => {console.log(a,b,c)
      document.querySelector('#waterfall').scrollIntoView({behavior:"smooth", block:"center"})},
    onHover: (event, chartElement) => {
      event.native.target.style.cursor = chartElement.length == 1 ? 'pointer' : 'default'
    }
  },
}
const configuration_detailed = {
  type: "bar",
  data: {
    labels: ["12/31/2015","06/30/2016", "12/31/2016"],
    datasets: [
      {
        type: "line",
        label: "Performance",
        backgroundColor: "transparent",
        borderWidth: 2,
        borderColor: "#e65100",
        data: [8245000, 9575508.8, 9991090.02],
        pointHitRadius: 20,
      },
      {
        type: "bar",
        label: "Capital",
        backgroundColor: "#0dd8b8 ",
        borderWidth: 2,
        borderColor: "#0dd8b8 ",
        data: [9383950, 9434075, 9528600],
      },
      {
        type: "bar",
        label: "Preferred Return",
        backgroundColor: "rgba(150,245,220, 1)",
        borderWidth: 2,
        borderColor: "rgba(150,245,220, 1)",
        data: [750716, 754726, 762288],
      },
      {
        type: "bar",
        label: "Catch-Up",
        backgroundColor: "rgb(53,240,150)",
        borderWidth: 2,
        borderColor: "rgb(53,240,150)",
        data: [8633234, 8679349, 8766312],
      },
      {
        type: "bar",
        label: "Profit Sharing",
        backgroundColor: "rgba(0,200,0, 0.8)",
        borderWidth: 2,
        borderColor: "rgba(0,200,0, 0.8)",
      },
    ],
  },
  options: {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        title: {
          display: true,
          text: "Euros (€)",
        },
        stacked: true,
        min: 8000000,
        max: 11000000,
      },
    },
  },
}
const configuration_share = {
  type: "bar",
  data: {
    labels: ["B", "A1", "A2"],
    datasets: [
      {
        type: "line",
        label: "Performance",
        borderWidth: 2,
        borderColor: "#e65100",
        data: [100, 101.5, 101.5],
        showLine: false,
      },
      {
        type: "bar",
        label: "Capital",
        backgroundColor: "#0dd8b8 ",
        borderWidth: 2,
        borderColor: "#0dd8b8 ",
        data: [100, 100, 100],
      },
      {
        type: "bar",
        label: "Preferred Return",
        backgroundColor: "rgba(150,245,220, 1",
        borderWidth: 2,
        borderColor: "rgba(150,245,220, 1)",
        data: [0, 8.02, 8.02],
      },
      {
        type: "bar",
        label: "Catch-Up",
        backgroundColor: "rgb(53,240,150)",
        borderWidth: 2,
        borderColor: "rgb(53,240,150)",
        data: [100, 73.76, 73.76],
      },
      
    ],
  },
  options: {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Partner",
        },
        stacked: true,
      },
      y: {
        title: {
          display: true,
          text: "Pourcentage (%)",
        },
        stacked: true,
        min: 70,
      },
    },
  },
}

var chart_full = null
var chart_detailed = null
var chart_share = null

onMounted( () => {
  chart_full = new Chart(canvas_full.value, configuration)
  chart_detailed = new Chart(canvas_detailed.value, configuration_detailed)
  chart_share = new Chart(canvas_share.value, configuration_share) 
}
)

watch(representation, async () => {
  if (representation.value == "table") {
    return
  }
  await nextTick()
  if (chart_full) chart_full.destroy()
  if (chart_detailed) chart_detailed.destroy()
  if (chart_share) chart_share.destroy()

  chart_full = new Chart(canvas_full.value, configuration)
  chart_detailed = new Chart(canvas_detailed.value, configuration_detailed)
  chart_share = new Chart(canvas_share.value, configuration_share)
})
</script>
<script>
export const additions = { icon: "ic_timeline" }
</script>

<template>
  <div class="flex flex-col">
    <h1 class="justify-center font-bold mb-3 text-3xl pb-4">
      {{ $root.params.userflow }} : {{ t.performance }}
    </h1>

    <div class="pb-1">
      <label class="pr-4 font-bold" for="vue"> {{t.view}}:</label>
      <select v-model="representation" class="border-[3px] text-center w-auto">
        <option value="table">Table</option>
        <option value="graph">Graph</option>
      </select>
    </div>

    <NewTable class="pt-3" v-if="representation == 'table'" :data="combined_data" :options="options_combined">
      <template #table="{ row, column_group }">
        <div v-if='column_group[0] == "NAV / Performance"' class="flex flex-col">
          <div>{{ row[column_group[0]] }}</div>
          <div
            class="text-sm"
            :class="
              row[column_group[1]] < '0%'
                ? 'text-red-500 font-medium'
                : row[column_group[1]] > '0.001%'
                ? 'text-green-500 font-medium'
                : 'text-gray-500'
            "
          >
            {{ row[column_group[1]] }}
          </div>
        </div>
        <div v-else>{{ row[column_group] }}</div>
      </template>
    </NewTable>

    <div v-else>
      <div class="flex justify-center">
        <div class="flex w-[80vw]">
          <canvas ref="canvas_full"></canvas>
        </div>
      </div>
      <div>
        <div class="flex flex-row justify-around pt-20">
          <div class="flex flex-col items-center w-1/3">
            <div class="font-bold text-2xl">{{ t.performanceFocus }}</div>
            <div class="font-bold pb-8">101.50% on June 2016</div>
            <div class="w-auto h-[250px]">
              <canvas ref="canvas_detailed"></canvas>
            </div>
          </div>
          <div id="waterfall" class="flex flex-col items-center w-1/3">
            <div class="font-bold text-2xl">{{ t.waterfallDistribution }}</div>
            <Waterfall class="pl-12 w-[350px] relative" />
          </div>
          <div class="flex flex-col items-center w-1/3">
            <div class="font-bold text-2xl pb-12">{{ t.shareDistribution }}</div>
            <div class="flex w-fill h-[250px]">
              <canvas ref="canvas_share"></canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
