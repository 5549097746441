<script setup>
import kpiData from "./slash.json"
import KpiCard from "../components/kpi-card.vue"

</script>
<script>
export const additions = { icon: "ic_account_balance_wallet" }
</script>

<template>
  <div>
    <div class="space-y-4">
      <div class="flex flex-row justify-between flex-wrap">
        <KpiCard v-for="(data, index) in kpiData" :key="index" :kpi_data="data" />
      </div>
    </div>
  </div>
</template>
