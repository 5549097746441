<script setup>
import GaugeFiller from "./gauge-filler.vue"

const data = {
  gauge1: {
    percent: 100,
    name: "Capital",
    value: "9 434 075",
  },
  gauge2: {
    percent: 94,
    name: "Preferred Return",
    value: "10 188 801",
  },
  gauge3: {
    percent: 0,
    name: "Catch-Up",
    value: "18 868 150",
  },
  gauge4: {
    percent: 0,
    name: "Profit-Sharing",
    
  },
}
const options = {
  config: {
    circleColor: "#0dd8b8",
    waveColor: "#0dd8b8",
  },
  id: "fillgauge",
}

const options2 = {
  config: {
    circleColor: "rgba(150,245,220, 1)",
    waveColor: "rgba(150,245,220, 1)",
  },
  id: "fillgauge2",
}
const options3 = {
  config: {
    circleColor: "rgb(53,240,150)",
    waveColor: "rgb(53,240,150)",
  },
  id: "fillgauge3",
}
const options4 = {
  config: {
    circleColor: "rgba(0,200,0, 0.8)",
    waveColor: "rgba(0,200,0, 0.8)",
  },
  id: "fillgauge4",
}
</script>

<template>
  <div>
    <div class="w-[200px] absolute">
      <GaugeFiller :data="data.gauge1" :options="options"> €</GaugeFiller>
    </div>
    <div class="pl-8 w-[250px] pt-[90px] absolute">
      <GaugeFiller :data="data.gauge2" :options="options2"> €</GaugeFiller>
    </div>
    <div class="pl-16 w-[300px] pt-[180px] absolute">
      <GaugeFiller :data="data.gauge3" :options="options3"> €</GaugeFiller>
    </div>
    <div class="pl-24 w-[350px] pt-[270px] absolute">
      <GaugeFiller :data="data.gauge4" :options="options4"></GaugeFiller>
    </div>
  </div>
</template>

<style></style>
