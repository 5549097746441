import {dbRef} from "../../../features/firebase";
import {onMounted, onUnmounted, ref} from "vue";

export function useNxPack() {
  const nxpackRef = dbRef.child('/nxpack')
  const loaded = ref(false)
  const nxpack = ref({})
  const update = (snap) => {
    nxpack.value = snap.val() || {}
    loaded.value = true
  }
  onMounted(() => {
    loaded.value = false
    nxpackRef.on('value', update)
  })
  onUnmounted(() => nxpackRef.off('value', update))
  return {nxpack, loaded}
}
