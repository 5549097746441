<template>
  <div class="nx-pie relative m-auto w-[100px]">
    <svg viewBox="-100 -100 200 200">
      <path :d="item.arc" :class="item.name?.format('dash')" v-for="item in items"></path>
      <circle r="65"></circle>
    </svg>
    <!-- <div class="center pointer-events-none absolute inset-0 flex h-full w-full flex-col items-center justify-center">
    <div><span class="text-3xl leading-none">{{ total }}</span><span class="text-2xl leading-none">u</span></div>
    <div class="text-base uppercase leading-none">Total</div>
  </div> -->
  </div>
</template>

<script setup>
import * as d3 from "d3"
const { data, options } = defineProps(["data", "options"])

const total = data.map((v) => v.value).sum()
const items = data
  // .sort("-value")
  .map((v, i, ds) => {
    const prev = ds.slice(0, i).map("value").sum()
    const next = prev + v.value
    return {
      ...v,
      arc: d3.arc()({
        innerRadius: 60,
        outerRadius: 90,
        startAngle: (2 * Math.PI * prev) / total,
        endAngle: (2 * Math.PI * next) / total,
      }),
    }
  })
</script>

<style>
.nx-pie path {
  cursor: pointer;
  fill: rgb(var(--primary));
}
.nx-pie circle {
  pointer-events: none;
  fill: none;
  stroke: white;
  stroke-width: 2px;
}
.nx-pie path:nth-child(2n) {
  opacity: 0.8;
}
.nx-pie path:nth-child(3n) {
  opacity: 0.6;
}
.nx-pie path:nth-child(4n) {
  opacity: 0.4;
}
.nx-pie path:nth-child(5n) {
  opacity: 0.2;
}
.nx-pie path:nth-child(n + 6) {
  opacity: 0.1;
}
</style>
