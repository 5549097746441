import { IGridContainer, IGridItemMetadata } from '@hauru/common'
import { faker } from '@faker-js/faker'

let id = 0
function createRandomRow(columnLevels: number) {
  if (columnLevels === 2) {
    return {
      id: id++,
      Account: faker.finance.account(),
      'Account Name': faker.finance.accountName(),
      Y2020: {
        Q1: faker.finance.amount(),
        Q2: faker.finance.amount(),
        Q3: faker.finance.amount(),
        Q4: faker.finance.amount(),
        default: faker.finance.amount(),
      },
      Y2021: {
        Q1: faker.finance.amount(1_000_000, 100_000_000),
        Q2: faker.finance.amount(),
        Q3: faker.finance.amount(),
        Q4: faker.finance.amount(),
        default: faker.finance.amount(),
      },
      Y2022: {
        Q1: faker.finance.amount(),
        Q2: faker.finance.amount(),
        Q3: faker.finance.amount(),
        Q4: faker.finance.amount(),
        default: faker.finance.amount(),
      },
    }
  }
  if (columnLevels === 3) {
    return {
      id: id++,
      'Account #': faker.finance.account(),
      'Account Category': faker.finance.accountName(),
      'Data Group': {
        Y2020: {
          Q1: faker.finance.amount(),
          Q2: faker.finance.amount(),
          Q3: faker.finance.amount(),
          Q4: faker.finance.amount(),
          default: faker.finance.amount(),
        },
        Y2021: {
          Q1: faker.finance.amount(1_000_000, 100_000_000),
          Q2: faker.finance.amount(),
          Q3: faker.finance.amount(),
          Q4: faker.finance.amount(),
          default: faker.finance.amount(),
        },
        Y2022: {
          Q1: faker.finance.amount(),
          Q2: faker.finance.amount(),
          Q3: faker.finance.amount(),
          Q4: faker.finance.amount(),
          default: faker.finance.amount(),
        },
        default: faker.finance.amount(),
      },
    }
  } else {
    return {
      id: id++,
      Account: faker.finance.account(),
      'Account Name': faker.finance.accountName(),
      Type: faker.helpers.arrayElement(['Individual', 'Company']),
      Valid: faker.helpers.arrayElement([true, false]),
      Updated: faker.date.past(), //.format('YYYY-MM-DD hh:mm:ss'),
      Quantity: faker.datatype.number(100),
      Price: faker.datatype.float({ min: 1, max: 1000, precision: 2 }),
      Value: faker.datatype.float({ min: 100, max: 100000, precision: 2 }),
      Amount: faker.finance.amount(0, 1_000_000_000),
      'Currency Code': faker.finance.currencyCode(),
      Registered: faker.date.past(), //.format('day, month, year', 'en'),
      Status: faker.helpers.arrayElement(['Active', 'Inactive', 'Pending']),
      Actions: faker.helpers.arrayElement(['Edit', 'Delete', 'View']),
      'Invested Amount': faker.finance.amount(0, 1_000_000_000, 0),
      'Profit/Loss': faker.finance.amount(),
      // 'Profit/Loss %': faker.finance.amount(0, 100, 0) + '%',
    }
  }
}

function generateRows(rows = 100, columnLevels = 1) {
  const data = []
  for (let i = 0; i < rows; i++) {
    data.push(createRandomRow(columnLevels))
  }
  return data
}

function generateRandomNumber(x: number, y: number) {
  return Math.floor(Math.random() * (y - x + 1)) + x
}

export function generateTree({
  rows = 100,
  levels = 3,
  columnLevels = 1,
  minGroupRows = 1,
  maxGroupRows = 10,
  createNodeProbability = 80,
} = {}): IGridContainer {
  id = 0
  const data = generateRows(rows, columnLevels)

  let children = data.map(
    row =>
      ({
        type: 'row',
        id: row.id,
      } as IGridItemMetadata),
  )
  let parents: IGridItemMetadata[] = []

  for (let curLevel = 0; curLevel < levels; curLevel++) {
    const initialLength = children.length
    parents = []
    for (let itemI = 0; itemI < initialLength; ) {
      const itemCount = generateRandomNumber(minGroupRows, maxGroupRows)
      const createNode = generateRandomNumber(1, 100) <= createNodeProbability

      if (createNode) {
        const nodeData = createRandomRow(columnLevels)
        data.push(nodeData)

        parents.push({
          type: 'node',
          id: nodeData.id,
          children: children.slice(itemI, itemI + itemCount),
        })
      } else {
        parents.push(...children.slice(itemI, itemI + itemCount))
      }
      itemI += itemCount
    }

    children = parents
  }

  function aggregate(arr, path, state, column) {
    if (state.columns.yieldColumns().next().value.label !== column) return
    return `${path.at(-1)} [${arr.filter(x => typeof x.id === 'number').length}]`
  }
  return {
    data,
    metadata: {
      tree:
        levels > 0
          ? {
              type: 'node',
              id: '-1',
              is_virtual: true,
              children: parents,
            }
          : undefined,
      columns: {
        // id: { type: 'id', aggregate, width: 20 },
        Account: { type: 'string', aggregate },
        'Account Name': { type: 'string', aggregate },
        Type: { type: 'list', aggregate, list: ['Individual', 'Company'] },
        Valid: { type: 'boolean', aggregate },
        Updated: { type: 'datetime', aggregate, pattern: date => new Date(date).format('YYYY-MM-DD hh:mm:ss') },
        Quantity: { type: 'number', aggregate },
        Price: { type: 'number', aggregate },
        Value: { type: 'number', aggregate },
        Amount: { type: 'number', aggregate, pattern: num => num?.format('en', { maximumFractionDigits: 2 }) },
        'Currency Code': { type: 'string', aggregate },
        Registered: { type: 'date', aggregate, pattern: date => new Date(date).format('day, month, year', 'en') },
        Status: { type: 'list', aggregate, list: ['Active', 'Inactive', 'Pending'] },
        Actions: { type: 'list', aggregate, list: ['Edit', 'Delete', 'View'] },
        'Invested Amount': { type: 'number', aggregate },
        'Profit/Loss': { type: 'number', aggregate, pattern: num => num?.format('en', { maximumFractionDigits: 2 }) },
        'Profit/Loss %': { type: 'number', aggregate },
        // Q1: { type: 'number', aggregate },
        // Q2: { type: 'number', aggregate },
        // Q3: { type: 'number', aggregate },
        // Q4: { type: 'number', aggregate },
        // default: { type: 'number', aggregate },
      },
    },
  }
}
