import { reactive, ref } from 'vue'

async function defaultLogin(username, password) {
  const profile = { username, email: username, role: 'user' }
  localStorage.profile = JSON.stringify(profile)
  return profile
}
async function defaultLogout() {}
async function defaultInit() {
  return JSON.parse(localStorage.profile || null)
}
function isAuthenticated(profile) {
  return !!profile
}
const profileRef = ref(null)
let _login, _logout
export default function useAuth() {
  const guard = (to, from, next) => {
    const profile = profileRef.value
    const authenticated = isAuthenticated(profile)
    const role = authenticated ? profile.role : 'anonymous'
    const authorized = to.meta.roles.includes(role) || (role === 'admin' && !to.path.startsWith('/login'))
    const path = to.fullPath
    if (!authenticated && !authorized) return next(`/login?redirect=${path}`)
    if (authenticated && !authorized) return next('/')
    return next()
  }
  return {
    authPlugin(app, { login, onRequest, logout, initAuth }) {
      const global = app.config.globalProperties
      _login = (...args) =>
        (login || defaultLogin)(...args).then(profile => {
          profileRef.value = profile
          global.$router.push('/')
        })
      _logout = () =>
        (logout || defaultLogout)().then(() => {
          profileRef.value = null
          global.$router.push('/login')
        })
      // TODO not be dependant on axios being setup ?
      if (global.$axios) {
        global.$axios.interceptors.request.use(config => {
          config.headers['Authorization'] = `Bearer ${profileRef.value.idToken}`;
          return config;
        });
        if (onRequest) global.$axios.interceptors.request.use(onRequest)
        global.$axios.interceptors.response.use(
          res => {
            try {
              if (res.data.errors.some(error => error.extensions.code === "FORBIDDEN")) _logout()
            } catch(e) {}
            return res
          },
          err => {
            const authenticated = isAuthenticated(profileRef.value)
            if (authenticated && err.response && err.response.status === 401) {
              _logout()
            }
          },
        )
      }
      global.$auth = reactive({
        profile: profileRef,
        login: _login,
        logout: _logout,
        initAuth: () => (initAuth || defaultInit)().then(p => (profileRef.value = p)),
      })
    },
    guard,
    profile: profileRef,
    login: _login,
    logout: _logout,
  }
}
