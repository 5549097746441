import { dbRef, update } from '../../../features/firebase'
import { onMounted, ref } from 'vue'

let runsRef

export function useRuns() {
  runsRef = dbRef.child('/data/runs')
  const loaded = ref(false)
  const deleteRun = async id => {
    return await update(`data.runs.${id}`, { disabled: true })
  }

  const runs = ref({})

  const updateRuns = (snap) => {
    runs.value = snap.val()
    loaded.value = true
  }
  onMounted(async () => {
    loaded.value = false
    runsRef.once('value', updateRuns)
  })
  return {
    deleteRun,
    runs,
    loaded,
  }
}
