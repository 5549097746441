import { INxButtonProps, IThemeClass, IThemeConfig, ITypeThemeBase } from '@hauru/common'

export type IButtonTheme = Pick<INxButtonProps, 'shape' | 'size' | 'label' | 'icon' | 'iconPlacement' | 'iconClass'> &
  ITypeThemeBase & {
    idle?: IThemeClass
    hover?: IThemeClass
    active?: IThemeClass
    [key: string]: IThemeClass | undefined
  }

export const buttonTheme: IThemeConfig = {
  themes: {
    $default: {},
    nx: {
      button: {
        default: 'basic',
        basic: {
          idle: 'text-gray-900 hover:bg-accent hover:border-accent hover:text-white',
        },
        outlined: {
          idle: 'text-gray-900 border border-solid border-accent duration-75 hover:bg-accent hover:text-white',
        },
        flat: {
          idle: 'text-gray-900 bg-accent duration-75 hover:bg-accent hover:text-white',
        },
        drag: {
          idle: '!cursor-grab text-gray-300 transition-colors hover:text-gray-500',
        },
      },
    },
  },
}
