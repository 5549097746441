import { GraphQlService } from './GraphQlService'

export class ShareService extends GraphQlService{
  async listAll(expired = false, standby = false) {
    return (await this.query(`
    query Shares {
      rawData {
        business {
          shareReferentials {
            fund_name
            fund_id
            share_id
          }
        }
      }
    }`))
      .data.rawData.business.shareReferentials
  }
}

const shareService = new ShareService()
export default shareService
