import { onMounted, onUnmounted, ref } from 'vue'
import { dbRef } from '../../../features/firebase'
const presses = ref([])
const initialized = ref(false)
export function usePresses() {
  const pressesRef = dbRef.child('/data/presses')
  const loaded = ref(false)
  const onUpdate = snap => {
    presses.value = (snap.val() || []).v()
    loaded.value = true
    initialized.value = true
  }
  onMounted(() => {
    loaded.value = false
    pressesRef.on('value', onUpdate)
  })
  onUnmounted(() => pressesRef.off('value', onUpdate))
  return {
    loaded,
    presses,
    initialized,
  }
}
