<template>
  <!-- nx-dropdown-select-list -->
  <draggable
    tag="div"
    handle=".handle"
    :disable="!props.group"
    :list="props.list"
    :group="{ name: props.group }"
    :item-key="(el: string) => el"
    @change="onChange"
  >
    <template #item="{ element }: { element: any, index: number }">
      <nx-list-item v-show="props.show?.(element) ?? true">
        <div
          class="flex flex-1 !cursor-default items-center"
          :class="[!props.group ? 'h-7' : '', props.isToggleable ? '' : 'pl-4']"
        >
          <div class="w-2"></div>
          <nx-switch v-if="props.isToggleable" @click="props.toggle(element)" :checked="getChecked(element)" />
          <!--
            @slot Content of selected item in the list
            @binding {string}  element  Selected item
          -->
          <slot name="item" :element="element">
            {{ getLabel(element) }}
          </slot>
        </div>
        <nx-button v-if="props.isDraggable && props.group" class="handle" v-bind="dragButton" />
        <div v-if="!props.group" class="w-7"></div>
      </nx-list-item>
    </template>
  </draggable>
</template>

<script setup lang="ts">
import { IDraggableChange, INxButtonProps } from '@hauru/common'
import draggable from 'vuedraggable'

interface IProps<T = any> {
  /**
   * Array of strings representing available items
   */
  list?: T[]
  /**
   * String representing the name of the draggable group of items
   */
  group?: string
  /**
   * Key to use to access the label of an item :
   * - a string of the key used to access the label (ex. 'label' for item.label)
   * - a function that takes the item and returns the label
   * If not provided, the item itself will be used as the label
   * @param el The item to be accessed
   */
  labelKey?: string | ((el: T) => string)
  /**
   * Boolean indicating if item is selected or Function to check if an item is selected
   * @param el The item to be checked
   */
  checked?: boolean | ((el: T) => boolean)
  /**
   * Function to check if an item is visible
   * @param el The item to be checked
   */
  show?: (el: T) => boolean
  /**
   * Function to toggle an item in a list
   * @param key The key of the item to be added or removed
   */
  toggle: (key: T) => void
  /**
   * Boolean indicating if the list is toggleable
   */
  isToggleable?: boolean
  /**
   * Boolean indicating if the list is draggable
   */
  isDraggable?: boolean
}

const props = withDefaults(defineProps<IProps>(), {
  isToggleable: true,
  isDraggable: true,
})

const emit = defineEmits<{
  /**
   * Event emitted when the order of the items changes
   * @param draggableChange The change object
   */
  (e: 'change', draggableChange: IDraggableChange): void
}>()

const dragButton: INxButtonProps = {
  icon: 'ic-baseline-drag-indicator',
  iconClass: 'w-5 h-5',
  type: 'drag',
  size: 'md',
}

/**
 * Get the label of an item
 * @param el The item to be accessed
 */
function getLabel(el: any) {
  if (typeof props.labelKey === 'function') {
    return props.labelKey(el)
  } else if (typeof props.labelKey === 'string') {
    return el[props.labelKey]
  } else {
    return el
  }
}

/**
 * Get the checked state of an item
 * @param el The item to be checked
 */
function getChecked(el: any) {
  if (typeof props.checked === 'function') {
    return props.checked(el)
  } else if (typeof props.checked === 'boolean') {
    return props.checked
  } else {
    return false
  }
}

/**
 * Event emitted when the order of the items changes
 * @param draggableChange The change object
 */
function onChange(e: IDraggableChange) {
  console.log('onChange', e)
  emit('change', e)
}
</script>

<style lang="postcss" scoped>
.slider {
  @apply !bg-gray-200;
}
</style>
