import { pagesFromGlobRecord, componentsFromGlobRecord } from "../../utils/platformBuilder"
import config from "./index.yml"
const translations = config.translation

export default {
  /**
   * App name
   */
  name: "private-equity",
  /**
   * Listed
   */
  listed: true,
  /**
   * If common, this app will be merged with others
   */
  common: false,
  /**
   * Prefix to apply on the pages routes
   */
  routePrefix: "/private-equity",
  /**
   * Pages components to map to the router
   */
  pages: pagesFromGlobRecord(import.meta.globEager("./screens/**/*.vue"), "./screens"),
  /**
   * Components to add to the app
   */
  components: componentsFromGlobRecord(import.meta.globEager("./components/**/*.vue")),
  /**
   * Translations to add
   */
  translations,
  /**
   * Config partial
   */
  config,
}
