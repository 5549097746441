import { onMounted, onUnmounted, ref } from 'vue'
import { dbRef } from '../../../features/firebase'
const impressions = ref([])
const initialized = ref(false)
export function useImpressions() {
  const impressionsRef = dbRef.child('/data/impressions')
  const loaded = ref(false)
  const onUpdate = snap => {
    impressions.value = (snap.val() || {}).v()
    loaded.value = true
    initialized.value = true
  }
  onMounted(() => {
    loaded.value = false
    impressionsRef.on('value', onUpdate)
  })
  onUnmounted(() => impressionsRef.off('value', onUpdate))
  return {
    impressions,
    loaded,
    initialized,
  }
}
