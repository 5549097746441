<template>
  <!-- story-doc-item -->
  <div class="flex items-stretch gap-2">
    <div
      class="flex w-32 shrink-0 font-medium"
      :class="[hasSubItems ? 'cursor-pointer' : '']"
      @click="() => (isExpanded = !isExpanded)"
    >
      <div v-for="l in Array(props.level).keys()" class="mr-2 w-px" :class="[props.last ? 'h-[12px]' : 'h-full']"></div>

      {{ toKebabCase(props.item.name) }}
      <svg
        v-if="hasSubItems"
        :class="[isExpanded ? '' : 'rotate-[-90deg]']"
        role="img"
        width="16px"
        height="16px"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 24 24"
        class="iconify iconify--ic mt-[3px] transition-transform"
      >
        <path
          fill="currentColor"
          d="M8.12 9.29L12 13.17l3.88-3.88a.996.996 0 1 1 1.41 1.41l-4.59 4.59a.996.996 0 0 1-1.41 0L6.7 10.7a.996.996 0 0 1 0-1.41c.39-.38 1.03-.39 1.42 0z"
        ></path>
      </svg>
      <div v-else class="w-4"></div>
    </div>
    <div class="max-w-[560px] grow pb-4">
      {{ props.item.description }}
    </div>
    <div class="w-40 shrink-0 pb-4">
      <div class="h-4">
        {{ typeName }}
      </div>
      <div class="h-4 text-gray-400" :class="[!props.item.required ? 'italic' : '']">
        {{ props.item.required ? 'required' : 'optional' }}
      </div>
    </div>
    <div class="w-32 shrink-0">
      <story-controls :prop="props.item" :state="props.state" :options="props.options" :accessArg="props.accessArg" />
    </div>
  </div>
  <template v-if="hasSubItems && isExpanded">
    <story-doc-item
      v-for="(prop, index) in Object.values(itemType ?? {})"
      :item="prop"
      :state="{}"
      :options="{}"
      :level="props.level + 1"
      :last="index === Object.keys(itemType ?? {}).length - 1"
      :access-arg="props?.accessArg"
    >
    </story-doc-item>
  </template>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { getType } from '@hauru/common'
import { PropDescriptor } from 'vue-docgen-api'
import { IMapKeysToControl } from '@storytime'

interface IProps {
  /**
   * Vue documentation object of a prop as extracted by vue-docgen
   */
  item: PropDescriptor
  /**
   * Reactive object that allows to communicate the current value of props
   */
  state: object
  /**
   * Options that allow to modify the widget of props
   */
  options?: IMapKeysToControl<unknown>
  level?: number
  last?: boolean
  accessArg?: string
}

const props = withDefaults(defineProps<IProps>(), {
  level: 0,
  last: false,
})

function toKebabCase(str: string) {
  return str.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase()
}

const typeName = computed<string | undefined>(() => {
  const type = props.item.type?.name
  if (type === 'Array') return (props.item.type as any)?.elements[0]?.name + '[]'
  return type ?? (props.item.type as unknown as string)
})
const itemType = computed(() => getType(typeName.value?.replace('[]', '') ?? ''))
const hasSubItems = computed(() => Object.values(itemType.value ?? {}).length > 0)
const isExpanded = ref(false)
</script>
