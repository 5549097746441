export default (num, description, lang = "en") => {
  let [, digit = 0, unit] = /\.?(\d+)?(.*)/.exec(description)
  digit = Math.max(0, Math.min(digit, 4))
  if (unit === "bp") return Math.round(num * 10000) + "bp"
  if (unit === "%") return Intl.NumberFormat(lang, { notation: "compact", minimumFractionDigits: digit, maximumFractionDigits: digit }).format(num * 100) + "%"
  if (["$", "€", "£", "USD", "EUR", "GBP", "CHF"].includes(unit))
    return (
      Intl.NumberFormat(lang, {
        // style: 'currency',
        // currency: unit.replace('$', 'USD').replace('€', 'EUR').replace('£', 'GBP'),
        notation: "compact",
        minimumFractionDigits: digit,
        maximumFractionDigits: digit,
      }).format(num) + unit
    )
  return Intl.NumberFormat(lang, { notation: "compact", minimumFractionDigits: digit, maximumFractionDigits: digit }).format(num)
}
