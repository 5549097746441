import { pagesFromGlobRecord, componentsFromGlobRecord } from '../../utils/platformBuilder'
import Layout from './Layout.vue'

export default {
  /**
   * App name
   */
  name: 'switch',
  /**
   * Layout component
   */
  layout: Layout,
  /**
   * If common, this app will be merged with others
   */
  common: false,
  /**
   * Pages components to map to the router
   */
  pages: pagesFromGlobRecord(import.meta.globEager('./pages/**/*.vue'), './pages'),
  /**
   * Components to add to the app
   */
  components: componentsFromGlobRecord(import.meta.glob('./components/**/*.vue')),
  /**
   * Translations to add
   */
  translations: import.meta.globEager('./translations/**/*.yml'),
}
