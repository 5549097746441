import {computed, onMounted, onUnmounted, ref} from "vue";
import {dbRef} from "../../../features/firebase";
export function useUsers() {
  const userRef = dbRef.child('/data/users')
  const loaded = ref(false)
  const users = ref([])
  const emails = computed(() => users.value.map(u => u.email).sort())
  const update = (snap) => {
    users.value = (snap.val() || []).filter()
    loaded.value = true
  }
  onMounted(() => {
    userRef.on('value', update)
    loaded.value = false
  })
  onUnmounted(() => {
    userRef.off('value', update)
  })
  return {
    users,
    emails,
    loaded,
  }
}
