import { pagesFromGlobRecord, componentsFromGlobRecord } from '../../utils/platformBuilder'
import Layout from './Layout.vue'

const pages = pagesFromGlobRecord(import.meta.globEager('./pages/**/*.vue'), './pages')
pages[':catchall(.*)'] = import.meta.globEager('./Layout.vue')['./Layout.vue']
export default {
  name: 'design',
  routePrefix: '/design',
  pages,
  components: componentsFromGlobRecord(import.meta.globEager('./components/**/*.vue')),
  layout: Layout,
}
