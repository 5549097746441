export default {
  data() {
    const appCfg = this.$config.apps['client-edition'].config
    const {
      detail_info,
      report_info,
      document_info,
      context_info,
      formats,
      filtered_timelines,
      cron2def,
    } = appCfg

    const production_metadata = { key: 'slash', editable: false, sort: ['-id'], columns: [{ name: 'check', freeze: true }, { name: 'details', freeze: true }].concat(detail_info).concat(['steps']).unique() }
    const report_metadata = { key: 'report', editable: false, sort: ['pattern'], columns: [{ name: 'check', freeze: true }].concat(report_info) }
    const exported_columns = detail_info

    return {
      formats,
      cron2def,
      production_metadata,
      report_metadata,
      report_info,
      detail_info,
      document_info,
      exported_columns,
      filtered_timelines,
      see_future: false,
      assignee: null,
      empty_workflow: { name: 'Workflow', actions: { 1: { id: 1, name: 'Step', step: 'Group', type: '', duration: 3 }}}
    }
  },
  computed: {

  },
  mounted() {
    if ($root.screen.path !== 'workflow') {
      let params = { rid: null, id: null, domain: null, email: null }
      if ($root.screen.path === 'slash') params = {...params}
      update_query(params)
    }
  },
  methods: {
    validate_step(rid, lid) {
      if ($root.query.action_id) update_query({ action_id: $root.query.action_id + 1 })
      set(['data', 'runs', rid, 'logs', lid, 'user'].join('.'), $root.profile.email)
      set(['data', 'runs', rid, 'logs', lid, 'status'].join('.'), 'success')
    },
    skip_step(rid, lid) {
      set(['data', 'runs', rid, 'logs', lid, 'user'].join('.'), $root.profile.email)
      set(['data', 'runs', rid, 'logs', lid, 'skipped'].join('.'), new Date().toISOString())
      set(['data', 'runs', rid, 'logs', lid, 'status'].join('.'), 'skipped')
    },
    async rerun_step(run, action_id) {
      if (!run || !action_id) return console.warn('No run or action id available', run, action_id)
      const id = run.logs.map('id').v().max() + 1 || 0
      const log = { id, status: 'queued', action_id, user: $root.profile.email }
      await set(['data', 'runs', run.id, 'logs', id].join('.'), log)
    },
    assign_runs(runs, assignee) {
      runs.map(d => set(['data', 'runs', d, 'context', 'assignee'].join('.'), assignee))
      this.assignee = null
    },
    // animations
    leave(el, done) {
      el.style.opacity = 0
      setTimeout(() => done(), 500)
    },
  },
}
