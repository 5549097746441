<script setup>
import NewTable from "../../components/new-table.vue"
import { ref } from "vue"
import business_data from "../../data/business-data.json"

const PE_business_data = ref(
  business_data
    .filter((d) => d.portfolioCode === $root.params.userflow)
    .__.map((d) => ({ Company: d.companyLabel, Investment: d.companyValeur, Performance: d.companyPerformance / 100 }))
)

const options = {
  sort_column: "Performance",
  table_size: 10,
  format: {
    Investment: ".2fM€",
    Performance: ".2%",
  },
  colored_text: "Performance",
}
</script>
<script>
export const additions = { icon: "ic_dashboard" }
</script>

<template>
  <div class="flex flex-col">
    <h1 class="justify-center font-bold mb-6 text-3xl">{{ $root.params.userflow }} : {{ t.Position}}</h1>
    <NewTable :data="PE_business_data" :options="options" />
  </div>
</template>
