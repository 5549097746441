<style>
.downloadButton {
  background: #24292e;
  margin-top: 20px;
  color: white;
  margin-left: 200px;
}

.filter select {
  width: 200px;
}

.downloadButton:hover {
  background: #0366d6;
}

.spreadsheet {
  margin: 12px;
}

.message {
  text-align: center;
  margin-top: 10px;
}

.form .row .column {
  padding: 12px;
}


.fund_name select {
  width: 280px;
}

.isin select {
  width: 160px;
}



button[type="submit"] {
  background: #24292e;
  width: 90px;
  color: white;
  margin-top: 22px;
  margin-left: 475px;
}

button[type="submit"]:hover {
  background: #0366d6;
}

.domain input {
  width: 208px
}

.lang input {
  width: 78px;
}

.asof input {
  width: 110px;
}
</style>

<template>
  <h1> Data Audit </h1>

  <block class='form' title="search">
    <div class='row'>
      <div class="column fund_name">
        <label>{{ t.funds || 'funds' }}: </label>
        <select v-model="form.selectedFund" @change="reset">
          <option v-for="fund in fundList" :value="fund">
            {{ fund.fund_name }}
          </option>
        </select>
      </div>
      <div class="column isin">
        <label>{{ t.share || 'share' }}: </label>
        <select class="" v-model="form.selectedShare" :disabled="!form.selectedFund">
          <option v-if="form.selectedFund" v-for="share in form.selectedFund.shares" :value="share">
            {{ share.share_id }}
          </option>
        </select>
      </div>
      <div class="column filter">
        <label>{{ t.filter_list || 'filter_list' }}: </label>
        <select v-model="form.selectedFilter">
          <option v-for="filter in filtersList.filtersList" :value="filter">
            {{ filter }}
          </option>
        </select>
      </div>
      <div class="column domain">
        <label>{{ t.domain_time || 'domain_time' }}: </label>
        <input type="text" v-model="form.domain" />
      </div>
      <div class="column asof">
        <label>{{ t.as_of || 'as_of' }}: </label>
        <input type="text" v-model="form.asof" />
      </div>
      <div class="column">
        <form @submit.prevent="submitForm">
          <button type="submit">Search</button>
        </form>
      </div>
    </div>
  </block>

  <div v-if="loading" class="message">
    Data is loading...
  </div>

  <div v-else class="message">
    {{ message }} 
  </div>

  <div class="row">
    <block class='form' :title="'Filter: ' + form.selectedFilter + '  |  Length : ' + length"
      v-if="filteredAllocationData.length > 0 && form.selectedShare !== '' && this.form.domain !== '' && this.form.asof !== '' && this.form.selectedFilter !== ''">
      <div class=" row">
        <div class="column">
          <label>{{ t.fund_id || 'fund_id' }}: </label>
          <select v-model="selectedFundId">
            <option v-for="fund in displayedFundIds" :value="fund.value">{{ fund.text }}</option>
          </select>
        </div>
        <div class="column">
          <label>{{ t.axis || 'axis' }}: </label>
          <select v-model="selectedAxis">
            <option v-for="axis in axisOptions" :value="axis">{{ axis }}</option>
          </select>
        </div>
        <div class="column">
          <button @click="downloadData" class="downloadButton">Download</button>
        </div>
      </div>
    </block>

    <block class='form' title="Filter Details"
      v-if="filteredAllocationData.length > 0 && form.selectedShare !== '' && this.form.domain !== '' && this.form.asof !== '' && this.form.selectedFilter !== ''">
      <div class="column" v-for="detail in filterDetails">
        <pre>
        {{ detail }}
      </pre>
      </div>
    </block>
  </div>

  <block
    v-if="filteredAllocationData.length > 0 && form.selectedShare !== '' && this.form.domain !== '' && this.form.asof !== '' && this.form.selectedFilter !== ''">
    <spreadsheet :key="computedMetaData.columns.join('-')"
      v-if="filteredAllocationData.length > 0 && form.selectedShare !== '' && this.form.domain !== '' && this.form.asof !== '' && this.form.selectedFilter !== ''"
      :data="filteredAllocationData" :options="computedMetaData" class="spreadsheet">
    </spreadsheet>
  </block>

</template>

  



<script>
export default {

  icon: 'pt-icon-database',

  data() {
    return {
      filterDetails: [],
      displayedFundIds: [],
      fundId: [],
      filtersList: [],
      fundList: [],
      axisOptions: [],
      selectedAxis: '',
      selectedFundId: '',
      loading: false,
      length: 0,
      allocationData: [],
      spreadsheetData: {
        "key": "slash",
        "editable": false,
        "sort": [
          "-id"
        ],
      },
      form: {
        selectedFund: '',
        selectedShare: '',
        selectedFilter: '',
        domain: '',
        asof: '',
      },
      message: '',
      loaded: '',
    }
  },

  computed: {

    displayedFundIds() {
      return this.fundId.map(fund => {
        let text = `${fund} `;
        return {
          value: fund,
          text: this.form.selectedFund.fund_id === fund ? fund + ' (fund)' : fund + ' (benchmark)'
        };
      });

    },

    filteredAllocationData() {
      return this.allocationData
        .filter(item => item.fund_id === this.selectedFundId)
        .map(item => ({ ...item, [this.computedAxis]: item.axis[this.selectedAxis] }))
    },


    computedMetaData() {
      let columns = [
        "date",
        "fund_id",
        "name",
        "exposure",
      ];

      if ((this.form.selectedFund.fund_id === this.selectedFundId)) {
        columns.push("weight_fund");
      } else {
        columns.push("weight_benchmark");
      }

      columns.push(this.computedAxis);

      return {
        ...this.spreadsheetData,
        columns
      }
    },

    computedAxis() {
      return this.selectedAxis
    }
  },


  async mounted() {
    await this.getFundListData()
    await this.getFilterData()
  },


  methods: {

    reset() {
      this.form.selectedShare = '',
        this.form.domain = '',
        this.form.asof = '',
        this.form.selectedFilter = '',
        this.selectedFundId = '',
        this.selectedAxis = '',
        this.filteredAllocationData = []
    },

    async getFundListData() {
      const resp = await axios({
        url: '/dr',
        method: 'post',
        data: {
          variables: this.params,
          query: `
          query SlashQuery {
            funds {
              fund_name
              fund_id
              shares {
                share_id
            }
          }
          }`,

        },
      })
      this.fundList = resp.data.data.funds.sort('fund_name')
    },

    async getFilterData() {
      const resp = await axios({
        url: '/dr',
        method: 'post',
        data: {
          variables: this.params,
          query: `
          query SlashQuery {
          dataAudit {
          filtersList
          }
          }`,

        },
      })
      this.filtersList = resp.data.data.dataAudit 
    },


    async getBreakdownDetail(share_id, domain, filter) {
      const params = {
        'isinShare': share_id,
        'domain': domain,
        'filter': filter
      }

      const resp = await axios({
        url: '/dr',
        method: 'post',
        data: {
          variables: params,
          query: `
          query Query($isinShare: String!, $domain: Domain!, $filter: String!) {
          dataAudit {
          breakdownDetail(isin_share: $isinShare, domain: $domain, filter: $filter)
          }
          }`,
        },

      })


      this.allocationData = resp.data.data.dataAudit.breakdownDetail.map(item => {
        return {
          date: item.date.split('T')[0],
          fund_id: item.fund_id,
          name: item.name,
          exposure: item.exposure,
          weight_fund: item.weight_fund,
          weight_benchmark: item.weight_benchmark,
          axis: item.axis
        }

      });

      this.loading = false;
      this.fundId = [...new Set(this.allocationData.map(item => item.fund_id))]
      this.axisOptions = Object.keys(this.allocationData[0].axis)
      this.length = Object.values(resp.data.data.dataAudit.breakdownDetail).length

      return resp.data
    },

    async getFilterDetails(filter) {
      const params = {
        'filter': filter
      }

      const resp = await axios({
        url: '/dr',
        method: 'post',
        data: {
          variables: params,
          query: `
          query Query($filter: String) {
          dataAudit {
          filterDetail(filter: $filter)
          }
          }`,

        },
      })

      this.filterDetails = resp.data.data.dataAudit.filterDetail

    },



    async submitForm() {
      this.loading = true;
      if (!this.form.selectedFund || !this.form.selectedShare || !this.form.domain || !this.form.asof) {
        this.message = "Field Missing";
      } else {
        const share_id = this.form.selectedShare.share_id
        const domain = this.form.domain;
        const filter = this.form.selectedFilter;
        const filterDetails = this.form.selectedFilter;
        await this.getBreakdownDetail(share_id, domain, filter);
        await this.getFilterDetails(filterDetails);
      }
      this.selectedFundId = this.fundId[0];
      this.selectedAxis = this.axisOptions[0];

    },


    async downloadData() {
      this.filteredAllocationData.dlXLS('data.xlsx');
    }

  }

}


</script>
