/* globals window, $root, config */
import { GraphQlService } from './GraphQlService'

export class DataReportService extends GraphQlService{
  async list() {
    return await (await fetch(`${config.graphqlEndpoint}/dr?token=${$root.profile.idToken}`)).json()
  }
  async run(name, variables) {
    const toQuery = (obj) => Object.entries(obj || {}).reduce((a, [k, v]) => `${a}${a ? "&" : "?"}${k}=${encodeURI(v)}`, "")
    return await (await fetch(`${config.graphqlEndpoint}/dr/${name}/run${toQuery({ ...variables, token: $root.profile.idToken, postprocess: true })}`)).json()
  }
  async create({ name, query, variables }) {
    return (await this.query(`
      mutation create($name: String!, $query: String!, $variables: JSON) {
        addDataReportDefinition(name: $name, query: $query, variables: $variables) {
          id
        }
      }`, { name, query, variables })).data.addDataReportDefinition
  }
  async update(id, { name, query, variables }) {
    if (!variables) variables = {}
    return (await this.query(`
      mutation update($id: Int, $name: String, $query: String, $description: String, $variables: JSON) {
        updateDataReportDefinition(id: $id, name: $name, query: $query, description: $description, variables: $variables) {
          id
        }
      }`, { id, name, query, variables })).data.updateDataReportDefinition
  }
  async remove(id) {
    return (await this.query(`
      mutation remove($id: Int!) {
        deleteDataReportDefinition(id: $id) {
          id
        }
      }`, { id })).data.deleteDataReportDefinition
  }
}

const dataReportService = new DataReportService()
export default dataReportService
