<style scoped></style>

<template lang="pug">
h1 Templates
.block.expand
  spreadsheet.stripped.expand.nosort(:data="templates" :options="{ columns: ['name', 'actions'], editable: false }")
    template(v-slot:cell-name="{ column, line }")
      input(:value="line" @change="rename(line, $event.target.value)")
    template(v-slot:cell-actions="{ column, line }")
      .row
        button.ghost(tt="Edit" @click="edit(line)")
          svg-icon(name="pt-icon-edit")
        button.ghost(tt="Duplicate" @click="duplicate(line)")
          svg-icon(name="ic_file_copy")
        button.ghost(tt="Delete" @click="remove(line)")
          svg-icon(name="pt-icon-trash")
  .row
    button.primary(@click="create") Create template
</template>

<script>
export default {
  icon: "ic_picture_as_pdf",
}
</script>

<script setup>
import { ref, onMounted } from "vue"
import templateService from "../../../services/TemplateService"

const templates = ref([])
const rename = async (name, newName) => {
  console.log(name, newName)
  // await templateService.rename(path, name)
  // templates.value = await templateService.list()
}
const create = async () => {
  const newName = prompt("Enter template name", new Date().format("YYYY-MM-DD hh:mm"))
  if (templates.value.find(v => v === newName)) return alert("Template already exists")
  const defaultTemplate = config.defaultTemplate || {
    theme: {
      prefix: "grk",
      page_size: "A4",
      page_orientation: "portrait",
      spacing: 3,
      primary: "#069f86",
      secondary: "#444444",
      font: "MarkPro",
      text_size: 2.5,
      title_size: 4,
      style: "",
    },
    pages: [{ lines: [{ component: "block" }, { component: "block" }, { component: "block" }] }],
  }
  await templateService.create(newName, defaultTemplate)
  edit(newName)
}
const edit = (name) => {
  $root.$router.push(`/builder/?layout=${name}`)
}
const duplicate = async (name) => {
  const template = await templateService.get(name)
  const newName = prompt("Enter template name", `${name.replace(/ \d\d\d\d-\d\d-\d\d \d\d:\d\d/, "")} ${new Date().format("YYYY-MM-DD hh:mm")}`)
  await templateService.create(newName, template.layout)
  edit(newName)
}
const remove = async (name) => {
  await templateService.removeAll(name)
  templates.value = await templateService.list()
}
onMounted(async () => {
  templates.value = await templateService.list()
})
</script>
