import { GraphQlService } from './GraphQlService'

export class TemplateService extends GraphQlService{
  async list() {
    return (await this.query(`
      query Template {
        builder {
          templatesNames
        }
      }`))
      .data.builder.templatesNames
  }
  async get(name) {
    return (await this.query(`
      query Template($name: String!) {
        builder {
          template(name: $name) {
            createdAt
            id
            layout
            name
            user
          }
        }
      }`, { name }))
      .data.builder.template
  }
  async all(name) {
    return (await this.query(`
      query TemplateVersions($name: String) {
        builder {
          templates(name: $name) {
            createdAt
            id
            layout
            name
            user
          }
        }
      }`, { name }))
      .data.builder.templates
  }
  async create(name, layout) {
    return (await this.query(`
      mutation AddBuilderTemplate($name: String!, $layout: JSON!) {
        addBuilderTemplate(name: $name, layout: $layout) {
          createdAt
          id
          layout
          name
          user
        }
      }`, { name, layout }))
      .data.addBuilderTemplate
  }
  async remove(id) {
    return (await this.query(`
      mutation RemoveBuilderTemplate($id: Int!) {
        removeBuilderTemplate(id: $id)
      }`, { id }))
  }
  async removeAll(name) {
    return (await this.query(`
      mutation RemoveBuilderTemplatesByName($name: String!) {
        removeBuilderTemplatesByName(name: $name)
      }`, { name }))
  }
}

const templateService = new TemplateService()
export default templateService
