import {
  IGridCell,
  IGridItemMetadata,
  IGridNode,
  IGridState,
  IIconTheme,
  INxGridItemProps,
  IThemeClass,
  IThemeConfig,
  IThemeFunction,
  ITypeThemeBase,
} from '@hauru/common'
import { extend } from '../nx-theme/nx-theme'

export interface IGridCheckbox {
  idle?: IThemeClass<INxGridItemProps>
  checked?: IThemeClass<INxGridItemProps>
  anyChecked?: IThemeClass<INxGridItemProps>
}

export interface IGridItemTheme {
  container?: IThemeClass<INxGridItemProps>
  radius?: IThemeFunction<INxGridItemProps, number | number[]>
  buttons?: {
    container?: IThemeClass<INxGridItemProps>
    fader?: IThemeClass<INxGridItemProps>
    index?: IThemeClass<INxGridItemProps>
    checkbox?: IGridCheckbox
  }
}

type INumberOrFuntion = number | ((node: IGridNode) => number)

export interface IGridTheme extends ITypeThemeBase {
  container?: IThemeClass
  freezed?: {
    initial: IThemeClass
    scrolled: IThemeClass
  }
  cell?: {
    container?: IThemeClass<INxGridItemProps & IGridCell>
    content?: IThemeClass<INxGridItemProps & IGridCell>
  }
  node?: IGridItemTheme & {
    /**
     * The height of each node header in px (collapsed or not)
     */
    nodeHeaderHeight?: number
    /**
     * The gap between rows in px
     */
    rowGap?: number
    /**
     * The gap between nodes in px
     */
    nodeGap?: number
    /**
     * The inner offset of each node in px
     * Either a number or an array of 4 numbers (top, right, bottom, left)
     */
    nodeOffset?:
      | number
      | [INumberOrFuntion, INumberOrFuntion, INumberOrFuntion, INumberOrFuntion]
      | ((node: IGridNode) => number | [INumberOrFuntion, INumberOrFuntion, INumberOrFuntion, INumberOrFuntion])
    /**
     * The inner offset of the grid container in px
     * Either a number or an array of 4 numbers (top, right, bottom, left)
     */
    containerOffset?: number | [number, number, number, number]
  }
  row?: IGridItemTheme & {
    /**
     * The height of each row in px
     */
    rowHeight?: number
    rowLeftOffset?: IThemeFunction<
      {
        state: IGridState
        level: number
        parentNode: IGridNode | null
        metadataNode: IGridItemMetadata | null
      },
      number
    >
  }
  resizer?: {
    container?: IThemeClass
    content?: IThemeClass
  }
  actions?: {
    find?: {
      placeholder: string
      idle: IThemeClass
      active: IThemeClass
    }
  }
  header?: {
    checkbox?: IGridCheckbox
    container?: IThemeClass
    column?: {
      container?: IThemeClass
      content?: IThemeClass
      idle?: IThemeClass
      selected?: IThemeClass
      sorted?: IThemeClass
      sortOrder?: IThemeClass
    }
    dropdown?: {
      container?: IThemeClass
      content?: IThemeClass
    }
  }
  icons: {
    sortAscending: IIconTheme
    sortDescending: IIconTheme
    lineDensitySmall: IIconTheme
    lineDensityLarge: IIconTheme
    textSmall: IIconTheme
    textMedium: IIconTheme
    textLarge: IIconTheme
    columnSortAscending: IIconTheme
    columnSortDescending: IIconTheme
    columnExpand: IIconTheme
    columnCollapse: IIconTheme
    columnGroup: IIconTheme
    columnUngroup: IIconTheme
    columnFreeze: IIconTheme
    columnUnfreeze: IIconTheme
    columnFreezeUpTo: IIconTheme
    columnUnfreezeUpTo: IIconTheme
    columnHide: IIconTheme
  }
  labels: {
    hideColumns: string
    hiddenColumn: string
    hiddenColumns: string
    group: string
    groups: string
    sort: string
    sorts: string
    columnSortAscending: string
    columnSortDescending: string
    columnGroup: string
    columnUngroup: string
    columnFreeze: string
    columnUnfreeze: string
    columnFreezeUpTo: string
    columnUnfreezeUpTo: string
    columnHide: string
  }
  selected: {
    checkbox?: IGridCheckbox
    container: {
      widthOffset: number
      heightOffset: number
      cell?: IThemeClass
    }
    cell?: IThemeClass
    range?: IThemeClass
    handle?: IThemeClass
    row?: IThemeClass
    node?: IThemeClass
  }
  plot: {
    container?: IThemeClass
  }
}

export const gridZIndex = {
  content: 'z-10',
  surround: 'z-20',
  aboveSurround: 'z-30',
  freezed: 'z-[40]',
  header: 'z-[50]',
  resizer: 'z-[60]',
  actions: 'z-[100]',
}

export const gridTheme: IThemeConfig = {
  themes: {
    $default: {
      grid: {
        basic: {
          node: {
            nodeHeaderHeight: 18,
            rowGap: 0,
            nodeGap: 6,
            nodeOffset: 16,
            containerOffset: 16,
          },
          row: {
            rowLeftOffset: 32,
          },
          labels: {
            hideColumns: 'Hide columns',
            hiddenColumn: 'Hidden column',
            hiddenColumns: 'Hidden columns',
            group: 'Group',
            groups: 'Groups',
            sort: 'Sort',
            sorts: 'Sorts',
            columnSortAscending: 'Add to sorted columns A → Z',
            columnSortDescending: 'Add to sorted columns Z → A',
            columnGroup: 'Group by this column',
            columnUngroup: "Don't group by this column",
            columnFreeze: 'Freeze this column',
            columnUnfreeze: 'Unfreeze this column',
            columnFreezeUpTo: 'Freeze up to this column',
            columnUnfreezeUpTo: 'Unfreeze up to this column',
            columnHide: 'Hide column',
          },
        },
      },
      dropdown: {
        grid: {
          basic: {},
          actions: {
            basic: {
              $extends: '../basic',
            },
            hide: {
              $extends: './basic',
            },
            group: {
              $extends: './basic',
            },
            sort: {
              $extends: './basic',
            },
            filter: {
              $extends: './basic',
            },
            pivot: {
              $extends: './basic',
            },
            transpose: {
              $extends: './basic',
            },
            plot: {
              $extends: './basic',
            },
            density: {
              $extends: './basic',
            },
          },
          header: {
            $extends: './basic',
          },
        },
      },
      button: {
        grid: {
          actions: {
            basic: {},
            hide: {
              $extends: './basic',
            },
            group: {
              $extends: './basic',
            },
            sort: {
              $extends: './basic',
            },
            filter: {
              $extends: './basic',
            },
            pivot: {
              $extends: './basic',
              label: 'Pivot',
            },
            transpose: {
              $extends: './basic',
              label: 'Transpose',
            },
            plot: {
              $extends: './basic',
              label: 'Chart',
            },
            density: {
              $extends: './basic',
            },
          },
          subactions: {
            basic: {},
            hideAll: {
              $extends: './basic',
              label: 'Hide all',
            },
            showAll: {
              $extends: './basic',
              label: 'Show all',
            },
            collapseAll: {
              $extends: './basic',
              label: 'Collapse all',
            },
            expandAll: {
              $extends: './basic',
              label: 'Expand all',
            },
            expandToLevel: {
              $extends: './basic',
            },
            removeGroups: {
              $extends: './basic',
              label: 'Remove all groups',
            },
            removeSorts: {
              $extends: './basic',
              label: 'Remove all sorts',
            },
            emptyShowAll: {
              $extends: './basic',
              label: 'Show all columns',
            },
            emptyClearSearch: {
              $extends: './basic',
              label: 'Clear search',
            },
          },
          header: {},
        },
      },
    },
    nx: {
      grid: {
        basic: {
          container: 'select-none',
          header: {
            checkbox: {
              $extends: '../selected/checkbox',
              idle: extend('group-hover:opacity-100 my-auto mr-4'),
            },
            container: 'bg-white',
            column: {
              container: 'cursor-pointer select-none !overflow-visible truncate',
              idle: 'font-semibold pl-2 pr-1 py-1 border-gray-300 bg-gray-50 hover:bg-gray-100',
              selected: '!bg-gray-100',
              sorted: 'pr-4',
              content: 'truncate',
              sortOrder: 'text-xs text-gray-500 h-5 items-center absolute right-[25px] flex p-[2px] pr-1 rounded-full',
            },
            dropdown: {
              container: 'max-h-80 overflow-hidden',
              content: 'gap-2 py-1',
            },
          },
          actions: {
            find: {
              placeholder: 'Search',
              idle: 'h-[28px] w-[28px] min-w-[100px] focus:w-40',
              active: '!w-40',
            },
          },
          freezed: {
            initial: 'w-px bg-gray-400/80',
            scrolled: 'shadow-[4px_0_theme(colors.gray.500/15%)]',
          },
          cell: {
            container: [
              'flex h-full flex-shrink-0 flex-grow-0 items-center [contain:strict] bg-inherit relative',
              gridZIndex.content,
            ],
            content: 'px-2 truncate',
          },
          node: {
            container: 'group/node',
            buttons: {
              fader: '-mr-1 h-full w-1 shrink-0 grow-0 bg-gradient-to-r',
              checkbox: {
                $extends: '../../selected/checkbox',
                idle: extend('group-hover/node:opacity-100'),
                anyChecked: '!opacity-100',
              },
            },
            nodeOffset: [18, 6, 6, 6],
            containerOffset: [0, 0, 0, 0],
          },
          row: {
            container: 'group/row',
            buttons: {
              index:
                'text-gray-500 text-xs font-medium flex-shrink-0 flex-grow-0 ml-2 group-hover/row:opacity-0 opacity-100 min-w-[18px]',
              checkbox: {
                $extends: '../../selected/checkbox',
                idle: extend('absolute left-2 group-hover/row:opacity-100'),
                anyChecked: '!opacity-100',
              },
            },
          },
          resizer: {
            container: [
              'group/resizer absolute flex content-center w-[9px] top-0 bottom-0 -right-[3px] translate-x-[1px] cursor-ew-resize',
              gridZIndex.resizer,
            ],
            content: 'group-hover/resizer:opacity-100 opacity-0 bg-blue-600 w-[3px] mt-1 mb-[3px] m-auto rounded-full',
          },
          selected: {
            checkbox: {
              idle: 'opacity-0 vsg-checkbox vsg-unset w-4 h-4 text-blue-600 bg-white border border-solid border-blue-300 rounded shrink-0',
              anyChecked: '!opacity-100',
            },
            container: {
              widthOffset: 1,
              heightOffset: 1,
              cell: 'border-2 border-solid border-blue-700 -mt-px -ml-px',
            },
            cell: '',
            range: '!bg-blue-50',
            row: '!bg-blue-50 border-blue-300',
            node: '!bg-blue-50 border-blue-300',
            handle:
              'absolute -bottom-[6px] -right-[6px] cursor-crosshair border border-solid border-white w-[10px] h-[10px] bg-blue-700 rounded-full',
          },
          plot: {
            container: 'flex-1 bg-white p-4',
          },
          icons: {
            sortAscending: {
              i: 'mdi-sort-descending',
              class: 'h-4 w-5 text-gray-500',
            },
            sortDescending: {
              i: 'mdi-sort-ascending',
              class: 'h-4 w-5 text-gray-500',
            },
            lineDensitySmall: {
              i: 'ic-round-density-small',
              class: 'h-4 w-5',
            },
            lineDensityLarge: {
              i: 'ic-round-density-medium',
              class: 'h-4 w-5',
            },
            textSmall: {
              i: 'fe-text-size',
              class: 'h-3 w-5',
            },
            textMedium: {
              i: 'fe-text-size',
              class: 'h-4 w-5',
            },
            textLarge: {
              i: 'fe-text-size',
              class: 'h-5 w-5',
            },
            columnSortAscending: {
              i: 'mdi-sort-descending',
              class: 'h-4 w-5',
            },
            columnSortDescending: {
              i: 'mdi-sort-ascending',
              class: 'h-4 w-5',
            },
            columnExpand: {
              i: 'ic-baseline-add',
              class: 'h-5 w-5',
            },
            columnCollapse: {
              i: 'ic-baseline-remove',
              class: 'h-5 w-5',
            },
            columnGroup: {
              i: 'mdi-list-box-outline',
              class: 'h-4 w-5',
            },
            columnUngroup: {
              i: 'mdi-list-box-outline',
              class: 'h-4 w-5',
            },
            columnFreeze: {
              i: 'mdi-table-star',
              class: 'h-4 w-5',
            },
            columnUnfreeze: {
              i: 'mdi-table-star',
              class: 'h-4 w-5',
            },
            columnFreezeUpTo: {
              i: 'mdi-table-star',
              class: 'h-4 w-5',
            },
            columnUnfreezeUpTo: {
              i: 'mdi-table-star',
              class: 'h-4 w-5',
            },
            columnHide: {
              i: 'mdi-eye-off-outline',
              class: 'h-4 w-5',
            },
          },
        },
      },
      dropdown: {
        grid: {
          basic: {
            theme: 'dropdown',
            placement: 'bottom-start',
            triggers: ['click'],
            delay: 0,
            handleResize: true,
            autoHide: true,
            popperClass:
              'h-fit divide-y divide-gray-100 overflow-auto rounded-md bg-white shadow-lg ring-1 ring-black/5 overflow-hidden',
          },
          actions: {
            basic: {
              $extends: '../basic',
              distance: 3,
              skidding: -6,
              popperClass: extend('shrink-0 w-56'),
              contentClass: 'max-h-80',
              mainContentClass: 'shrink-1 flex grow-0 basis-auto flex-col overflow-auto py-1',
              subHeadingClass: 'text-xs text-gray-700',
            },
            density: {
              mainContentClass: extend('gap-2'),
            },
            pivot: {
              $extends: './basic',
              popperHideTriggers: triggers => [...triggers, 'click'],
            },
          },
          header: {
            $extends: './basic',
            distance: 0,
            skidding: 0,
            popperClass: extend('w-[264px]'),
            popperHideTriggers: triggers => [...triggers, 'click'],
            instantMove: true,
          },
        },
      },
      button: {
        grid: {
          actions: {
            basic: {
              size: 'md',
              idle: '!py-1 font-medium text-gray-700 border-2 border-solid border-white hover:bg-emerald-100 hover:border-emerald-100',
              active: 'bg-emerald-100 hover:bg-emerald-100 !border-emerald-100 hover:!border-emerald-200',
              selected: 'bg-emerald-200 hover:bg-emerald-200 !border-emerald-200 hover:!border-emerald-200',
            },
            hide: {
              icon: 'ic-round-visibility-off',
              iconClass: 'h-4 w-5 !transform-none',
            },
            group: {
              icon: 'mdi-list-box-outline',
              iconClass: 'h-[18px] w-5 !transform-none',
            },
            sort: {
              icon: 'mdi-sort',
              iconClass: 'h-4 w-5 !transform-none',
            },
            filter: {
              icon: 'ic-outline-filter-list',
              iconClass: 'h-[18px] w-5 !-scale-x-100',
            },
            pivot: {
              icon: 'ic-outline-pivot-table-chart',
              iconClass: 'h-4 w-5 !-scale-x-100',
            },
            transpose: {
              icon: 'mdi-table-pivot',
              iconClass: 'h-4 w-5 !-scale-x-100',
            },
            plot: {
              icon: 'ic-outline-bar-chart',
              iconClass: 'h-[18px] w-5 !-scale-x-100',
            },
            density: {
              icon: 'ic-baseline-format-line-spacing',
              iconClass: 'h-4 w-5 !-scale-x-100',
            },
            rows: {
              $extends: './basic',
              icon: 'mdi-table-row',
              iconClass: 'h-4 w-5',
            },
            columns: {
              $extends: './basic',
              icon: 'mdi-table-column',
              iconClass: 'h-4 w-5',
            },
            aggregates: {
              $extends: './basic',
              icon: 'mdi-function-variant',
              iconClass: 'h-4 w-5',
            },
            swap: {
              $extends: './basic',
              icon: 'ic-baseline-swap-horiz',
              iconClass: 'h-4 w-5',
            },
          },
          subactions: {
            basic: {
              size: 'sm',
              idle: 'grow !py-1 font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 active:bg-gray-300',
              disabled: 'cursor-default !bg-gray-50 !text-gray-400 hover:!bg-gray-50',
            },
            expandToLevel: {
              size: 'xs',
              idle: extend('!grow-0'),
            },
            emptyShowAll: {
              idle: extend('!grow-0'),
            },
            emptyClearSearch: {
              idle: extend('!grow-0'),
            },
          },
          header: {
            action: {
              size: 'xs',
              idle: 'ml-auto !py-1 transition-colors hidden font-medium text-gray-700 bg-gray-50 group-hover:bg-gray-100 group-hover:!flex group-hover:hover:bg-gray-200 group-hover:active:bg-gray-300 ring-white',
              selected: '!bg-gray-200 !flex group-hover:hover:ring-2',
              iconClass: 'h-4 w-5',
            },
            toggle: {
              size: 'xs',
              idle: '-ml-1 mr-1 !py-1 transition-colors font-medium text-gray-700 bg-gray-50 group-hover:bg-gray-100 group-hover:hover:bg-gray-200 group-hover:active:bg-gray-300',
              iconClass: 'h-4 w-5',
            },
          },
        },
      },
    },
  },
}
