<template>
  <!-- grid -->
  <div
    nx-grid
    :nx-theme="props.type ?? props.theme"
    class="relative flex w-full flex-col overflow-hidden bg-white"
    :ref="state.setRef"
  >
    <!-- actionbar -->
    <slot name="#actionbar" :tree-grid-props="props">
      <nx-grid-actionbar class="w-full p-1" :state="props.state" :actions="props.actions" />
    </slot>
    <!-- empty state -->
    <div qa-grid-no-results class="flex h-full w-full flex-col items-center justify-center" v-if="!state?.data?.length">
      <div class="flex flex-col items-center">
        <nx-dynamic-icon i="mdi-table-search" class="!h-16 !w-16 text-gray-300" />
        <slot name="empty-message">
          <div class="py-4 text-center text-sm leading-6 text-gray-600">
            No {{ state.find ? ` results found for '${state.find}'` : 'data are currently available' }}.
          </div>
        </slot>
        <nx-button
          qa-grid-reset-search
          class="pointer-events-auto"
          theme="grid.subactions.emptyClearSearch"
          @click="state.setFind('')"
          v-if="state.find"
        />
      </div>
    </div>
    <div
      qa-grid-no-columns
      class="flex h-full w-full flex-col items-center justify-center"
      v-else-if="hiddenColumnsCount === state?.columns?.headers?.list?.length"
    >
      <div class="flex flex-col items-center">
        <nx-dynamic-icon i="mdi-table-eye-off" class="!h-16 !w-16 text-gray-300" />
        <div class="py-4 text-center text-sm leading-6 text-gray-600">
          All columns are currently hidden. <br />
          Select some columns to show.
        </div>
        <nx-button
          qa-grid-show-all-columns
          class="pointer-events-auto"
          theme="grid.subactions.emptyShowAll"
          @click="state?.columns.showAll()"
        />
      </div>
    </div>
    <!-- plot content -->
    <div :class="gridTheme?.plot?.container" v-else-if="state.display === 'plot'">
      <slot name="#plot">
        <nx-plot :state="props.state" />
      </slot>
    </div>
    <!-- table content : header + rows -->
    <nx-scroll-area
      v-else
      class="relative flex w-full grow flex-col bg-inherit"
      :class="gridTheme?.container"
      :state="props.state.scroll"
      @keydown="props.state.onKeydown"
    >
      <!-- applies background border for freezed columns -->
      <div
        v-if="props.state.columns.visibleFreezed.length > 0"
        class="absolute bottom-[10px] left-0 top-0 bg-inherit"
        :class="[props.state.scroll.throttled.left > 0 ? gridTheme?.freezed?.scrolled : '']"
        :style="{
          width: '20px',
          transform: `translateX(${
            props.state.columns.visibleFreezedWidth.value + props.state.nodes.maxLeftOffset - 20
          }px)`,
        }"
      ></div>
      <slot
        name="grid-header"
        :scroll-left="props.state.scroll.throttled.left"
        :state="props.state"
        v-if="state.display !== 'transpose'"
      >
        <nx-grid-header class="w-full" :state="props.state" :theme="props.theme" :type="props.type">
          <template v-if="$slots.header" #header="{ header }">
            <slot name="header" :header="header" />
          </template>
        </nx-grid-header>
      </slot>
      <!-- table content container -->
      <div
        qa-grid-content
        class="relative flex w-full grow translate-x-0 flex-col overflow-hidden"
        :ref="props.state.container.setRef"
        :style="{ fontSize: props.state.fontSize + 'px' }"
      >
        <!-- This v-for is on maxViewsArray instead of views directly, as a performance optimisation -->
        <!-- Branching views direclty flags all divs for style recalculation -->
        <nx-grid-item
          v-for="view of maxViewsArray"
          :key="view"
          :theme="props.theme"
          :type="props.type"
          :state="props.state"
          :view="props.state.views[view]"
          :style="{
            transform: `translateY(${props.state.views[view].node.top - props.state.scroll.throttled.top}px)`,
            opacity: props.state.views[view]?.show ?? true ? 1 : 0,
            contentVisibility: props.state.views[view]?.show ?? true ? 'visible' : 'hidden',
          }"
        >
          <template v-if="$slots.cell" #cell="{ cell, header, row, freezed, isItemHover }">
            <slot
              name="cell"
              :cell="cell"
              :header="header"
              :row="row"
              :freezed="freezed"
              :is-item-hover="isItemHover"
              :view="props.state.views[view]"
            />
          </template>
          <template v-if="$slots['buttons-left']" #buttons-left="{ item, isItemHover }">
            <slot name="buttons-left" :item="item" :is-item-hover="isItemHover" />
          </template>
          <template
            v-if="$slots['component-left']"
            #component-left="{ item, isItemHover, evaluatedThemeGridItem, handleItemSelect }"
          >
            <slot
              name="component-left"
              :item="item"
              :is-item-hover="isItemHover"
              :evaluatedThemeGridItem="evaluatedThemeGridItem"
              :handleItemSelect="handleItemSelect"
            />
          </template>
          <template v-if="$slots['buttons-right']" #buttons-right="{ item, isItemHover }">
            <slot name="buttons-right" :item="item" :is-item-hover="isItemHover" />
          </template>
        </nx-grid-item>
        <div class="pointer-events-none absolute inset-0" :class="gridZIndex.freezed">
          <!-- applies background border for freezed columns -->
          <div
            v-if="props.state.columns.visibleFreezed.length > 0"
            class="absolute h-full"
            :class="gridTheme?.freezed?.initial"
            :style="{
              transform: `translateX(${
                props.state.columns.visibleFreezedWidth.value + props.state.nodes.maxLeftOffset - 1
              }px)`,
            }"
          ></div>
          <!-- container that hides selected cell behind freezed cells -->
          <div
            class="absolute bottom-0 right-0 top-0 overflow-hidden"
            :style="{
              left: !selectedCell?.isFreezed
                ? props.state.columns.visibleFreezedWidth.value + props.state.nodes.maxLeftOffset + 'px'
                : 0,
            }"
          >
            <!-- highlights selected cell -->
            <div
              v-if="selectedCell?.columnHeader && selectedCell?.rowView && props.state.selection.enable.cells"
              qa-grid-selected-cell
              class="absolute left-0 top-0 h-full"
              :class="[gridZIndex.surround, gridTheme?.selected.container.cell]"
              :style="{
                width: (selectedCell.columnHeader.width ?? 0) + (gridTheme?.selected.container.widthOffset ?? 0) + 'px',
                height: props.state.nodes.rowHeight + (gridTheme?.selected.container.heightOffset ?? 0) + 'px',
                transform: `translate(${
                  selectedCell.offset +
                  props.state.nodes.maxLeftOffset +
                  (selectedCell.isFreezed
                    ? 0
                    : -(
                        props.state.scroll.throttled.left +
                        props.state.columns.visibleFreezedWidth.value +
                        props.state.nodes.maxLeftOffset
                      ))
                }px, ${selectedCell.rowView.node.top - props.state.scroll.throttled.top + 1}px)`,
              }"
            >
              <!-- selected cell handler -->
              <div
                v-if="isNil(props.state.selection.range.toColumn) && isNil(props.state.selection.range.toRow)"
                class="pointer-events-auto"
                :class="gridTheme?.selected.handle"
                @pointerdown="rangeSelectStart"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </nx-scroll-area>
  </div>
</template>

<script setup lang="ts">
/**
 * @component Dynamic and customizable grid view for displaying data in a tree structure,
 * with features like expanding and collapsing nodes, and columns that adjust to fit their content.
 */
import { computed, onMounted, reactive, ref, watch } from 'vue'
import {
  IGridNode,
  IGridState,
  IGridView,
  watchImmediate,
  useTheme,
  ITreeGridHeaderActions,
  gridZIndex,
  useMove,
  IMoveEvent,
} from '@hauru/common'
import { isNil } from 'lodash'

interface IProps {
  /**
   * Allows to manually bypass the theme set as default, among the themes provided by the theme config
   */
  theme?: string
  /**
   * The type of the grid among the types defined in the theme config
   */
  type?: string
  state: IGridState
  /**
   * Actions that we want to present in the action toolbar in their order
   */
  actions?: ITreeGridHeaderActions[]
}

const props = defineProps<IProps>()

const emit = defineEmits<{
  (e: 'copy'): void
}>()

const DEBUG = false

const themeConfig = useTheme()
const gridTheme = themeConfig.computedThemeType('grid', props)
props.state.setGridTheme(themeConfig)

const moveRange = useMove({
  onMove: rangeSelectMove,
  onMoveEnd: () => props.state.selection.selectRangeEnd(),
  setTargetElement: () =>
    document.querySelector(
      `[nx-cell-column="${props.state.selection.range.fromColumn}"][nx-cell-row="${props.state.selection.range.fromRow}"]`,
    ),
})

onMounted(() => {
  props.state.setEmit(emit)
})

function rangeSelectMove({ event }: IMoveEvent) {
  props.state.selection.selectRange(event)
}

function rangeSelectStart(event: PointerEvent) {
  moveRange.moveStart(event, props.state.isProcessing)
  const node = props.state.views.find(
    v => v.rowIndex! + v.node.index_first_row === props.state.selection.range.fromRow,
  )?.node
  props.state.selection.selectRangeStart(event, node!, props.state.selection.range.fromColumn!, true)
}

const hiddenColumnsCount = computed(() => {
  return (
    (props.state?.columns?.headers?.list?.length ?? 0) -
    (props.state?.columns?.headers?.list?.filter(h => h.show).length ?? 0)
  )
})

const selectedCell = computed(() => {
  if (props.state.selection.range.fromRow !== null && props.state.selection.range.fromColumn !== null) {
    return {
      columnHeader: props.state.columns.visibleAll[props.state.selection.range.fromColumn],
      rowView: props.state.views.find(v => {
        return !isNil(v.rowIndex) && v.node.index_first_row + v.rowIndex === props.state.selection.range.fromRow
      }),
      offset: props.state.columns.visibleAll
        .slice(0, props.state.selection.range.fromColumn)
        .reduce((acc, c) => acc + c.width, 0),
      isFreezed: props.state.selection.range.fromColumn < props.state.columns.visibleFreezed.length,
    }
  }
  return undefined
})

const maxViews = ref(0)
const maxViewsArray = reactive<number[]>([])
const forceRecalculateView = ref(0)
watchImmediate([maxViews], () => {
  if (DEBUG) console.log('recalculate maxViewsArray')
  maxViewsArray.length = 0
  for (let i = 0; i < maxViews.value; i++) {
    maxViewsArray.push(i)
  }
  forceRecalculateView.value++
})

watch(
  () => props.state.container.height,
  () => {
    if (DEBUG) console.log('maxViews changed')
    maxViews.value = 0
  },
)
watchImmediate(
  [
    () => props.state.scroll.throttled.top,
    forceRecalculateView,
    props.state.nodes.collapsed.list,
    () => props.state.nodes.root.height,
    () => props.state.container.height,
    () => props.state.nodes.rowHeight,
    () => props.state.gridTheme?.node?.rowGap,
  ],
  () => {
    calculateViews()
  },
)

function calculateViews(force = false) {
  if (DEBUG) console.log('calculateViews, force =', force)
  const currentTop = Math.max(props.state.scroll.throttled.top, 0)
  const takenItemIndex = new Set<number>()
  const newViews: IGridView[] = []

  for (const [node, rowIndex] of props.state.nodes.yieldVisibleRange(currentTop, props.state.container.height + 50)) {
    const rowId = rowIndex || rowIndex === 0 ? node?.rows?.[rowIndex] : undefined
    const itemIndex = rowId || rowId === 0 ? node.index_item_first_row + rowIndex! : node.index_item

    if (takenItemIndex.has(itemIndex)) {
      // TODO: FIX THE BUG THAT PRODUCES DUPLICATES
      if (DEBUG) console.log('DUPLICATE INDEX', itemIndex)
      continue
    }
    takenItemIndex.add(itemIndex)

    const newView = {
      dataId: rowId ?? node?.id,
      show: true,
      rowIndex,
      node:
        rowId || rowId === 0
          ? ({
              ...node,
              id: rowId,
              index_item: node.index_item_first_row + rowIndex!,
              type: 'row',
              is_expanded: true,
              height: props.state.nodes.rowHeight,
              left: node.left + props.state.nodes.getNodeOffsetLeft(node),
              right: node.right + props.state.nodes.getNodeOffsetRight(node),
              top:
                node.top +
                props.state.nodes.getNodeOffsetTop(node) +
                (props.state.nodes.rowHeight + (props.state.gridTheme?.node?.rowGap ?? 0)) * rowIndex!,
              parent: node,
            } as IGridNode)
          : node,
      id: itemIndex,
    }

    newViews.push(newView)
  }

  maxViews.value = Math.max(maxViews.value, newViews.length)

  const takenViewIds = new Set<number>()
  let lastId = 0

  function keepNumberInCircle(n: number, max: number) {
    return ((n % max) + max) % max
  }

  function nextId(start = lastId) {
    let findId = keepNumberInCircle(start, maxViews.value)
    while (takenViewIds.has(findId)) {
      findId = keepNumberInCircle(findId - 1, maxViews.value)
    }
    lastId = keepNumberInCircle(findId - 1, maxViews.value)
    takenViewIds.add(findId)
    return findId
  }

  for (const view of newViews) {
    view.id = nextId(view.id)
    // console.log('view.id', view.id)
  }

  const padding = Math.max(maxViews.value - newViews.length, 0)
  for (let i = 0; i < padding; i++) {
    newViews.push({
      dataId: undefined,
      node: {
        id: -100,
        type: 'row',
        is_expanded: true,
        height: 0,
        left: 0,
        right: 0,
        top: 0,
        parent: null,
      } as IGridNode,
      show: false,
      rowIndex: undefined,
      id: nextId(),
    })
  }

  // compare newViews with views by id
  let arraysEqual = true
  if (newViews.length === props.state.views.length) {
    for (let i = 0; i < newViews.length; i++) {
      if (
        newViews[i].node.id !== props.state.views[i].node.id ||
        newViews[i].rowIndex !== props.state.views[i].rowIndex
      ) {
        arraysEqual = false
        break
      }
    }
  } else {
    arraysEqual = false
  }

  if (!arraysEqual || force) props.state.setViews(newViews)
  if (DEBUG && (!arraysEqual || force)) {
    console.log('-----------------------------------------------------------------------------------')
    console.log(
      maxViews.value,
      currentTop,
      props.state.container.height,
      props.state.views,
      props.state.nodes.printable(),
    )
  }
}
</script>

<style scoped>
/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
