import {dbRef} from "../../../features/firebase";


export default function () {
  const runIdRef = dbRef.child('/data/run_id')

  const genRunId = async () => {
    const res = await runIdRef.transaction(id => {
      if(!id) return '10000'
      return `${+id + 1}`
    })
    return +res.snapshot.val()
  }

  return { genRunId }
}
