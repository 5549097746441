<style scoped>
button.primary,
button.delete {
  font-weight: 500;
}
button.delete {
  color: white;
  --active: #cc1c3a;
  --hover: #cc1c3a;
  --input: #cc1c3a;
}
textarea.large {
  height: 300px;
  resize: none;
}
textarea.small {
  height: 100px;
  resize: none;
}
button.icon {
  padding: 12px;
  width: 40px;
  height: 40px;
}
button.icon svg {
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.active {
  background: var(--colors-highlight);
}
</style>

<template lang="pug">
h1 {{ t[$root.screen.path] }}
  autocomplete.right(:data="searchData" :options="{ placeholder: t.search }" :value="$root.filters.filter((v, k) => k !== 'domain').reduce((acc, v, k) => (v && v.map(v => acc.push([k, v].join('.'))), acc), [])" @input="$root.$router.push({ query: Object.assign($root.query.filter((v, k) => !$root.filters.keys().toggle('domain').includes(k)), $event.map(v => v.split('.')).group('0').map(g => g.map('1').join('|'))) })")

.block.business-scope(style="flex-direction: row;flex-wrap: nowrap;gap: 16px;")
  <label :style="{ fund: 'width: 300px;', share: 'width: 160px;' }[variable] || 'width: 120px;'" v-for="variable in ['fund', 'share', 'lang', 'period', 'date']">
    <div>{{ variable.titleize() }}</div>
    <select style="width: 100%;" v-model="variables[variable]">
      <option v-for="option in variablesOptions[variable]?.[variables.fund] || variablesOptions[variable]">{{ option }}</option>
    </select>
  </label>

.block.expand.data-reports
  .row.expand
    .column(style="width: 160px;margin: -16px;margin-right: 0;border-right: var(--border);")
      div(style="flex:1;max-height:60px;display:flex;align-items:center;place-content:center;" :class="{ active: !selected.id }" @click="selected = {}") New
      div(style="flex:1;max-height:60px;display:flex;align-items:center;place-content:center;" :class="{ active: selected === query }" @click="selected = query" v-for="query in queries") {{ query.name }}
    .column.space.expand(style="margin-top: -8px;margin-bottom: -8px")
      .row.nowrap.space(v-if="!selected.id")
        div Explore Impress GraphQL API in <button class="primary" style="font-size: 12px;height: auto;padding: 4px 8px;cursor: pointer;" @click="studioOpen">the studio</button> and save the query below.
      hr(v-if="!selected.id")
      .row.nowrap.space
        label.column Name
          input(v-model="selected.name")
        //label.column(v-if="(variablesQuery || []).length")
          .row(style="gap: 4px;") Variables
            div.link(@click="raw = !raw" style="user-select: none;cursor: pointer;") {{ raw ? "input" : "json" }}
          textarea.small(v-if="raw" :value="JSON.stringify(selected.variables, null, 2)" @input="selected.variables = JSON.parse($event.target.value);refresh()")
          template(v-else)
            label.row.nowrap.center(v-for="k in variablesQuery" :key="k")
              div(style="font-weight: bold;margin-right: 8px;flex: 1;text-align: right") {{ k }}:
              input(v-model="selected.variables[k]" @change="refresh")
      .row.nowrap.space.expand
        label.column.expand(style="min-width: 100%;") 
          .row(style="width: 100%;") Query
            a.button(style="margin-left: auto;font-size: 12px;height: auto;padding: 4px 8px;cursor: pointer;" :href="runLink(selected)" target="_blank" v-if="selected.id") Open in new tab
            div.button.primary(style="margin-left: 8px;font-size: 12px;height: auto;padding: 4px 8px;cursor: pointer;" @click="studioOpen" v-if="selected.id") Open in studio
          textarea.large(:style="'min-width: 100%;' + (!variables ? 'border: 1px solid red;' : '')" v-model="selected.query" @change="refresh")
        //label Result:
          a.link(style="width: calc(100% - 80px);text-align: right;margin-left: 20px;display: inline-block;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;margin-bottom: -5px;" :href="runLink(selected)" target="_blank") {{ runLink(selected, true) }}
          textarea.large(:value="result ? JSON.stringify(result, null, 2) : ''")
      .row.space
        button.primary(@click="saveDr(selected)" :disabled="!variablesQuery || !selected.name || !selected.query") {{ selected.id ? "Save" : "Create" }}
        button.delete(@click="deleteDr(selected)" v-if="selected.id") Delete
</template>

<script>
import dataReportService from "../../../services/DataReportService"
import { parse } from "graphql"

export default {
  icon: "pt-icon-graph",
  data() {
    return {
      loading: true,
      selected: { variables: {} },
      result: null,
      raw: localStorage.raw === "true",
      queries: [],
      variables: {},
      variablesOptions: {},
    }
  },
  async mounted() {
    this.loading = true
    this.queries = (await dataReportService.list()).sort("id")
    this.loading = false
    const { data, errors } = await dataReportService.query(`query {
  businessData {
    funds {
      fundId
      fund_name
      shares {
        shareId
      }
    }
  }
}`)
    const fundData = data.businessData.funds
    this.variablesOptions = {
      fund: fundData.map((fund) => fund.fund_name).sort(),
      share: fundData.reduce((acc, fund) => {
        acc[fund.fund_name] = fund.shares.map((share) => share.shareId).sort()
        return acc
      }, {}),
      lang: ["EN", "FR"],
      period: ["Monthly", "Quarterly", "Yearly"],
      date: Array.from({ length: new Date().getMonth() + 1 }, (_, i) => new Date(new Date().getFullYear(), new Date().getMonth() - i, 1).toISOString().slice(0, 7)),
    }
    this.variables = {
      fund: this.variablesOptions.fund[0],
      share: this.variablesOptions.share[this.variablesOptions.fund[0]][0],
      lang: this.variablesOptions.lang[0],
      period: this.variablesOptions.period[0],
      date: this.variablesOptions.date[0],
    }
  },
  computed: {
    variablesQuery() {
      try {
        const ast = parse(this.selected.query)
        return ast.definitions[0].variableDefinitions.map((v) => v.variable.name.value)
      } catch (e) {
        console.warn(e)
        return null
      }
    },
  },
  watch: {
    "selected.id": "refresh",
    raw() {
      localStorage.raw = this.raw
    },
  },
  methods: {
    async refresh() {
      this.result = null
      this.result = await (await fetch(this.runLink(this.selected))).json()
    },
    runLink({ name, variables } = {}, short = false) {
      if (!name) return
      const toQuery = (obj) => Object.entries(obj || {}).reduce((a, [k, v]) => `${a}${a ? "&" : "?"}${k}=${encodeURI(v)}`, "")
      if (short) return `/${name}/run${toQuery(variables)}`
      const endpoint = "https://lafrancaise.nx.digital/dr" || config.graphqlEndpoint
      return `${endpoint}/dr/${name}/run${toQuery({ ...variables, token: $root.profile.idToken })}`
    },
    studioLink({ query, variables = {} } = {}) {
      variables.isinShare = this.variables.share
      variables.lang = this.variables.lang.toLowerCase()
      variables.period = this.variables.period.toLowerCase()
      variables.domain = this.variables.date
      variables.endDate = this.variables.date
      variables.asof = this.variables.date
      const q =
        query.length > 5000
          ? query
              .replace(/\s+/g, " ")
              .replace(/\s?{\s?/g, "{")
              .replace(/\s?}\s?/g, "}")
              .replace(/\s?:\s?/g, ":")
          : query
      const endpoint = "https://lafrancaise.nx.digital/dr" || config.graphqlEndpoint
      return `https://studio.apollographql.com/sandbox?endpoint=${encodeURI(new URL(endpoint, location.origin).href)}&document=${encodeURI(q)}&variables=${encodeURI(JSON.stringify(variables, null, 2))}&headers=${encodeURI(JSON.stringify({ Authorization: `Bearer ${$root.profile.idToken}` }))}`
    },
    studioOpen() {
      if (this.selected.id) return window.open(this.studioLink(this.selected), "_blank")
      // TODO: make an Example default query
      return window.open(
        this.studioLink({
          query: `query Example(
  $isinShare: String!
  $lang: Lang!
  $period: ReportPeriod
  $domain: Domain!
  $endDate: UTCDate
  $asof: Asof
) {
  __typename
}`,
        }),
        "_blank"
      )
      // return this.queries.forEach((v, i) => setTimeout(() => window.open(this.studioLink(v), "_blank"), (i + 1) * 100))
    },
    async saveDr(query) {
      try {
        if (query.id) return await dataReportService.update(query.id, query)
        const { id } = await dataReportService.create(query)
        const selected = { id, ...query }
        this.queries = this.queries.concat(selected)
        this.selected = selected
      } catch (e) {
        return $root.toast({ description: e.message, type: "error", timeout: 5000 })
      }
    },
    async deleteDr({ id }) {
      if (!confirm($root.t["delete_confirmation"])) return
      try {
        await dataReportService.remove(id)
      } catch (e) {
        $root.toast({ description: e.message, type: "error", timeout: 5000 })
        return
      }
      this.queries = this.queries.filter((v) => v.id !== id)
      this.selected = { variables: {} }
    },
    async copy(url) {
      await navigator.clipboard.writeText(url)
    },
  },
}
</script>
