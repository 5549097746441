import { dbRef, update } from '../../../features/firebase'
import { ref, onMounted, onUnmounted, watch, computed } from 'vue'
import { useRoute } from 'vue-router'
export function useRun() {
  const route = useRoute()
  const rid = ref(route.query.rid)

  let runRef = dbRef.child(`/data/runs/${rid.value}`)
  const run = ref(null)
  const loaded = ref(false)
  const onUpdate = snap => {
    run.value = snap.val() || {}
    loaded.value = true
  }

  watch(
    () => route.query.rid,
    () => {
      runRef.off('value', onUpdate)
      rid.value = route.query.rid
      runRef = dbRef.child(`/data/runs/${rid.value}`)
      runRef.on('value', onUpdate)
    },
  )

  onMounted(() => {
    runRef.on('value', onUpdate)
    selectAction()
  })
  onUnmounted(() => runRef.off('value', onUpdate))

  const deleteRun = async rid => {
    return await update(`data.runs.${rid}`, { disabled: true })
  }

  const logs = computed(() => (run.value && run.value.logs) || {})

  const selectAction = () => {
    if (logs.value.filter(d => d.status === 'queued').length) return uquery({ action_id: logs.value.filter(d => d.status === 'queued').first().action_id })
    if (logs.value.filter(d => d.status === 'running').length) return uquery({ action_id: logs.value.filter(d => d.status === 'running').first().action_id })
    if (logs.value.v().filter().length) return uquery({ action_id: logs.value.v().last().action_id })
  }
  watch(() => logs.value, selectAction)


  const periodComments = computed(() => {
    if (!run.value) return null
    return run.value?.context.period === 'monthly' ? 'comment' : `comment_${run.value.period}`
  })
  return {
    deleteRun,
    run,
    rid,
    logs,
    periodComments,
    loaded,
  }
}
