export default dim => {
  if (dim === 'date_d_echeance') {
    return d => d.first().label
  }

  if (dim === 'rating_emission_non_signe')
    return d =>
      [
        'rating_emission_non_signe_dirr___rating_emission_aaa',
        'rating_emission_non_signe_dirr___rating_emission_aa',
        'rating_emission_non_signe_dirr___rating_emission_a',
        'rating_emission_non_signe_dirr___rating_emission_bbb',
        'rating_emission_non_signe_dirr___rating_emission_bbb',
        'rating_emission_non_signe_dirr___rating_emission_bb',
        'rating_emission_non_signe_dirr___rating_emission_b',
        'rating_emission_non_signe_dirr___rating_emission_ccc',
        'rating_emission_non_signe_dirr___rating_emission_cc',
        'rating_emission_non_signe_dirr___rating_emission_c',
        'rating_emission_non_signe_dirr___rating_emission_ddd',
        'rating_emission_non_signe_dirr___rating_emission_dd',
        'rating_emission_non_signe_dirr___rating_emission_d',
        'rating_emission_non_signe_dirr___rating_emission_nr',
        'NA',
      ].indexOf(d.first().label)

  if (dim === 'rating_emetteur_non_signe')
    return d =>
      [
        'rating_emetteur_non_signe_dirr___rating_emetteur_aaa',
        'rating_emetteur_non_signe_dirr___rating_emetteur_aa',
        'rating_emetteur_non_signe_dirr___rating_emetteur_a',
        'rating_emetteur_non_signe_dirr___rating_emetteur_bbb',
        'rating_emetteur_non_signe_dirr___rating_emetteur_bbb',
        'rating_emetteur_non_signe_dirr___rating_emetteur_bb',
        'rating_emetteur_non_signe_dirr___rating_emetteur_b',
        'rating_emetteur_non_signe_dirr___rating_emetteur_ccc',
        'rating_emetteur_non_signe_dirr___rating_emetteur_cc',
        'rating_emetteur_non_signe_dirr___rating_emetteur_c',
        'rating_emetteur_non_signe_dirr___rating_emetteur_ddd',
        'rating_emetteur_non_signe_dirr___rating_emetteur_dd',
        'rating_emetteur_non_signe_dirr___rating_emetteur_d',
        'rating_emetteur_non_signe_dirr___rating_emetteur_nr',
        'NA',
      ].indexOf(d.first().label)

  if (dim === 'rating_emission_a_defaut_emetteur')
    return d =>
      [
        'rating_emission_a_defaut_emetteur_aaa_plus',
        'rating_emission_a_defaut_emetteur_aaa',
        'rating_emission_a_defaut_emetteur_aaa_moins',
        'rating_emission_a_defaut_emetteur_aa_plus',
        'rating_emission_a_defaut_emetteur_aa',
        'rating_emission_a_defaut_emetteur_aa_moins',
        'rating_emission_a_defaut_emetteur_a_plus',
        'rating_emission_a_defaut_emetteur_a',
        'rating_emission_a_defaut_emetteur_a_moins',
        'rating_emission_a_defaut_emetteur_bbb_plus',
        'rating_emission_a_defaut_emetteur_bbb',
        'rating_emission_a_defaut_emetteur_bbb_moins',
        'rating_emission_a_defaut_emetteur_bb_plus',
        'rating_emission_a_defaut_emetteur_bb',
        'rating_emission_a_defaut_emetteur_bb_moins',
        'rating_emission_a_defaut_emetteur_b_plus',
        'rating_emission_a_defaut_emetteur_b',
        'rating_emission_a_defaut_emetteur_b_moins',
        'rating_emission_a_defaut_emetteur_ccc_plus',
        'rating_emission_a_defaut_emetteur_ccc',
        'rating_emission_a_defaut_emetteur_ccc_moins',
        'rating_emission_a_defaut_emetteur_cc_plus',
        'rating_emission_a_defaut_emetteur_cc',
        'rating_emission_a_defaut_emetteur_cc_moins',
        'rating_emission_a_defaut_emetteur_c_plus',
        'rating_emission_a_defaut_emetteur_c',
        'rating_emission_a_defaut_emetteur_c_moins',
        'rating_emission_a_defaut_emetteur_ddd_plus',
        'rating_emission_a_defaut_emetteur_ddd',
        'rating_emission_a_defaut_emetteur_ddd_moins',
        'rating_emission_a_defaut_emetteur_dd_plus',
        'rating_emission_a_defaut_emetteur_dd',
        'rating_emission_a_defaut_emetteur_dd_moins',
        'rating_emission_a_defaut_emetteur_d_plus',
        'rating_emission_a_defaut_emetteur_d',
        'rating_emission_a_defaut_emetteur_d_moins',
        'rating_emission_a_defaut_emetteur_unknown',
        'NA',
      ].indexOf(d.first().label)

  if (dim === 'strat_mso_detailed_reporting')
    return d =>
      [
        'strat_mso_detailed_reporting___mso_govies_core_euro',
        'strat_mso_detailed_reporting___mso_govies_peripheriques',
        'strat_mso_detailed_reporting___mso_govies_etats_unis',
        'strat_mso_detailed_reporting___mso_govies_autres_pays_developpes',
        'strat_mso_detailed_reporting___mso_credit_ig',
        'strat_mso_detailed_reporting___mso_credit_hy',
        'strat_mso_detailed_reporting___mso_credit_sub',
        'strat_mso_detailed_dirr___classe_d_actif___monetaire___obligations_ct',
        'strat_mso_detailed_reporting___mso_diversification_dettes_emergentes',
        'strat_mso_detailed_reporting___mso_diversification_convertibles',
      ].indexOf(d.first().label)

  if (dim === 'tranche_maturite_monetaire')
    return d =>
      [
        'tranche_maturite_monetaire_obligations__inf_1_mois_',
        'tranche_maturite_monetaire_obligations__sup_1_mois_',
        'tranche_maturite_monetaire_obligations__sup_3_mois_',
        'tranche_maturite_monetaire_obligations__sup_6_mois_',
        'tranche_maturite_monetaire_obligations__sup_9_mois_',
        'tranche_maturite_monetaire_obligations__1_an___2_ans_',
        'NA',
      ].indexOf(d.first().label)

  if (dim === 'rating_mmf') return d => ['rating_mmf_a1_plus', 'rating_mmf_a1', 'rating_mmf_a2', 'rating_mmf_a3', 'rating_mmf_unknown'].indexOf(d.first().label)

  if (dim === 'tranche_maturite_with_call')
    return d =>
      [
        'tranche_maturite_with_call__moins_1_an',
        'tranche_maturite_with_call__1_a_3_ans',
        'tranche_maturite_with_call__3_a_5_ans',
        'tranche_maturite_with_call__5_a_7_ans',
        'tranche_maturite_with_call__7_a_10_ans',
        'tranche_maturite_with_call__10_a_15_ans',
        'tranche_maturite_with_call__sup_a_15_ans',
      ].indexOf(d.first().label)

  if (dim === 'tranche_maturite')
    return d =>
      [
        'tranche_maturite__moins_1_an',
        'tranche_maturite__1_a_3_ans',
        'tranche_maturite__3_a_5_ans',
        'tranche_maturite__5_a_7_ans',
        'tranche_maturite__7_a_10_ans',
        'tranche_maturite__10_a_15_ans',
        'tranche_maturite__sup_a_15_ans',
      ].indexOf(d.first().label)

  if (dim === 'taille_de_capi')
    return d =>
      ['taille_de_capi_small_cap', 'taille_de_capi_mid_cap', 'taille_de_capi_large_cap', 'taille_de_capi_dirr__titres_non_cotes', 'NA'].indexOf(d.first().label)

  if (dim === 'lfam_sub_debt')
    return d =>
      [
        'lfam_sub_debt_coco_s',
        'lfam_sub_debt_hybrid_corporate',
        'lfam_sub_debt_assurance',
        'lfam_sub_debt_tier_1',
        'lfam_sub_debt_tier_2',
        'lfam_sub_debt_autres_subordonnees',
        'lfam_sub_debt_dirr___non_subordonnees',
        'NA',
      ].indexOf(d.first().label)

  if (dim === 'rating_assurance_emetteur_non_signe')
    return d =>
      [
        'rating_assurance_emetteur_non_signe_dirr___rating_assurance_emetteur_aaa',
        'rating_assurance_emetteur_non_signe_dirr___rating_assurance_emetteur_aa',
        'rating_assurance_emetteur_non_signe_dirr___rating_assurance_emetteur_a',
        'rating_assurance_emetteur_non_signe_dirr___rating_assurance_emetteur_bbb',
        'rating_assurance_emetteur_non_signe_dirr___rating_assurance_emetteur_bb',
        'rating_assurance_emetteur_non_signe_dirr___rating_assurance_emetteur_b',
        'rating_assurance_emetteur_non_signe_dirr___rating_assurance_emetteur_ccc',
        'rating_assurance_emetteur_non_signe_dirr___rating_assurance_emetteur_cc',
        'rating_assurance_emetteur_non_signe_dirr___rating_assurance_emetteur_c',
        'rating_assurance_emetteur_non_signe_dirr___rating_assurance_emetteur_nr',
        'NA',
      ].indexOf(d.first().label)

  if (dim === 'rating_assurance_emission_non_signe')
    return d =>
      [
        'rating_assurance_emission_non_signe_dirr___rating_assurance_emission_aaa',
        'rating_assurance_emission_non_signe_dirr___rating_assurance_emission_aa',
        'rating_assurance_emission_non_signe_dirr___rating_assurance_emission_a',
        'rating_assurance_emission_non_signe_dirr___rating_assurance_emission_bbb',
        'rating_assurance_emission_non_signe_dirr___rating_assurance_emission_bb',
        'rating_assurance_emission_non_signe_dirr___rating_assurance_emission_b',
        'rating_assurance_emission_non_signe_dirr___rating_assurance_emission_ccc',
        'rating_assurance_emission_non_signe_dirr___rating_assurance_emission_cc',
        'rating_assurance_emission_non_signe_dirr___rating_assurance_emission_c',
        'rating_assurance_emission_non_signe_dirr___rating_assurance_emission_d',
        'rating_assurance_emission_non_signe_dirr___rating_assurance_emission_nr',
        'NA',
      ].indexOf(d.first().label)
}
