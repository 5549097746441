<template lang="pug">
transition(@leave='leave')
  loader(v-if="!this.loaded")
template(v-if="this.loaded")
  .screen-title
    h1 {{ t['performance'] }} 
  .row.stretch
    kpi.performance(
      v-if="data?.share?.analytics?.domainPerformance"
      :data="performanceKpis"
    )
    kpi.volatility(
      v-if="data?.share?.analytics?.volatility?.fund && (!is_less_1y || !$root.config.hideLessThanOneYear)"
      :data="volatilityKpis" 
    )
    kpi.drawdown(
      v-if="data?.share?.analytics?.drawdownDetails && params?.metric !== 'diff'"
      :data="data.share.analytics.drawdownDetails.keys().map(el=>({title: el, value: data.share.analytics.drawdownDetails[el] }))"
    )
    kpi(
      v-if="(data?.share?.analytics?.trackingError || params?.metric === 'diff') && (!is_less_1y || !$root.config.hideLessThanOneYear) "
      :data="[{title: 'trackingError', value: parseFloat(data.share.analytics.trackingError) },{ title: 'informationRatio', value: parseFloat(data.share.analytics.informationRatio) },{ title: 'sharpe_ratio', value: parseFloat(data.share.analytics.domainSharpeRatio) },{ title: 'sharpe_ratio_benchmark', value: parseFloat(data.share.analytics.domainSharpeRatioBenchmark) }]"
    )
  .block.column.expand
    block(
      :title="performance_type_title(data)"
      type="plot-line"
      v-if="data?.share?.analytics?.domainHistorical"
      :data="domain_historical_formatted"
      :metadata="{format: (v) => format('.2%')(v * 0.01)}"
    )
    block.allow-fullscreen(
      type="plot-bar"
      v-if="this.isChartYearly ? data?.share?.analytics?.yearlyPerformances : data?.share?.analytics?.monthlyPerformances"
      :data="this.isChartYearly ? data.share.analytics.yearlyPerformances.reduce((acc,a)=> {acc[a.year]=a; return acc}, {}).map(p => p[this.$route.query.metric || 'fund']) : this.formatMonthlyPerformanceLabel(data.share.analytics.monthlyPerformances.reduce((acc,a)=> {acc[a.month]=a; return acc}, {}).filter((v,k) => k.includes(this.$route.query.domain)).map(p => p[this.$route.query.metric || 'fund']))"
      :title="this.isChartYearly ?  'performance_yearly' : 'performance_monthly' "
      :metadata="{ sort: this.sortMonths }"
      @plotclick="handleBarChartClick"
    )
      template(v-slot:title="{ title, toggleFullscreen }")
        h2
          div(v-html="t[title] || title")
          select(@change="update_query({ domain: $event.target.value })" v-if="data.share.analytics.yearlyPerformances.keys().length > 1")
            option(value="") {{ t.default }}
            option(:selected="$route.query.domain && $route.query.domain.length <= 7 && year === $route.query.domain.slice(0, 4)" v-for="year in data.share.analytics.yearlyPerformances.keys().reverse()") {{ year }}
          button.ghost(aria-label="fullscreen" @click="toggleFullscreen()")
            svg-icon.nx-fullscreen(name="nx-fullscreen")
</template>

<script>
export const additions = { calendar: 'period', icon: 'ic_timeline' };
import { useDates } from '../../composables/useDates';
import { usePerformance } from '../../composables/usePerformance.js'
const { getDatesQuery } = useDates();
const { getPerformanceData } = usePerformance();

export default {
  data() {
    return {
      default_domain: '0001-01-01|' + new Date().toISOString().split('T')[0],
      displayed_plot: 'performance_historical',
      loaded: false,
      params: {
        isinShare: this.$route.fullPath.split('/')[2].split('-')[1],
        domain: $root.domain.join('|') || this.$route.query.domain,
        asof: $root.asof,
        lang: this.$route.query.lang || $root.lang,
        metric: this.$route.query.metric,
      },
      data: {},
    }
  },
  computed: {
    rootWatcher() {
      return this.$route.query.domain || $root.query.domain 
    },
    metricWatcher(){
      return this.$route.query.metric
    },
    sortMonths() {
      return d => [ ...Array(12).keys() ].map( i => new Intl.DateTimeFormat($root.lang.slice(0, 2), { month: 'short' }).format(new Date(String(i+1)))).indexOf(d.x)
    },
    isChartYearly() {
      return (!this.$route.query.domain || this.$route.query.domain.length > 7) && this.data.share.analytics.yearlyPerformances.length > 1
    },
    performanceKpis() {
      const kpis = [{
        title: this.performance_type_title(this.data),
        value: this.data.share.analytics.domainPerformance.fund
      }]
      if (this.data.share.benchmark.label) {
        kpis.push({
          title: 'performance_benchmark',
          value: this.data.share.analytics.domainPerformance[this.data.share.benchmark.label]
        })
      }
      return kpis
    },
    volatilityKpis() {
      const kpis = [{
        title: 'volatility',
        value: this.data.share.analytics.volatility.fund
      }]
      if (this.data.share.benchmark.label) {
        kpis.push({
          title: 'volatility_benchmark',
          value: this.data.share.analytics.volatility[this.data.share.benchmark.label]
        })
      }
      return kpis
    },
    domain_historical_formatted() { //todo: check if really needed
      // Get the first benchmark in the list, if it's empty, use 'benchmark'
      const benchmark_multiple = this.data.share.characteristics?.benchmark_multiple?.split(',')[0]
      return this.data.share.analytics.domainHistorical
      .reduce((acc,a)=> {
        let doc = {}
        if (a.fund) doc.fund = a.fund - 100
        if (a.benchmark && (!benchmark_multiple || benchmark_multiple === 'benchmark')) doc.benchmark = a.benchmark - 100
        if (a.benchmark2 && benchmark_multiple === 'benchmark2') doc.benchmark2 = a.benchmark2 - 100
        if (a.benchmark3 && benchmark_multiple === 'benchmark3') doc.benchmark3 = a.benchmark3 - 100
        acc[a.date]=doc;
        return acc
      }, {})
    },
    is_less_1y() {
      // Do not show volatility and risk indicators if < 1y
      const days = (new Date($root.domain[1]) - new Date($root.domain[0])) / 86400000
      return (days < 363)
    }
  },
  watch: {
    async rootWatcher(newRoot, oldRoot) {
      if (newRoot?.toString() === oldRoot?.toString()) return
      this.params.domain = newRoot?.join('|')
      if (this.$route.query.domain === undefined) {
        this.params.domain = this.default_domain
      } else {
        this.params.domain = this.$route.query.domain
      }
      await this.loadData()
    },
    async metricWatcher(newM, oldM){
      this.params.metric = newM
      await this.loadData()
    }
  },
  async created() {
    await this.loadData()
  },
  methods: {
    leave(el, done) {
      el.style.opacity = 0
      setTimeout(() => done(), 500)
    },
    async loadData() {
      this.loaded = false
      if (!$root.dates || $root.dates.isin_share !== this.params.isinShare || $root.dates.query !== 'performance') {
        $root.dates = await getDatesQuery(this.params, 'performance')
        $root.dates.isin_share = this.params.isinShare
        $root.dates.query = 'performance'
        $root.refresh = $root.refresh + 1
        this.params.domain = $root.domain.join('|')
      }
      this.data = await getPerformanceData(this.params)
      $root.tab_benchmark = this.data.share.benchmark
      $root.tab_userflow_name = this.data.share.slug
      $root.refresh = $root.refresh + 1
      this.loaded = true
    },
    formatMonthlyPerformanceLabel(monthlyPerformance) {
      return monthlyPerformance.reduce((acc, v, k) => {
        const formattedDate = new Intl.DateTimeFormat($root.lang.slice(0, 2), { month: 'short' }).format(new Date(k))
        acc[formattedDate] = v
        return acc
      }, {})
    },
    handleBarChartClick($event) {
      if (this.isChartYearly) {
        update_query({ domain: $event.x })
      } else {
        const year = this.$route.query.domain.slice(0, 4)
        const month = `${this.sortMonths($event) + 1}`.padStart(2, '0')
        const domain = `${year}-${month}`
        update_query({ domain })
      }
    },
    performance_type_title(data) {
      if (data.share.characteristics?.mandat_is_perf_net || data.share.characteristics?.mandat_is_perf_net === '') return "performance_net"
      return "performance_brut" 
    },
  },
}
</script>
<style>
.kpi.benchmark.expand {
  min-width: 450px;
}
.kpi .plus {
  min-width: 400px;
}
</style>
