<template>
  <grk-block :data="data" :options="options">
    <div class="flex w-full flex-col">
      <div class="flex w-full flex-col">
        <div class="flex w-full justify-between">
          <div class="flex flex-col">
            <div class="flex font-semibold">Lower risk</div>
            <div class="flex">Potentially lower reward</div>
          </div>
          <div class="flex flex-col">
            <div class="flex justify-end font-semibold">Higher risk</div>
            <div class="flex justify-end text-right">Potentially higher reward</div>
          </div>
        </div>
        <div class="mt-3 flex h-full w-full justify-center">
          <div v-for="num in possible_risks" class="m-0.5 flex h-6 flex-1 justify-center text-center align-middle font-semibold">
            <div class="w-6 rounded-xl p-1" :class="num === risk ? 'bg-primary text-white' : 'bg-neutral-300'">{{ num }}</div>
          </div>
        </div>
      </div>
    </div>
  </grk-block>
</template>

<script setup>
const props = defineProps({
  data: {
    type: [Object, String],
  },
  options: {
    type: Object,
  },
})
const sep = props.options.sep || "/"
const risk = props.data.split(sep)[0] * 1
const max = props.data.split(sep)[1] * 1
const possible_risks = [...Array(max + 1).keys()].splice(1)
</script>

<style scoped>
li {
  list-style-type: square;
  list-style-position: outside;
  margin-left: calc(var(--text_size) * (1.5));
}
</style>
