<style scoped>
h1 .search-filters { display: flex;flex: 1;margin-left: 10px;}
h1 .search-filters .autocomplete {min-width: 90%!important;}
.cell-details .resizer { display: none; }
.cell-details { max-width: 60px; }
.cell-details > div { cursor: pointer;position: relative;padding: 0;min-width: 59px;line-height: 28px;overflow: visible;background: inherit; }
.cell-details > div > .details { width: 100%;color: var(--primary);text-align: center;text-decoration: underline; }
.cell-details > div > .tooltip { display: none;z-index: 1;position: absolute;top: 1px;bottom: 1px;left: 60px;box-shadow: inset 0 0 0 .5px var(--background);background: inherit;width: max-content; }
.cell-details > div:hover > .tooltip { display: flex;align-items: center;justify-content: center;font: var(--p2); }
.tooltip .row { padding: 0 10px; }
.tooltip a { display: flex;align-items: center; }
.tooltip img { width: 30px; }
.tooltip .ghost { width: 30px;height: 30px;padding: 8px; }
.tooltip .task { height: 8px;width: 30px;margin: 0 1px; }
.tooltip .column .task { margin-top: 2px; }
.tag { display: flex;width: 20px;height: 20px;margin: auto;padding: 4px!important;align-items: center;font-size: 10px;font-weight: bold;font-variant-numeric: tabular-nums; }
:deep() .cell > * { display: flex;justify-content: center;align-items: center;min-width: fit-content; }

.cell-steps .tasks div { display:flex;justify-content: center;align-items: center; }
.cell-owner .owner, .cell-assignee .owner { width: 25px;height: 25px;margin: auto;padding: 0; }
.file_icon { min-width: 28px; }
.modal { z-index: 1000;position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.7); display: table; transition: opacity 0.3s ease;}
.modal-wrapper { display: table-cell; vertical-align: middle;}
.modal-container { background: #fff; width: 450px; border-radius: 5px; box-shadow: 0 2px 8px rgba(0, 0, 0, .33);transition: all 0.3s ease; margin: 0 auto; padding: 20px 30px;}
.modal-footer {display: flex; justify-content: flex-end; margin-top: 15px;}
.modal-footer .close{background: transparent;}
.modal-footer .delete{margin-left: 5px;color: red; background: transparent;}
.modal-footer .delete:hover{background: #ff0d0d0d;--shadow:none;}
.modal-footer .close:hover{background: #d0d0d026;--shadow:none;}
.modal-enter, .modal-leave { opacity: 0;}
.modal-enter .modal-container, .modal-leave .modal-container { -webkit-transform: scale(1.1);transform: scale(1.1);}
.block > .row:first-child .ghost {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  padding: 12px;
}
h1 .autocomplete { margin-left: 5px}
h1 {
  display: flex;
  /* justify-content: space-between; */
}
h1 .year {
  width: 120px;
  font-size: 14px;
  margin-right: 16px;
}

</style>

<template lang="pug">
transition(@leave='leave')
  loader(v-if="isLoading")
h1
  span {{ t[$root.screen.path] }}
  .search-filters
    select.year(v-model="year" style="width: 120px; font-size: 14px;")
      option(v-for="m in years" :value="m" :key="m") {{m}}
    autocomplete.right(
      :data="autocomplete_data"
      :options="{ placeholder: t.search }"
      :modelValue="activated_filters"
      @update:modelValue="autocomplete_input")
.block.expand
  .row.center.left
    popup(:show="confimRemove")
      template(v-slot:header)
        | {{t["confirmation_remove"]}}
      template(v-slot:content)
        | {{t["text_remove"]}}
      template(v-slot:action)
        button.close(@click="confimRemove = false") {{t['confirmation_btn_close']}}
        button.delete(@click="deleteRuns()") {{t["confirmation_btn_delete"]}}
    template(v-if="selected.length")
      autocomplete(:data="emails" :options="{ placeholder: $root.profile.email }" v-model="assignee" v-if="emails.length")
      button.ghost(@click="assign_runs(selected, assignee || $root.profile.email)" tt="Assignate")
        svg-icon(name="ic_user")
      button.ghost(@click="exported_production.dlCSV('fr', 'production.csv')" tt="Export")
        svg-icon(name="pt-icon-export")
      button.ghost(@click="confimRemove = true" tt="Remove")
        svg-icon(name="pt-icon-trash")
      button.ghost(
        tt="validate"
        @click.stop="validate_selected"
      )
        svg-icon(class="icon" style="fill: var(--positive);" name="pt-icon-tick")
      button.ghost(tt="rerun" @click.stop="rerunSelected()")
        svg-icon(class="icon" style="fill: var(--cat10);" name="pt-icon-play")
    select(@change="update_query({ scheduled: $event.target.value })")
      option(value="") Last runs
      option(value="all") All runs
  spreadsheet.stripped(:data="filtered_production" :options="production_metadata")
    template(v-slot:header-check)
      input(type="checkbox" :checked="selected.length === filtered_production.length" v-indeterminate="selected.length > 0 && selected.length !== filtered_production.length" @click="selected = selected.length === filtered_production.length ? [] : filtered_production.map('id')" @mousedown.prevent.stop="")
    template(v-slot:header-details)
      div(style="cursor: pointer" @mousedown.prevent.stop="")

    template(v-slot:cell-id="s")
      .tag {{ s.line.id }}

    template(v-slot:cell-check="s")
      input(type="checkbox" :checked="selected.includes(s.line.id)" @click.stop="selected = selected.map().toggle(s.line.id)" @mousedown.prevent.stop="")

    template(v-slot:cell-details="s")
      div(style="overflow: unset")
        router-link.details(:to="s.line.link") {{ t.details }}
        .tooltip
          template(v-if="!s.line.isParallel")
            template(v-if="s.line.isValidation")
              button.ghost(:tt="s.line.last_step.type === 'user_input' ? t.submit : t.accept" @click.stop="validate_step(s.line.id, s.line.log.id)")
                svg-icon(class="icon" style="fill: var(--positive);" name="pt-icon-tick")
              button.ghost(:tt="t.reject" @click.stop="set(['data', 'runs', s.line.id, 'logs', s.line.log.id, 'status'].join('.'), 'error')")
                svg-icon(class="icon" style="fill: var(--negative);" name="pt-icon-cross")
            template(v-else)
              button.ghost(:tt="t.skip" @click.stop="skip_step(s.line.id, s.line.log.id)")
                svg-icon(class="icon" style="fill: var(--yellow1);" name="ic_redo")
              button.ghost(:tt="t.rerun" @click.stop="rerun_step(s.line, s.line.log.action_id)" v-if="s.line.last_step.type !== 'wait_for'")
                svg-icon(class="icon" style="fill: var(--cat10);" name="pt-icon-play")
          a(download="" target="_blank" :href="commandr + '/' + document.alias" :tt="document.alias" v-for="document in documentsByRun[s.line.id]")
            img(:src="document.alias.includes('.pdf') ? 'https://platform.100m.io/dist/impress/icon_pdf.png' : 'https://platform.100m.io/dist/impress/icon_doc.png'")
          .row.center(v-if="s.line.workflow")
            //.task( :class="[s.line.logs && s.line.logs.v().filter(l => l && l.action_id === action.id).map('status').last(), { skipped: s.line.logs && s.line.logs.v().filter(l => l && l.action_id === action.id).map('skipped').last() && s.line.logs.v().filter(l => l && l.action_id === action.id).map('skipped').last() !== 'rerun'}]" v-for="action in s.line.workflow.actions.v().filter()" :tt="action.name" @click="$router.push({ path: '/workflow', query: Object.assign({}, $root.query, { id: s.line.sid.split('|')[0], rid: s.line.sid.split('|')[1], action_id: action.id }) })")
            .task( :class="[task.status, { skipped: task.skipped }]" v-for="task in s.line.tasks" :tt="task.name" @click="$router.push({ path: '/workflow', query: Object.assign({}, $root.query, { id: s.line.sid.split('|')[0], rid: s.line.sid.split('|')[1], action_id: task.id }) })")
    template(v-slot:cell-assignee="s")
      .owner(v-if="s.line.context.assignee" :style="{ background: 'var(--cat' + ((s.line.context.assignee || '').charCodeAt(0) % 10 + 1) + ')' }" :tt="s.line.context.assignee.split('@')[0].titleize()") {{ s.line.context.assignee.split('@')[0].split('.').map('0').join('').upper() }}
    template(v-slot:cell-template="s")
      div(v-if="s.line.template && s.line.tid !== null") {{ s.line.template }}
      div(v-else style="color: red;") Template removed
    template(v-slot:cell-language="s")
      div {{ s.line.lang }}
      //.flag_box(:tt="s.line.language.upper()")
      //  svg-icon(class="icon" :name="'flag-' + s.line.language.slice(0,2).lower()")
    template(v-slot:cell-jurisdiction="s")
      div {{ s.line.jurisdiction }}
      //.flag_box(:tt="s.line.language.upper()")
      //  svg-icon(class="icon" :name="'flag-' + s.line.language.slice(-2).lower()")
    template(v-slot:header-start-date)
      div {{ t.launched }}
    template(v-slot:header-domain)
      div {{ t.period }}
    //- template(v-slot:cell-domain="s")
    //-   div {{ new Date(s.line.domain).format('YYYY-MM') }}
    template(v-slot:cell-last-update-date="s")
      div {{ new Date(s.line.last_update_date).format('YYYY-MM-DD hh:mm:ss') }}
    template(v-slot:cell-start-date="s")
      div {{ new Date(s.line.context.run_time).format('YYYY-MM-DD') }}
    template(v-slot:cell-format="s")
      div {{ s.line.format.upper() }}
    // .tt(style="display: flex;" :tt="s.line.format.upper()")
    //    img.file_icon(:src="'https://platform.100m.io/dist/impress/icon_' + s.line.format.lower() + '.png'")
    template(v-slot:cell-workflow="s")
      div {{ (s.line.workflow || {}).name }}
    template(v-slot:cell-steps="s")
      .row.tasks
        div {{ s.line.log.action_id +  " / " + (s.line.workflow.actions.length - 1) }}
          .task(:class="[s.line.log.status, { skipped: s.line.log.skipped && s.line.log.status === 'killed'}]")</template>

<script>
import {computed, inject, onMounted, watch} from "vue";

export const additions = {"icon":"ic_history"}
import config from '../../../config'
import {useImpressions} from "../composables/useImpressions";
import {useProduction} from "../composables/useProduction";
import {useUsers} from "../composables/useUsers";
import {useProgress} from "../composables/useProgress";
export default {
  setup() {
    const {production, deleteRun, deleteRuns, loaded: prodLoaded, initialized, years, year} = useProduction()
    const {impressions, loaded: impressionsLoaded} = useImpressions()
    const {emails, loaded: usersLoaded} = useUsers()
    const {progress} = useProgress([prodLoaded, impressionsLoaded, usersLoaded])

    // Initial loading
    const isLoading = computed(() => progress.value !== 1)

    return {deleteRun, deleteMany: deleteRuns, impressions, production, emails, isLoading, years, year}
  },
  data() {
    return {
      selected: [],
      commandr: config.commandr,
      confimRemove: false,
      filtered_timelines: ['future_task'],
      assignee: null,
    }
  },
  methods: {
    async rerunSelected() {
      await Promise.all(this.filtered_production.filter(r => this.selected.includes(r.id)).map(r => this.rerun_step(r, r.logs.v().last().action_id)))
      this.selected = []
    },
    isParallel(s) {
      if (s.line.id === undefined || !s.line.logs) return false
      const action = s.line.workflow.actions[s.line.log.action_id]
      return action !== undefined && action.parallel
    },
    deleteRuns() {
      this.deleteMany(this.selected)
      this.confimRemove = false
    },
    validate_selected() {
      let resolveSelectedRuns = this.filtered_production
          .filter(r => this.selected.includes(r.id));
      let runningSelected = resolveSelectedRuns
          .filter(r => r.logs.last().status === 'running')
      let promptSelected = runningSelected
          .filter(r => {
            let {action_id} = r.logs.last();
            let workflowAction = r.workflow.actions[action_id];
            let {alias, type} = workflowAction;
            let isValidationStep = alias === 'prompt' || type === 'user_validation'
            return isValidationStep
          })
      promptSelected
          .forEach(run => {
            let lid = run.logs.v().last().id;
            let rid = run.id;
            this.validate_step(rid, lid);
          });
    },
    autocomplete_input(event) {
      let event_array = event
          .map(v => [v.slice(0, v.indexOf('.')), v.slice(v.indexOf('.')+1, v.length)])
      let grouped_events = event_array
          .group('0')
      let selected_filters =  grouped_events.map(g => g.map('1').join('|'))
      let new_filter_selected = this.$route.query
          .filter((v, k) => !this.$route.query.keys().includes(k))
      let other = {year: this.$route.query.year}
      let query = {...selected_filters,...new_filter_selected, ...other}
      this.$router.push({query})
    },
    format_filter_data(name,value) {
      return name + '.' + value
    },
  },
  computed: {
    impressionByRuns() {
      return this.impressions.reduce((acc, b) => {
        if (acc[b.run_id]) acc[b.run_id].push(b)
        else acc[b.run_id] = [b]
        return acc
      }, {})
    },
    documentsByRun() {
      return Object.fromEntries(Object.entries(this.impressionByRuns)
          .map(([run_id, imps]) => [run_id, imps.group('name').v().sort(d => +d.first().path.split('-')[1]).last()]))
    },
    filtered_production() {
      let production = this.production.v()
      if (!$root.query.scheduled) production = production.group(d => ['template', 'isin', 'language', 'domain', 'workflow'].map(c => typeof d[c] === "object" ? ['wid',d[c].id].join('-') : d[c]).join('-')).map(v => v.last()).v()
      if ($root.query.scheduled === 'assignee') production = production.filter(r => r.workflow.actions[r.logs.last().action_id].user === $root.profile.email)
      if ($root.query.scheduled === 'owner') production = production.filter(r => r.context.owner === $root.profile.email)
      if ($root.query.scheduled === 'year') production = production.filter(r => r.start_date >= new Date().minus('1 year').format())
      if ($root.query.scheduled === 'active') production = production.filter(r => !(r.workflow.actions.last().id === r.logs.last().action_id && r.logs.last().status === 'success'))

      const filters = Object.entries($root.query).filter(([k, v]) => !['search', 'selected', 'scheduled', 'year'].includes(k)).map(([k, v]) => [k, v.split('|')])
      return production.filter(d =>
          filters.every(([k, vs]) =>
              d[k] && vs.some(v =>
                  `${d[k]}`.replace('.','') === v ||
                  (/^>/.test(v) && d[k] > v.slice(1)) ||
                  (/^</.test(v) && d[k] < v.slice(1))
              )
          ))
    },
    selected_production() {
      return this.production.filter(d => this.selected.includes(d.id))
    },
    exported_production() {
      return this.selected_production.map(v => this.exported_columns.reduce((acc, k) => (acc[k] = v[k], acc), {}))
    },
    activated_filters() {
      let query_params = this.$route.query
      let active_filters = query_params.map(filter_value => filter_value.split('|'))
      const filters = []
      Object.entries(active_filters).forEach(([filter_name,filter_values]) => {
        if (filter_name === 'year') return
        filter_values.forEach(value => {
          filters.push(this.format_filter_data(filter_name,value))
        })
      })
      return filters
    },
    autocomplete_data() {
      let column_selections = this.production_metadata.columns
          .filter(k => {
            return (k &&
                !['assignee', 'start_date', 'last_update_date'].includes(k))
          })
          .concat(['status', 'workflow_name', 'step'])
      let data = this.production.reduce((acc, v) => {
        column_selections
            .forEach(k => {
              if(typeof v[k] === 'string') {
                let filter_criteria = v[k].replace('.','')
                acc[k] = acc[k] || {}
                acc[k][filter_criteria] = filter_criteria
              }
            })
        return acc
      }, {})
      return data
    },
  }
}
</script>
