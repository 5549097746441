<template>
  <table class="bottom-0 h-fit w-fit divide-y self-center rounded-lg">
    <tr :class="item.name?.format('dash')" v-for="(item, index) in data.slice(0, options.limit ?? 10)">
      <td>
        <div class="bg-primary h-2 w-2" :style="{ opacity: Math.max(1 - 0.2 * index, 0.1) }"></div>
      </td>
      <td class="p-1">
        {{ item.name }}
      </td>
      <td class="p-1">
        {{ format(item.value / total, (options.digit ?? 1) + (options.unit ?? "")) }}
      </td>
    </tr>
  </table>
</template>

<script setup>
import format from "../../format.js"
const { data, options } = defineProps(["data", "options"])
const total = data.map((v) => v.value).sum()
</script>
