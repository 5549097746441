<style scoped>
.plot-block.plottable {
  flex: 1;
  height: auto;
}
.content {
  height: 100%;
}
.plot-block {
  min-height: 20vh;
}
.legend {
  margin: -4px 4px 12px;
}
/* .board.data {
  position: relative;
  top: 64px;
  bottom: 40px;
  overflow: auto;
  width: 100%;
  flex: 0 0 15em;
  padding-left: 55px;
  min-width: 280px;
} */
.tooltip-table,
.tooltip-line { z-index: 3;position: absolute;top: 8px;right: 40px;min-width: 180px;font: var(--p2); }
.tooltip-table > :first-child { padding: 2px 8px;background: var(--colors-highlight);border-top-left-radius: var(--border-radius);border-top-right-radius: var(--border-radius);font-weight: 600; }
.tooltip-table > :last-child { padding: 2px 8px;background: white;border: var(--border);border-bottom-left-radius: var(--border-radius);border-bottom-right-radius: var(--border-radius);border-color: rgb(180, 186, 198, 0.4);border-top: 0; }
.tooltip-table > :last-child > div { display: flex;justify-content: space-between; }
:is(.tooltip-table, .tooltip-line) .number { margin-left: 10px;font-weight: 600; }
.tooltip-line { display: flex;justify-content: space-between;min-width: 140px;padding: 2px 8px;background: var(--colors-highlight);border-radius: var(--border-radius); }
.tooltip-line > :first-child { margin-right: 16px; }
</style>
<template lang="pug">
slot(name="legend" :tooltip="tooltip" :data="data" :metadata="metadata" :legend="legend")
  .legend
    div(:class="k" v-for="k in legend")
      .color
      .label {{ t[k] || k }}
  //- .legend(v-if="(type === 'plot-base') && legend_plotbase")
  //-   div(v-for="k in legend")
  //-     .color(:style=" {background: k.color || 'inherit' }")
  //-     .label {{ t[k.name] || k.name }}

slot(name="tooltip" :tooltip="computedTooltip")
  .tooltip-line(v-if="computedTooltip && computedTooltip.x")
    div {{ t[computedTooltip.label] || computedTooltip.label || t[computedTooltip.x] || computedTooltip.x }}
    div(v-html="unit(format((metadata && metadata.format) || '.2%')(+computedTooltip.y))")
  .tooltip-line(v-if='computedTooltip && computedTooltip.date && computedTooltip.y')
    div {{ new Date(computedTooltip.date).format('day, mon, year', $root.lang).titleize() }}
    div(v-html="unit(format((metadata && metadata.format) || '.2f')(+computedTooltip.y))")
  .tooltip-table(v-if="computedTooltip && computedTooltip.date && !computedTooltip.y")
    div
      | {{ '' + new Date(computedTooltip.date) === 'Invalid Date' || (metadata && metadata.xAxisNumeric) ? computedTooltip.date : new Date(computedTooltip.date).format('day, mon, year', $root.lang).titleize() }}
    div
      div(v-for="v, k in computedTooltip.__.filter((v,k) => k !== 'date')" :key="k")
        div {{ t[k] || k }}
        div(v-html="unit(format((metadata && metadata.format) || '.2f')(+v))")
.row.content(v-if="$root.slide === 0")
  component.plot-block(:is="type" :guideline="guideline" :data="data" :metadata="metadata" ref="component" @guideline="$emit('guideline', $event)" @tooltip="onTooltip" @plotclick="onPlotclick" @rebind="onRebind" @brush="onBrush" :style="{ height }")
  slot(name="fullscreen")
    //- TODO this is unreadable
    board.data(v-if="fullscreen" :data="data.__.map((v, k) => typeof v === 'object' ? Object.assign({ 'sum': v.__.v().sum() }, v) : v).__.map((v, k) => Object.assign({ [title.includes(',') ? title.split(',').last() : 'title']: t[k] || k }, typeof v === 'object' ? v : { [title.split(',').first()]: v })).__.v().sort(d => new Date(data.__.keys().first()).toDateString() !== 'Invalid date' ? d :d[fullscreenColumns.first()])" :metadata="{ format: metadata && metadata.format, columns: typeof data.__.v().first() === 'object' ? fullscreenColumns.concat(['sum']) : fullscreenColumns, sort: '-' + fullscreenColumns.last() }")
</template>
<script>
import './plottable'

// TODO check if we still need this
    // legend_plotbase() {
    //   // TODO Replace by toggle-legend from natixis project
    //   if (this.$root.app === 'natixis-ldi') return
    //   if(this.metadata.plots.__.v().first().plot_type === 'StackedArea') return this.data.__.v().__.map(d => d.__.map(v => ({name: v.first().name, color: v.first().color})))[0]
    //   return []
    // },
import { ref, computed, inject, reactive, onMounted } from 'vue'
export default {
  props: {
    data: {
      type: [Object, Array],
    },
    metadata: {
      type: Object,
    },
    type: {
      type: String,
    },
    guideline: {
      type: Date,
      default: () => null,
    },
    enableActions: {
      type: Boolean,
      default: true,
    },
    tooltip: {
      type: Object,
      default: () => null,
    }
  },
  emits: ['mounted', 'rebind', 'plotclick', 'brush', 'tooltip', 'guideline'],
  setup(props, { emit, attrs }) {
    const component = ref(null)
    const data = inject('data', computed(() => props.data))
    const legend = computed(() => {
      if (!data.value) return
      const entry = Object.values(data.value)[0]
      if (!entry || !Object.keys(entry).length) return
      return data.value.__.map(v => v.__.keys()).__.v().flat().unique()
    })
    const getEmitter = (eventName) => {
      const emitter = inject('on' + eventName.capitalize(), null)
      if (!emitter) return (ev) => emit(eventName, ev)
      return emitter
    }

    const metadata = inject('metadata', computed(() => props.metadata))
    const tooltipData = ref()
    const computedTooltip = inject('computedTooltip', computed(() => props.tooltip || tooltipData.value))
    const _onTooltip = getEmitter('tooltip')
    const onTooltip = (ev) => {
      if (ev) window.tooltip = ev
      _onTooltip(ev)
      tooltipData.value = ev
    }

    const components = ref()
    const _onRebind = getEmitter('rebind')
    const onRebind = (ev) => {
      _onRebind(ev)
      components.value = ev
    }
    const _onPlotclick = (datum, entities) => {
      const dimension = metadata?.value?.dimension
      if (!dimension) return
      if (!entities.length) {
        const filter = components.value.plot
          .datasets()
          .__.map(d => d.data())
          .flat(Infinity)
          .__.filter(d => d.x === datum.x)
          .__.map('label')
          .join('|')
        return update_filter(dimension, filter)
      }
      update_filter(dimension, datum.label || datum.x)
    }
    const onPlotclick = inject('onPlotclick', _onPlotclick)
    const _onBrush = domain => {
      if (!props.enableActions) return
      domain = domain.__.map(d => d.format())
      if (domain[0] === domain[1]) return update_query({ domain: domain[0].slice(0, 7) })
      return update_domain(domain)
    }
    const onBrush = inject('onBrush', _onBrush)
    // debugger
    // TODO we should not need this
    const title = inject('title', props.title)
    const height = ref('auto')
    const resize = () => {
      const plotElement = component?.value?.$el?.querySelector?.('.component')
      if (!plotElement) return
      height.value = plotElement.style.height
    }
    onMounted(() => {
      resize()
      const plotElement = component?.value?.$el?.querySelector?.('.component')
      if (!plotElement) return
      new ResizeObserver(resize).observe(plotElement)
    })
    const fullscreen = inject('fullscreen')
    const fullscreenColumns = computed(() => {
      return data.value.__.map((v, k) => Object.assign({ [title.value.includes(',') ? title.value.split(',').last() : 'title']: $root.t[k] || k }, typeof v === 'object' ? v : { [title.value.split(',').first()]: v })).__.v().first().__.keys()
    })

    return {
      computedTooltip,
      legend,
      metadata,
      data,
      tooltipData,
      onTooltip,
      onPlotclick,
      onRebind,
      onBrush,
      components,
      log: console.log,
      title,
      fullscreen,
      fullscreenColumns,
      component,
      height,
    }
  },
}
</script>
