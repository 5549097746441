import { GraphQlService } from './GraphQlService'

export class FileService extends GraphQlService{
  async list(options = { limit: 100, order: 'DESC' }) {
    return (await this.query(`
      query Files($limit: Int, $order: Order, $type: FileType) {
        files(limit: $limit, order: $order, type: $type) {
          name
          status
          startDate
          mtime
          duration
          date
          checksum
          type
        }
      }`, options))
      .data
      .files
      .map(({ name, ...f }) => ({ ...f, name: name.split('/').slice(-1)[0] }))
  }
}

const fileService = new FileService()
export default fileService
