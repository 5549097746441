<script setup>
import { ref } from "vue"
import NewTable from "../../components/new-table.vue"
import investor_data from "../../data/investor.json"
import local_atm_data from "../../data/local-atm.json"

// const general_part = ["B", "D2"]
// const partner_part = ["A1", "A2", "D1"]

const General_data = ref(
  local_atm_data
    .filter((d) => d.portfolioCode === $root.params.userflow)
    .filter((d) => d.date == "06/30/2017")
    .__.map((d) => ({
      "Share Class": d.part,
      "Share Class Label": d.partLabel,
      Capital: d.capital,
      NAV: d.vl,
      "Net Asset": d.actifNetPart,
      Performance: d.performance / 100,
    }))
)

// const Partner_data = ref(
//   local_atm_data
//     .filter((d) => d.portfolioCode === $root.params.userflow)
//     .filter((d) => partner_part.includes(d.part) && d.date == "06/30/2017")
//     .__.map((d) => ({
//       "Share Class": d.part,
//       "Share Class Label": d.partLabel,
//       Capital: d.capital,
//       NAV: d.vl,
//       "Net Asset": d.actifNetPart,
//       Performance: d.performance / 100,
//     }))
// )

const Investor_data = ref(
  investor_data
    .filter((d) => d.portfolioCode === $root.params.userflow)
    .filter((d) => d.date == "06/30/2017")
    .__.map((d) => ({
      "Share Class": d.part,
      Investor: d.investor,
      Amount: d.amount,
    }))
)

const general_options = {
  format: {
    Capital: ".2fM€",
    "Net Asset": ".2fM€",
    Performance: ".2%",
  },
  colored_text: "Performance",
}

// const partner_options = {
//   format: {
//     Capital: ".2fM€",
//     "Net Asset": ".2fM€",
//     Performance: ".2%",
//   },
//   colored_text: "Performance",
// }

const investor_options = {
  format: {
    Amount: ".2fM€",
  },
}
</script>
<script>
export const additions = { icon: "ic_storage" }
</script>

<template>
  <div class="flex flex-col space-y-10">
    <h1 class="justify-center font-bold mb-6 text-3xl">{{ $root.params.userflow }} : {{ t.capitalAccount }}</h1>
    <NewTable :data="General_data" :options="general_options" />
    <!-- <NewTable :data="Partner_data" :options="partner_options" /> -->
    <NewTable :data="Investor_data" :options="investor_options" />
  </div>
</template>
