import { dbRef, update } from '../../../features/firebase'
import {onMounted, onUnmounted, ref, watch} from 'vue'
import { mapRun } from '../utils/runs'
import {useRoute} from "vue-router";

let productionRef
let yearsRef
const production = ref([])
const years = ref([])
const initialized = ref(false)

const enforceTable = data => {
  if (Array.isArray(data)) return data
  return Object.entries(data || {}).reduce((acc, [k, v]) => {
    acc[+k] = v
    return acc
  }, [])
}
export function useProduction() {
  productionRef = productionRef || dbRef.child('/data/runs')
  yearsRef = yearsRef || dbRef.child('/data/runs_years')
  const loaded = ref(false)
  const year = ref(null)
  const route = useRoute()
  const deleteRun = async id => {
    return await update(`data.runs.${id}`, { disabled: true })
  }

  const deleteRuns = async ids => {
    const updates = ids.reduce((acc, id) => {
      acc[`/data/runs/${id}`] = { disabled: true }
      return acc
    }, {})
    return dbRef.update(updates)
  }

  const onUpdate = snap => {
    production.value = (enforceTable(snap.val()) || []).map(mapRun).filter()
    loaded.value = true
    initialized.value = true
  }
  const onAdd = snap => {
    const p = mapRun(snap.val())
    if (p) production.value.push(p)
  }
  const onRemove = snap => {
    const id = snap.key
    const index = production.value.findIndex(p => p.id === +id)
    if (index > -1) production.value.splice(index, 1)
  }
  const onChanged = snap => {
    const id = snap.key
    const data = mapRun(snap.val())
    const index = production.value.findIndex(p => p.id === +id)
    if (index === -1) return
    if (!data) production.value.splice(index, 1)
    else production.value[index] = data
  }
  window.prdRef = productionRef
  const getProductionRef = (year) => {
    if (year === 'all') return productionRef
    return productionRef.orderByChild('year').equalTo(year)
  }

  const initYear = async () => {
    const pref = route.query.year || localStorage.ce_year
    if (pref) return year.value = pref
    const years = (await yearsRef.get()).val()
    if (typeof years !== 'object') return year.value = 'all'
    year.value = Object.keys(years).sort().pop()
  }

  let pRef = null
  const clearCallbacks = () => {
    pRef.off('child_added')
    pRef.off('child_removed')
    pRef.off('child_changed')
  }
  watch(year, () => {
    loaded.value = false
    if (pRef) clearCallbacks()
    pRef = getProductionRef(year.value)
    pRef.once('value', onUpdate)
    pRef.on('child_added', onAdd)
    pRef.on('child_removed', onRemove)
    pRef.on('child_changed', onChanged)
    localStorage.setItem('ce_year', year.value)
    uquery({year: year.value})
  })
  onMounted(async () => {
    loaded.value = false
    await initYear()
    yearsRef.on('value', snap => {
      years.value = Object.keys(snap.val()).sort((a, b) => b.localeCompare(a));
      if(year.value === null && years.value.length) year.value = years.value[0]
    })
  })
  onUnmounted(() => {
    clearCallbacks()
  })
  return {
    loaded,
    deleteRun,
    deleteRuns,
    production,
    initialized,
    years,
    year,
  }
}
