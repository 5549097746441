<template>
  <!-- nx-switch -->
  <label qa-switch class="switch mx-1 w-6">
    <input @click="emitClick" type="checkbox" class="vsg-unset" :checked="props.checked" />
    <span class="slider round" :class="currentTheme?.slider"></span>
  </label>
</template>

<script setup lang="ts">
import { useTheme } from "@hauru/common"

interface IProps {
  /**
   * Boolean indicating if swicth is on or off
   */
  checked?: boolean
  /**
   * Allows to manually bypass the theme set as default, among the themes provided by the theme config
   */
  theme?: string
  /**
   * The type of the switch among the types defined in the theme config
   */
  type?: string
}

const props = withDefaults(defineProps<IProps>(), {})

const emit = defineEmits<{
  (e: "click"): void
}>()

const themeConfig = useTheme()
const currentTheme = themeConfig.computedThemeType("switch", props)

const emitClick = () => {
  emit("click")
}
</script>

<style>
.switch {
  position: relative;
  display: inline-block;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
}
.switch .slider {
  position: absolute;
  cursor: pointer;
  width: 22px;
  height: 11px;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: theme("colors.red.500");
  -webkit-transition: 0.4s;
  transition: 0.4s;
  transform: translateY(-50%);
}
.switch .slider:before {
  position: absolute;
  content: "";
  height: 7px;
  width: 7px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.switch input:checked + .slider {
  background-color: theme("colors.green.500") !important;
}
.switch input:focus + .slider {
  box-shadow: 0 0 1px theme("colors.green.500") !important;
}
.switch input:checked + .slider:before {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
  background-color: white;
}
.switch .slider.round {
  border-radius: 34px;
}
.switch .slider.round:before {
  border-radius: 50%;
}
</style>
