import { ref } from 'vue';
export function useFirebaseTranslations() {
  const loaded = ref(true)
  const langs = [ // TODO should be a graphQL call
    'de',
    'de_at',
    'de_ch',
    'en',
    'en_ch',
    'fr',
    'fr_be',
    'fr_ch',
    'fr_lu',
    'it',
    'nl_be',
  ]

  return {
    langs,
    loaded,
  }
}
