import { onMounted, onUnmounted, ref } from 'vue'
import { dbRef } from '../../../features/firebase'
export function useDataQuality() {
  const dataQualityRef = dbRef.child('/data-quality')
  const dataQualityIgnoredRef = dbRef.child('/data-quality-ignored')
  const loaded = ref(false)
  const dataQuality = ref({})
  const dataQualityIgnored = ref({})

  const onUpdate = snap => {
    dataQuality.value = snap.val() || {}
    loaded.value = true
  }
  const onUpdateIgnored = snap => (dataQualityIgnored.value = snap.val() || {})
  onMounted(() => {
    loaded.value = false
    dataQualityRef.on('value', onUpdate)
    dataQualityIgnoredRef.on('value', onUpdateIgnored)
  })
  onUnmounted(() => dataQualityRef.off('value', onUpdate))
  onUnmounted(() => dataQualityIgnoredRef.off('value', onUpdateIgnored))
  return {
    loaded,
    dataQuality,
    dataQualityIgnored,
  }
}
