import { reactive, readonly } from 'vue'
import { useHashList } from '@hauru/common'

export interface IGridSortColumn {
  /**
   * Column label
   */
  label: string
  /**
   * Sorting order of the column
   */
  order: -1 | 1
}

export type ISortList = ReturnType<typeof useSortList>

export function useSortList({ allowMultiple = true } = {}) {
  const hashList = useHashList<IGridSortColumn>({ allowMultiple, getKey: item => item.label })

  const state = reactive({
    ...hashList,
    /**
     * Returns a number that indicates whether a column is sorted
     *  1 --> sorted by alphanumeric order
     *  0 --> not sorted
     * -1 --> sorted by reverse alphanumeric order
     * @param label The label of the desired column
     */
    getOrder: (label: string) => hashList.get(label)?.order ?? 0,
    cycle,
    toggle,
    reverse,
  })

  /**
   * Adds column to sorted, Reverses sorting order or removes from sorted
   * @param label The label of the column who's sorted status we will cycle
   */
  function cycle(label: string) {
    console.log('cycleSorted')
    const sortedOrder = state.getOrder(label)

    if (sortedOrder === 0) state.add({ label, order: 1 })
    else if (sortedOrder === 1) state.set(label, { label, order: -1 })
    else state.remove(label)
  }

  /**
   * Adds column to sorted, or removes it if it is already sorted (and forceOrder is not defined)
   * @param label The label of the column who's sorted status we will toggle
   * @param forceOrder The order to sort by, even if the column is ordered
   */
  function toggle(label: string, forceOrder?: -1 | 1) {
    const sortedOrder = state.getOrder(label)

    if (sortedOrder === 0) state.add({ label, order: forceOrder ?? 1 })
    else if (forceOrder !== undefined) state.set(label, { label, order: forceOrder })
    else state.remove(label)
  }

  /**
   * Reverses sorting order of a column, or adds it to sorted if it is not already sorted
   * @param label The label of the column who's sorted order we will reverse
   */
  function reverse(label: string) {
    const sortedOrder = state.getOrder(label)

    if (sortedOrder === 1) state.set(label, { label, order: -1 })
    else state.set(label, { label, order: 1 })
  }

  return readonly(state)
}
