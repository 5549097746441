<style>
.board .line .cell { align-items: flex-start; }
</style>

<template lang="pug">
transition(@leave='leave')
  loader(v-if="isLoading")
h1 {{ t[$root.screen.path] }}
block.expand
  spreadsheet.stripped.expand(:data="workflows.filter(w => w && !w.disabled)" :options="{ columns: ['name', 'steps', 'actions'], sort: ['name'], editable: false }")
    template(v-slot:cell-steps="s")
      div {{ s.line.actions.v().filter().length }} {{ t.steps }}
    template(v-slot:cell-actions="s")
      .row
        button.ghost(tt="Edit" @click="$router.push({ path: $root.appath + 'workflow', query: { id: s.line.id, action_id: s.line.actions.v().first().id } })")
          svg-icon(name="pt-icon-edit")
        button.ghost(tt="Duplicate" @click="duplicate_workflow(s.line.id)")
          svg-icon(name="ic_file_copy")
        button.ghost(tt="Delete" @click="delete_workflow(s.line.id)")
          svg-icon(name="pt-icon-trash")
  .row
    button.primary(@click="create_workflow") Create workflow
</template>

<script>
import {useWorkflows} from "../composables/useWorkflows";
import {usePresses} from "../composables/usePresses";
import {useProgress} from "../composables/useProgress";
import {computed} from "vue";

export const additions = {"icon":"ic_settings"}
export default {
  setup() {
    const {workflows, loaded: wfLoaded, initialized} = useWorkflows()
    const {presses, loaded: pLoaded} = usePresses()
    const {progress} = useProgress([wfLoaded, pLoaded])
    const isLoading = computed(() => progress.value !== 1 && !initialized.value)
    return {workflows, presses, isLoading}
  },
  methods: {
    async create_workflow(){
      const id = +((this.workflows || {}).v().map('id').max()) + 1 || 0
      await set('data.workflows.' + id, {...this.empty_workflow, id: id})
      await this.$router.push({ path: $root.appath + 'workflow', query: { id: id, action_id: 1 }})
    },
    async duplicate_workflow(wid) { //TODO move to workflow.vue
      const id = (this.workflows || {}).v().map('id').max() + 1 || 0
      await set('data.workflows.' + id, { ...this.workflows[wid], id })
      await this.$router.push($root.appath + 'workflow?id=' + id)
    },
    async delete_workflow(wid) { //TODO move to workflow.vue
      const affected_presses = (this.presses || []).v().filter(d => d.workflow_id === wid)
      update_query({ id: null })
      if (affected_presses.length) await set('data.presses', this.presses.map(p => affected_presses.map('id').includes(p.id) ? Object.assign(p, {disabled: true}) : p))
      await set('data.workflows.' + wid + '.disabled', true)
    },
  }
}
</script>
