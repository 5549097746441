<style scoped>
.spinner {
  z-index: 10000;
  position: absolute;
  margin: 0;
  min-width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 1;
  background: #f5f5f5;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.spinner img {
  max-width: 50vw;
  max-height: 200px;
  margin-bottom: 2.5em;
  object-fit: contain;
}
.loader {
  width: 6vw;
}
.loader.greenrock svg {
  fill: #0ed8b8;
}
.loader.amiral svg {
  fill: #3276a2;
}
.transparent {
  opacity: 0;
  pointer-events: none;
}
</style>

<template>
  <div class="spinner loading" style="z-index: 10001">
    <img src="/icon.png" />
    <div :class="`loader ${project}`">
      <svg width="100%" height="100%" viewBox="0 0 120 30" xmlns="http://www.w3.org/2000/svg">
        <circle cx="15" cy="15" r="15">
          <animate attributeName="r" from="15" to="15" begin="0s" dur="0.8s" values="15;9;15" calcMode="linear" repeatCount="indefinite" />
          <animate attributeName="fill-opacity" from="1" to="1" begin="0s" dur="0.8s" values="1;.5;1" calcMode="linear" repeatCount="indefinite" />
        </circle>
        <circle cx="60" cy="15" r="9" fill-opacity="0.3">
          <animate attributeName="r" from="9" to="9" begin="0s" dur="0.8s" values="9;15;9" calcMode="linear" repeatCount="indefinite" />
          <animate attributeName="fill-opacity" from="0.5" to="0.5" begin="0s" dur="0.8s" values=".5;1;.5" calcMode="linear" repeatCount="indefinite" />
        </circle>
        <circle cx="105" cy="15" r="15">
          <animate attributeName="r" from="15" to="15" begin="0s" dur="0.8s" values="15;9;15" calcMode="linear" repeatCount="indefinite" />
          <animate attributeName="fill-opacity" from="1" to="1" begin="0s" dur="0.8s" values="1;.5;1" calcMode="linear" repeatCount="indefinite" />
        </circle>
      </svg>
    </div>
  </div>
</template>

<script>
import config from '../../config'
export default {
  data() {
    return { project: config.project }
  },
}
</script>
