<script setup>
import * as d3 from "d3"
import { ref, watch } from "vue"
const element = ref(null)
const { data, options = {} } = defineProps(["data", "options"])
const draw = (width, height, margin) => {
  const formatInt = (value, digit) => Math.round(value * Math.pow(10, digit)) / Math.pow(10, digit)
  const ticks = (data, { num = 5, digit = 0, flag = true } = {}) => {
    const min = formatInt(Math.min(...data), digit)
    const max = formatInt(Math.max(...data), digit)
    const gap = formatInt((max - min) / (num - 1), digit)
    const ticks = Array(num)
      .fill()
      .map((_, i) => formatInt(min + i * gap, digit))
    if (flag && gap === max - ticks.at(-1)) return ticks.concat(max)
    return ticks
  }

  const xScale = d3
    .scaleTime()
    .domain(d3.extent(data.map((v) => v.date)))
    .range([margin.left + 5, width - margin.right])

  const yScale = d3
    .scaleLinear()
    .domain(d3.extent(data.map((v) => v.value)))
    .range([height - margin.bottom, margin.top])

  const line = d3
    .line()
    .x((v) => xScale(v.date))
    .y((v) => yScale(v.value))

  const xTicks = ticks(
    data.map((v) => +v.date.getFullYear()),
    options.x
  )
  const yTicks = ticks(
    data.map((v) => +v.value),
    { ...options.y, num: Math.min(5, Math.round(height / 30)) }
  )

  return { xScale, yScale, line, xTicks, yTicks }
}

const width = ref(300)
const height = ref(150)
const margin = { top: 20, bottom: 20, right: 20, left: 40 }
const xScale = ref(() => null)
const yScale = ref(() => null)
const line = ref(() => null)
const xTicks = ref([])
const yTicks = ref([])
watch(
  element,
  () => {
    width.value = element.value.clientWidth
    height.value = element.value.clientHeight
    const x = draw(width.value, height.value, margin)
    xScale.value = x.xScale
    yScale.value = x.yScale
    line.value = x.line
    xTicks.value = x.xTicks
    yTicks.value = x.yTicks
  },
  { deep: true }
)
</script>

<template>
  <svg class="nx-line" xmlns="http://www.w3.org/2000/svg" ref="element">
    <g class="axis y" :transform="'translate(0,' + yScale(v) + ')'" v-for="v in yTicks">
      <line :x1="margin.left" :x2="width - margin.right" />
      <text alignment-baseline="middle">{{ v }}</text>
    </g>
    <g class="axis x" :transform="'translate(' + xScale(new Date(v.toString())) + ',' + height + ')'" v-for="v in xTicks">
      <text text-anchor="middle">{{ v }}</text>
    </g>
    <path :d="line(data)" stroke="currentColor" fill="transparent" />
  </svg>
</template>

<style>
.nx-line .axis text {
  font-size: 12px;
  fill: rgba(0, 0, 0, 0.6);
}
.nx-line .axis line {
  stroke: rgba(0, 0, 0, 0.2);
  stroke-width: 1px;
}
</style>
