import {dbRef} from "../../../features/firebase";
import {onMounted, onUnmounted, ref} from "vue";

const config = ref({})
export function useConfig() {
  const configRef = dbRef.child('/config')
  const loaded = ref(false)
  const update = (snap) => {
    config.value = snap.val() || {}
    loaded.value = true
  }
  const define_scope_fn = (report) => {
    try {
      if (config.value.define_scope) {
        const define_scope = eval(config.value.define_scope)
        return define_scope(report)
      }
    } catch (e) {
      console.log(e)
      return 'default'
    }
  }
  onMounted(() => {
    loaded.value = false
    configRef.on('value', update)
  })
  onUnmounted(() => configRef.off('value', update))
  return {config, define_scope_fn, loaded}
}
