<style scoped></style>

<template>
  <div class="flex flex-col bg-white border-2 rounded-lg shadow-lg min-w-[400px] flex-1 max-w-[450px] mx-20 my-5">
    <div class="flex ml-4 font-bold mt-4 text-xl leading-normal">{{ kpi_data.portfolioLabel }}</div>
    <div class="flex flex-row justify-between px-10 mt-8 text-center">
      <div class="flex flex-col">
        <div class="font-bold">{{ t.hurdle }}</div>
        <div>{{ kpi_data.hurdle }}%</div>
      </div>
      <div class="flex flex-col">
        <div class="font-bold">{{ t.performance }}</div>
        <div>{{ kpi_data.performance }}%</div>
      </div>
    </div>
    <div class="flex justify-between pr-6 pt-8 pb-4">
      <div class="pl-6 pt-2 mt-2 text-xs">{{ kpi_data.date }}</div>
      <router-link class="bg-[#0ed8b8] active:bg-[#0cb89c] hover:bg-[#0cb89c] text-white py-2 px-6 rounded" :to="path">
        Access 
      </router-link>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  kpi_data: Object,
})

const path = "./" + props.kpi_data.portfolioCode + "/position"
</script>
