<script setup>
import * as XLSX from 'xlsx';
import { ref, onMounted, computed, watch, onUnmounted } from 'vue'
import { useAttribution } from '../../composables/useAttribution.js'
import { useXfilter } from '../../composables/useXfilter.js'
import { useDates } from '../../composables/useDates';

const { getShareInfo, getAttributionData, getAttributionDetails, getAttributionDetailsDeep } = useAttribution()
const { axisFormat } = useXfilter()
const { getDatesQuery } = useDates();
const loaded = ref(false)
const availableDimensions = ref()
const loadedDimensions = ref()
const asof = ref()
const benchId = ref()
const fundCurrency = ref()
const fundAssetClass = ref()
const excessReturnData = ref()
const attributionData = ref()
const effectData = ref()
const extension = ref('csv')
const detailsDimension = ref()
const columns = ref([
  'dimension',
  'average_weight_fund',
  'fund_contribution',
  'average_weight_benchmark',
  'benchmark_contribution',
  'allocation_effect',
  'selection_effect',
  'excess_return'
])

const leave = (el, done) => {
  el.style.opacity = 0
  setTimeout(() => done(), 500)
}

const loadData = async () => {
  loaded.value = false
  loadedDimensions.value = []
  const isinShare = $root.$route.fullPath.split('/')[2].split('-')[1]
  const params = {
    isinShare,
    domain: $root.$route.query.domain || $root.domain.join('|'),
    asof: $root.domain[1],
    lang: $root.$route.query.lang || $root.lang,
    dimension: null
  }

   // Get the attribution dates:
  $root.dates = await getDatesQuery(params, 'contribution') // attrib dates are the same as contrib dates
  $root.dates.isin_share = params.isinShare
  $root.dates.query = 'contribution'
  $root.refresh = $root.refresh + 1
  params.domain = $root.domain.join('|')
  params.asof = $root.domain[1]

  // Get the share info & its axes of analysis:
  const shareData = await getShareInfo(params)
  asof.value = shareData.asof
  availableDimensions.value = shareData.axis_of_analysis
  fundAssetClass.value = shareData.characteristics?.fund_asset_class || ''
  benchId.value = shareData.benchmark.id
  $root.tab_benchmark = shareData.benchmark
  $root.tab_userflow_name = shareData.slug
  $root.refresh = $root.refresh + 1
  fundCurrency.value = shareData.fund_currency
  detailsDimension.value = $root.query.attribdim
  excessReturnData.value = shareData.excessReturn
  if (!$root.query.attribdim && availableDimensions.value.length > 0) detailsDimension.value = availableDimensions.value[0]
 
  loaded.value = true

  // Get the attribution data for each axis of analysis:
  attributionData.value = []
  effectData.value = {}
  await Promise.all(availableDimensions.value
    .map(async (dimension) => {
      const queryParams = { ...params, dimension }
      const data = await getAttributionData(queryParams)
      console.log(`Received attribution data for the "${dimension}" axis.`)
      loadedDimensions.value.push(dimension)
      attributionData.value.push({ dimension, data })

      // Build the "effect data" along with their respective KPIs:
      effectData.value[dimension] = {}
      Object.entries(data)
        .forEach(([dimValue, dimData]) => {
          const effects = Object.entries(dimData)
            .filter(([key]) => key.endsWith('_effect'))
            .reduce((acc, [key, value]) => {
              acc[key] = value
              return acc
            }, {})
            effectData.value[dimension][dimValue] = effects
        })
    })
  )
}

const effectKpi = computed(() => (field) => loadedDimensions.value
  .map((dimension) => {
    const obj = attributionData.value.find((d) => d.dimension === dimension)
    if (!obj) return {}
    const total = Object.values(obj.data).reduce((acc, cur) => acc + cur[field], 0)
    return {
      title: ($root.t[dimension] || dimension) + ' ' + $root.t[field],
      value: format(field)(total)
    }
  })
)

const excessReturnKpi = computed(() => {
  if (!excessReturnData.value) return []
  return [
    {
      title: 'excess_return',
      value: excessReturnData.value.excess_return
    },
    {
      title: 'performance',
      value: excessReturnData.value.performance
    },
    {
      title: 'performance_benchmark',
      value: excessReturnData.value.performance_benchmark
    }
  ]
})

const detailsAttributionData = computed(() => (dimension) => {
  if (!attributionData.value || attributionData.value.length === 0) return []
  const obj = attributionData.value.find((d) => d.dimension === dimension)
  if (!obj) return []
  // Build the total of the dimension:
  const total = Object.values(obj.data).reduce((acc, cur) => {
    Object.keys(cur).forEach((key) => {
      if (!acc[key]) acc[key] = 0
      acc[key] += cur[key]
    })
    return acc
  }, { dimension: 'TOTAL' })
  // Rreturn the data and the total of these data:
  return [
    ...Object.entries(obj.data)
      .map(([dimension, data]) => ({
        dimension,
        ...data
      })),
      total
    ]
})

const changeExt = (e) => {
  extension.value = e.target.value;
}

const axisList = computed(() => {
  if (fundAssetClass.value === 'fundAssetClass_actions') return ['exposure', 'contrib_deltaaction']
  if (fundAssetClass.value === 'fundAssetClass_obligations' || fundAssetClass.value === 'fundAssetClass_monetaire') return ['exposure', 'contrib_sensi']
  if (fundAssetClass.value === 'fundAssetClass_diversifies' || fundAssetClass.value === 'fundAssetClass_convertibles') return ['exposure', 'contrib_sensi', 'contrib_deltaaction']
  return ['exposure', 'contrib_sensi', 'contrib_deltaaction']
})

const attributionAxis = computed(() => {
  return $root.query && $root.query.evolution ? 'attribution_evolution' : 'attribution'
})

const metadataAttribution = dim => {
  const format = axisFormat.value
  const max = 6 // Warning Old code (display at leat 80% removed)
  const dimension = dim // REQUIRE for inactive bar in plot-bar.vue
  const sort = $root.config.sorting(dim)
  const suppressEmptyBars = true
  return {format, max, dimension, sort, suppressEmptyBars}
}

const exportTable = () => {
  const tableToExport = detailsAttributionData.value(detailsDimension.value)
    .map((line) => {
      const lineToExport = {}
      columns.value.forEach((column) => {
        lineToExport[column] = line[column]
      })
      return lineToExport
    })

  switch (extension.value) {
    case 'csv':
      return tableToExport.dlCSV($root.lang, 'attribution.csv')
    case 'xlsx':
      return tableToExport.dlXLS('attribution.xlsx')
  }
}

const downloadDetailedAttrib = async () => {
  const routeTokens = $root.$route.fullPath.split('/')[2].split('-')
  const filename = ['attribution', routeTokens[0], routeTokens[1]].join('-') + '.xlsx'
  const isinShare = $root.$route.fullPath.split('/')[2].split('-')[1]
  const domain = $root.$route.query.domain || $root.domain.join('|')
  const dimension = 'label2' // TODO the dimension name should come from client config
  const additionalDimensions = availableDimensions.value.filter((d) => d !== dimension)
  const params = {
    isinShare,
    domain,
    asof: $root.domain[1],
    dimension,
    additionalDimensions
  }
  const detailedData = await getAttributionDetails(params)
  return detailedData.dlXLS(filename)
}

const downloadDetailedAttribDeep = async () => {
  const routeTokens = $root.$route.fullPath.split('/')[2].split('-')
  const filename = ['attribution', 'raw', routeTokens[0], routeTokens[1]].join('-') + '.xlsx'
  const isinShare = $root.$route.fullPath.split('/')[2].split('-')[1]
  const domain = $root.$route.query.domain || $root.domain.join('|')
  const dimension = 'label2' // TODO the dimension name should come from client config
  const additionalDimensions = availableDimensions.value.filter((d) => d !== dimension)
  const params = {
    isinShare,
    domain,
    asof: $root.domain[1],
    dimension,
    additionalDimensions
  }
  const detailedDataDeep = await getAttributionDetailsDeep(params)

  const label = [$root.tab_userflow_name.titleize(), 'vs', benchId.value].join(' ')
  const periodLabel = [$root.t.from, $root.domain[0], $root.t.to, $root.domain[1]].join(' ')
  const keys = [
    'dimension',
    'name',
    ...columns.value.slice(0 /* clone the original array so that splice does not mutate it */).splice(1)
  ]
  const header = [
    ['Performance Attribution'],
    [label],
    [periodLabel],
    [fundCurrency.value],
    keys
  ]

  const workbook = XLSX.utils.book_new()
  additionalDimensions.forEach((ad) => {
    const worksheet = XLSX.utils.json_to_sheet([])
    XLSX.utils.sheet_add_aoa(worksheet, header)
    // Add the row for the totals:
    let data = [{
      dimension: 'TOTAL',
      ...detailedDataDeep[ad].totals
    }]
    Object.entries(detailedDataDeep[ad].attribution).forEach(([dimension, { details, ...rest }]) => {
      // Add the master row for the current dimension value:
      data.push({
        dimension,
        ...rest
      })
      // Add the child rows for the details of the current dimension value:
      data = data.concat(details)
    })
    XLSX.utils.sheet_add_json(worksheet, data, { origin: 'A6', skipHeader: true, header: keys})
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Attribution-' + ad)
  })
  XLSX.writeFile(workbook, filename)
}

onMounted(loadData)
watch(() => $root.query.domain, loadData)
watch(() => $root.query.attribdim, (newValue) => { detailsDimension.value = newValue })
</script>

<script>
export const additions = { calendar: 'period', icon: 'ic_bar_chart' }
</script>

<template lang="pug">
transition(@leave='leave')
  loader(v-if="!loaded")
template(v-if="loaded")
  .screen-title
    .row
      h1 {{ t.attribution }}
  .row.stretch(v-if="$root.domain.length")
    kpi.performance(:data="excessReturnKpi")
    kpi(v-if="loadedDimensions.length > 0" :data="effectKpi('allocation_effect')")
    kpi(v-if="loadedDimensions.length > 0" :data="effectKpi('selection_effect')")

  .block.column.expand(v-if="availableDimensions && effectData")
    .row.expand(v-for="row in availableDimensions.chunk(2)")
      template(v-for="dimension in row")
        block.compact-loading(v-if="!effectData[dimension]")
          .row.center(style="margin: auto")
            .row(style="margin:8px auto") Loading data by {{ t[dimension] || dimension }}
            transition(@leave='leave')
              loader
        block.allow-fullscreen(v-else type="plot-bar" :data="effectData[dimension]" :title="attributionAxis + ',by,' + dimension" :metadata="metadataAttribution(dimension)")

  .block.column.expand(v-if="loadedDimensions.length > 0")
    .row
      h2 {{ t.attribution_details }}
    .row
      .block
        .row.left
          select(:value="$root.query.attribdim || loadedDimensions[0]" @change="update_query({ attribdim: $event.target.value })" style="margin-right: 8px")
            option(:value="dim" v-for="dim in availableDimensions.filter((d) => loadedDimensions.includes(d))") {{ t[dim] || dim }}
          button.primary.download(@click="exportTable()")
              span {{ t.export_table }}
          select(@change="changeExt")
            option(v-for="exten in ['csv', 'xlsx']" :value="exten") {{ exten }}
      .block
        .row.right.download
          button.primary(@click="downloadDetailedAttrib" style="margin-right: 8px")
            span {{ t.download_detailed_attrib }}
          button.primary(@click="downloadDetailedAttribDeep")
            span {{ t.download_detailed_attrib_deep }}
    .row.column.expand
      board(v-if="detailsDimension && detailsAttributionData(detailsDimension)" :data="detailsAttributionData(detailsDimension)" :metadata="{ expand: 'fuid', sort: '-average_weight_fund', desc: true, columns }")  
        template(v-slot:cell-dimension="s")
          span {{ t[s.line.dimension] || s.line.dimension }}
  .block.compact-loading(v-else)
    .row.center(style="margin: auto")
      .row(style="margin:8px auto") Loading {{ t.attribution_details }}
      transition(@leave='leave')
        loader

  .asof(v-if="asof")
    p Mapping asof: {{asof}}
</template>

<style>
.on {
  opacity: 1;
}
.page > .asof {
  margin-top: 0;
  margin-bottom: 0;
}
.asof p {
  font-size: 9px;
  text-align: right;
}
.row.right {
  flex: 1;
}
.screen-attribution .block-plot-bar {
  min-width: 370px;
}
.button-bar{
  min-width: 624px;
}
/* .screen-attribution.no_benchmark_data .tab, */
.screen-attribution.no_benchmark_data .kpi .weight_benchmark,
.screen-attribution .kpi.no_benchmark_data .title:nth-of-type(2),
.screen-attribution .kpi.no_benchmark_data .value:nth-of-type(2)
{
  display: none;
}

.compact-loading {
  padding: 14px;
  text-align: center;
}
.compact-loading .spinner {
  position: relative;
  background-color: transparent;
}
.compact-loading img {
  display: none;
}
</style>
