import { inject, onMounted, onUnmounted, ref } from 'vue'
import shareService from '../../../services/ShareService'

export function useShares() {
  const shares = ref([])
  const fund_context = ref({})
  const loaded = ref(false)

  onMounted(async () => {
    shares.value = await shareService.listAll();
    fund_context.value = makeFundContext(shares.value)
    loaded.value = true
  })

  return {
    shares,
    fund_context,
    loaded,
  }
}

function makeFundContext(shares) {
  return shares.reduce((acc, { share_letter, slug, ...s }) => {
    acc[s.share_id] = {
      ...s,
      share: share_letter
    }
    return acc
  }, {})
}
